import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ProjectsService } from 'app/modules/projects/projects.service';
import { Project, Pipeline, ProjectTemplate } from 'app/modules/projects/projects.types';
import { FieldDef } from 'app/core/models/account.types';
@Component({
  selector: 'project-status-required-fields',
  templateUrl: './project-status-required-fields.component.html',
  styleUrls: ['./project-status-required-fields.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectStatusRequiredFieldsComponent implements OnInit {
  fieldDefs: FieldDef[] = [];
  project: Project;
  displayColumns: any[];
  options: [];

  projectTemplates: ProjectTemplate[];
  duplicateProjectId: number = 0;
  duplicateProjects: Project[] = [];

  public uploadedFile: any;

  get isStatus(): boolean {
    return this.data?.isStatus ?? true;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<ProjectStatusRequiredFieldsComponent>,
    private _projectsService: ProjectsService,
  ) {}

  ngOnInit(): void {
    this.fieldDefs = this.data.data.fieldDefs;
    this.project = this.data.project;
    this.options = this.data.data;
    this.displayColumns = this.data.stage.required_fields
      .split(',')
      .map((field) => this.fieldDefs.find((def) => def.name === field))
      .filter((field) => field);
  }

  discard(): void {
    this.matDialogRef.close();
  }

  updated() {
    this._projectsService.saveProject(this.project, null, null, null, false).subscribe();
  }

  lookupField(name) {
    let fields = this.fieldDefs.filter((fieldDef) => fieldDef.name == name);
    if (fields.length > 0) {
      return fields[0];
    } else {
      return null;
    }
  }

  update() {
    this.discard();
  }

  cancel() {
    this.discard();
  }
}
