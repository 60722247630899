/* eslint-disable no-case-declarations */
import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { FieldDef } from 'app/core/models';
import { Project, Property } from 'app/modules/projects/projects.types';
import { FormatNumberPipe } from './format-number-pipe';
import { TsCurrencyPipe } from './ts-currency.pipe';
import { AccountService } from 'app/core';
import { Dictionary } from '../models';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

type ModelType = Project | Property | Dictionary<any>;

@Pipe({
  name: 'readableField',
  pure: true,
})
export class ReadableFieldPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string, private readonly accountService: AccountService) {}

  transform(value: any, model: ModelType, fieldDef: FieldDef, optionsDictionary: { [key: string]: any }): string {
    switch (fieldDef.type) {
      case 'number':
        return new FormatNumberPipe().transform(value, fieldDef.meta?.scale || 0);
      case 'percent':
        return `${new FormatNumberPipe().transform(value || 0, fieldDef.meta?.scale || 0)}%`;
      case 'decimal':
        return new FormatNumberPipe().transform(value, fieldDef.meta?.scale || 2);
      case 'money':
        // eslint-disable-next-line no-case-declarations
        const precision = fieldDef.meta?.scale || 0;
        return new TsCurrencyPipe(this.accountService).transform(
          value,
          'USD',
          'symbol',
          '1.' + precision + '-' + precision,
        );
      case 'datetime':
        return value ? moment(value).utc().format('L') : null;
      case 'select':
        if (fieldDef?.meta?.multi_select) {
          return Array.isArray(model[fieldDef.name]) ? model[fieldDef.name].join() : null;
        }

        // eslint-disable-next-line no-case-declarations
        let options = [];

        if (fieldDef?.meta?.options?.length > 0) {
          options = fieldDef.meta.options.map((value) => ({ id: value, name: value }));
        } else {
          options = optionsDictionary[fieldDef.meta.option_name].filter((option) => !option?.hidden);
        }

        // eslint-disable-next-line no-case-declarations
        const selectedOption = options.find(({ id }) => id == value);
        return selectedOption?.name || selectedOption?.full_name;
      case 'checkbox':
      case 'record':
      case 'url':
      case 'typeahead':
      case 'tags':
      case 'year':
      case 'textarea':
      case 'text':
      default:
        return value;
    }
  }
}
