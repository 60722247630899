import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { AuthUtils } from 'app/core/utils/auth.utils';
import { ApiService } from './api.service';
import { Invitation } from 'app/core/models/user.types';
import { environment } from '@env/environment';
import { Permission } from '../enums/permission';
import { SignInResponse } from '../models/user.types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // Private
  private _authenticated: boolean;
  private assignedPermissions: Permission[] = [];

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient, private _apiService: ApiService, private _cookieService: CookieService) {
    // Set the defaults
    this._authenticated = false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter and getter for access token
   */
  set accessToken(token: string) {
    this._cookieService.set('access_token', token, undefined, '/', environment.cookieDomain);
  }

  get accessToken(): string {
    return this._cookieService.get('access_token');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Send Forgot Password link
   */
  forgotPassword(credentials: { email: string }): Observable<any> {
    return this._apiService.directPost('/api/forgot_password', credentials).pipe(
      switchMap((response: any) => {
        // Return a new observable with the response
        return of(response);
      }),
    );
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._apiService.directPost('/api/authenticate', credentials).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        this.accessToken = response.auth_token;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Return a new observable with the response
        return of(response);
      }),
    );
  }

  /**
   * Sign Up
   *
   * @param credentials
   */
  signUp(credentials: {
    full_name: string;
    email: string;
    password: string;
    account_name: string;
    invite_token?: string;
  }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._apiService.directPost('/api/register', credentials).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        this.accessToken = response.auth_token;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Return a new observable with the response
        return of(response);
      }),
    );
  }

  /**
   * Reset Passsword
   */
  resetPassword(credentials: {
    password: string;
    confirm_password: string;
    reset_password_token: string;
  }): Observable<any> {
    return this._apiService.directPost('/api/reset_password', credentials).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        this.accessToken = response.auth_token;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Return a new observable with the response
        return of(response);
      }),
    );
  }

  /**
   * Get an invitation
   */
  getInvitation(invitationToken: string): Observable<Invitation> {
    // Renew token
    return this._httpClient.get(`invitations/${invitationToken}`).pipe(
      catchError(() => {
        // Return false
        return of(false);
      }),
      switchMap((response: any) => {
        // Return true
        return of(response.invitation);
      }),
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Renew token
    return this._httpClient
      .post('/api/auth/refresh-access-token', {
        access_token: this.accessToken,
      })
      .pipe(
        catchError(() => {
          // Return false
          return of(false);
        }),
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response.access_token;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Return true
          return of(true);
        }),
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    //localStorage.removeItem('access_token');
    this._cookieService.delete('access_token', '/', environment.cookieDomain);

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    //RRS TODO
    return of(true);
  }

  signInWithTToken(ttoken: string): Observable<SignInResponse> {
    return this._apiService
      .directPost(`${environment.baseUrl}/integrations/oauth_connect/authenticate`, { ttoken }, false)
      .pipe<SignInResponse>(
        tap(({ auth_token }) => {
          this.accessToken = auth_token;
          this._authenticated = true;
        }),
      );
  }

  signInWithSamlTToken(ttoken: string): Observable<SignInResponse> {
    return this._apiService
      .directPost(`${environment.baseUrl}/integrations/saml/authenticate`, { ttoken }, false)
      .pipe<SignInResponse>(
        tap(({ auth_token }) => {
          this.accessToken = auth_token;
          this._authenticated = true;
        }),
      );
  }

  hasPermission(permission: Permission): boolean {
    return this.assignedPermissions.includes(permission);
  }

  isGuest(): boolean {
    return this.assignedPermissions?.length === 0;
  }

  setPermissions(permissions: Permission[]): void {
    this.assignedPermissions = permissions;
  }
}
