import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';

import { EMPTY, forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AccountService } from 'app/core';
import { ProjectsService } from './projects.service';
import { FieldDef } from 'app/core/models/account.types';
import { Project, ProjectPagination, Pipeline } from './projects.types';
import { FoldersTabService } from 'app/shared/modules/folders/folders.service';
import { TasksService } from '../tasks/tasks.service';
import { TaskStatus } from 'app/shared/modules/tasks/tasks.types';

@Injectable({
  providedIn: 'root',
})
export class PipelinesResolver implements Resolve<any> {
  constructor(private _projectsService: ProjectsService, private _accountService: AccountService) {
    this._accountService.currentAccount.subscribe((accountData) => {
      this._projectsService.accountId = accountData.id;
    });
  }

  resolve(): Observable<Pipeline[]> {
    return this._projectsService.getPipelines();
  }
}

@Injectable({
  providedIn: 'root',
})
export class PipelinesPipelineResolver implements Resolve<any> {
  constructor(
    private _router: Router,
    private _projectsService: ProjectsService,
    private _foldersTabService: FoldersTabService,
    private _accountService: AccountService,
  ) {
    this._accountService.currentAccount.subscribe((accountData) => {
      this._projectsService.accountId = accountData.id;
      this._foldersTabService.accountId = accountData.id;
    });
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Pipeline> {
    const pipelineId = route.paramMap.get('id') || route.parent?.paramMap.get('id');

    if (!pipelineId) {
      this._router.navigate(['/projects']);
    }

    return this._projectsService.getPipelineById(pipelineId).pipe(
      tap((pipeline) => {
        if ((pipeline as unknown) === EMPTY) this._router.navigate(['/projects']);
      }),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProjectsResolver implements Resolve<any> {
  constructor(private _projectsService: ProjectsService, private _accountService: AccountService) {
    this._accountService.currentAccount.subscribe((accountData) => {
      this._projectsService.accountId = accountData.id;
    });
  }

  resolve(): Observable<{ pagination: ProjectPagination; projects: Project[] }> {
    return this._projectsService.getProjects();
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProjectsProjectResolver implements Resolve<any> {
  constructor(
    private _router: Router,
    private _projectsService: ProjectsService,
    private _accountService: AccountService,
  ) {
    this._accountService.currentAccount.subscribe((accountData) => {
      this._projectsService.accountId = accountData.id;
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Project> {
    // Extract to a Util file.
    let depth = 0;
    let projectId = null;
    let aux = route;
    while (!(projectId = aux.paramMap.get('project_id')) && depth < 5) {
      aux = aux.parent;
      depth++;
    }

    return this._projectsService.getProjectById(projectId).pipe(
      // Error here means the requested contact is not available
      catchError((error) => {
        // Log the error
        console.error(error);

        // Get the parent url
        const parentUrl = state.url.split('/').slice(0, -1).join('/');

        // Navigate to there
        this._router.navigateByUrl(parentUrl);

        // Throw an error
        return throwError(error);
      }),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProjectsFieldDefsResolver implements Resolve<any> {
  constructor(private _accountService: AccountService) {}

  resolve(): Observable<FieldDef[]> {
    return this._accountService.getFieldDefs();
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProjectDataResolver implements Resolve<any> {
  constructor(
    private _accountService: AccountService,
    private _projectsService: ProjectsService,
    private readonly taskService: TasksService,
  ) {
    this._accountService.currentAccount.subscribe((accountData) => {
      this._projectsService.accountId = accountData.id;
    });
  }

  private _loadProjectStages(): Observable<any> {
    return this._accountService.getProjectStages();
  }

  private _loadPropertyTypes(): Observable<any> {
    return this._accountService.getPropertyTypes();
  }

  private _loadProjectTypes(): Observable<any> {
    return this._accountService.getProjectTypes();
  }

  private _loadInvestmentTypes(): Observable<any> {
    return this._accountService.getInvestmentTypes();
  }

  private _loadUsers(): Observable<any> {
    return this._accountService.getUsers();
  }

  private _loadTeams(): Observable<any> {
    return this._accountService.getTeams();
  }

  private _loadVehicles(): Observable<any> {
    return this._accountService.getVehicles();
  }

  private _loadSavedFilters(): Observable<any> {
    return this._accountService.getSavedFilters();
  }

  private _loadPipelines(): Observable<any> {
    return this._projectsService.getPipelines();
  }

  private _loadMarketStatuses(): Observable<any> {
    return this._accountService.getMarketStatuses();
  }

  private _loadProjectTemplates(): Observable<any> {
    return this._projectsService.getProjectTemplates();
  }

  private _loadLoanPurposes(): Observable<any> {
    return this._accountService.getLoanPurposes();
  }

  private _loadCategoriesPurposes(): Observable<any> {
    return this._accountService.getCategories();
  }

  private _loadProjectPhases(): Observable<any> {
    return this._accountService.getProjectPhases();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin([
      // Messages
      this._loadProjectStages(),

      // Navigation data
      this._loadPropertyTypes(),

      // Notifications
      this._loadProjectTypes(),

      // Shortcuts
      this._loadUsers(),

      this._loadInvestmentTypes(),

      this._loadTeams(),

      this._loadVehicles(),

      this._loadSavedFilters(),

      this._loadPipelines(),

      this._loadMarketStatuses(),

      this._loadProjectTemplates(),

      this._loadLoanPurposes(),

      this._loadCategoriesPurposes(),

      this._loadProjectPhases(),

      this.taskService.getTaskStatuses().pipe(
        map((taskStatuses: TaskStatus[]) =>
          taskStatuses.map(({ key, label }) => ({
            id: key,
            name: label,
          })),
        ),
      ),
    ]).pipe(
      map((data) => {
        return {
          project_stages: data[0],
          property_types: data[1],
          project_types: data[2],
          users: data[3],
          investment_types: data[4],
          teams: data[5],
          vehicles: data[6],
          savedFilters: data[7],
          pipelines: data[8],
          market_statuses: data[9],
          project_templates: data[10],
          loan_purposes: data[11],
          categories: data[12],
          project_phases: data[13],
          workflow_states: data[14],
        };
      }),
    );
  }
}
