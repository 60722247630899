<!-- Navigation -->
<treo-vertical-navigation
  [appearance]="'thin'"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="data.navigation.compact"
  [opened]="!isScreenSmall"
>
  <div treoVerticalNavigationContentHeader>
    <div class="logo">
      <img src="assets/images/logo/logo.svg" />
    </div>
  </div>
</treo-vertical-navigation>

<!-- Wrapper -->
<div class="wrapper">
  <!-- Header -->
  <div class="header">
    <!-- Navigation toggle button -->
    <button class="navigation-toggle-button" mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'menu'"></mat-icon>
    </button>

    <!-- Spacer -->
    <div class="spacer"></div>

    <!-- Search -->
    <search [appearance]="'bar'"></search>

    <!-- Shortcuts -->
    <shortcuts [shortcuts]="data.shortcuts"></shortcuts>

    <!-- Messages -->
    <messages [messages]="data.messages"></messages>

    <!-- Notifications -->
    <notifications [notifications]="data.notifications"></notifications>

    <!-- User -->
    <user [user]="data.user"></user>
  </div>

  <!-- Content -->
  <div class="content">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <div class="footer">Treo &copy; {{ currentYear }}</div>
</div>
