import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserAssociation } from '../models';

@Injectable()
export class UserAssociationService {
  constructor(private readonly httpClient: HttpClient) {}

  update(entity: UserAssociation): Observable<UserAssociation> {
    return this.httpClient.put<UserAssociation>(
      `accounts/${entity.related_to_id}/user_associations/${entity.id}?user_id=${entity.user.id}`,
      { user_association: entity },
    );
  }
}
