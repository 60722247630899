import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customFilter',
  pure: true,
})
export class CustomFilterPipe implements PipeTransform {
  transform(value: unknown[], customFn: Function): unknown[] {
    return customFn(value);
  }
}
