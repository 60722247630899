<div class="header">
  <div class="title">Associate Company</div>
  <button class="close-button" mat-icon-button (click)="discard()" [tabIndex]="-1">
    <mat-icon [svgIcon]="'close'"></mat-icon>
  </button>
</div>

<form [formGroup]="companyForm">
  <div class="flex flex-col gt-xs:flex-row">
    <mat-form-field class="flex-auto gt-xs:pr-3">
      <mat-label>Company</mat-label>
      <input
        matInput
        [formControlName]="'name'"
        [placeholder]="'Company Name'"
        [matAutocomplete]="matAutocompleteCompany"
        [spellcheck]="false"
      />
    </mat-form-field>
    <mat-autocomplete
      #matAutocompleteCompany="matAutocomplete"
      [disableRipple]="true"
      (optionSelected)="autocompleteCompanySelected($event)"
    >
      <mat-option class="no-results" *ngIf="companyResults && !companyResults.length"> No companies found! </mat-option>

      <mat-option *ngFor="let companyResult of companyResults" [value]="companyResult.id">
        {{ companyResult.name }}
      </mat-option>
    </mat-autocomplete>
  </div>

  <div class="flex flex-col gt-xs:flex-row">
    <mat-form-field class="flex-auto gt-xs:pr-3">
      <mat-label>Website</mat-label>
      <input matInput [formControlName]="'website'" [placeholder]="'Website'" />
    </mat-form-field>
  </div>
  <div class="actions justify-end">
    <div class="buttons">
      <button class="discard-button" mat-button type="button" (click)="discard()">
        <span>Cancel</span>
      </button>
      <button
        class="send-button"
        mat-flat-button
        [color]="'primary'"
        [disabled]="isSaveBtnDisabled"
        DebounceClick
        (debounceLoading)="isDebounceLoading($event)"
        (debounceClick)="saveCompanyAssociation()"
      >
        <span *ngIf="!isLoading">Save</span>
        <mat-progress-spinner *ngIf="isLoading" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
      </button>
    </div>
  </div>
</form>
