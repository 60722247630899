import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { TreoAutogrowModule } from '@treo/directives/autogrow';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocompleteModule } from '@angular/material/autocomplete';
import { Overlay } from '@angular/cdk/overlay';
import { MentionModule } from 'angular-mentions';

import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskModule, IConfig } from 'ngx-mask';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

import { ChartComponent } from './chart.component';
import { ChartBarComponent } from './bar/bar.component';
import { ChartDonutComponent } from './donut/donut.component';
import { ChartHorizontalBarComponent } from './horizontal-bar/horizontal-bar.component';
import { ChartLineComponent } from './line/line.component';
import { PipesModule } from 'app/shared/pipes/pipes.module';

@NgModule({
    declarations: [
        ChartComponent,
        ChartBarComponent,
        ChartDonutComponent,
        ChartHorizontalBarComponent,
        ChartLineComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        NgApexchartsModule,
        MatMenuModule,
        MatMomentDateModule,
        MatProgressBarModule,
        MatRadioModule,
        MatRippleModule,
        MentionModule,
        MatSelectModule,
        MatChipsModule,
        MatAutocompleteModule,
        TreoAutogrowModule,
        PipesModule,
        CurrencyMaskModule,
        NgxMaskModule.forRoot(),
    ],
    exports: [ChartComponent, ChartBarComponent, ChartDonutComponent, ChartHorizontalBarComponent, ChartLineComponent],
    providers: [
        {
            provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => {
                return () => overlay.scrollStrategies.block();
            },
            deps: [Overlay],
        },
    ]
})
export class ChartModule {}
