import { Component, OnInit } from '@angular/core';
import { FormFieldComponent } from '../form-field.types';

@Component({
  templateUrl: './select-form-field.component.html',
  styleUrls: ['./select-form-field.component.scss'],
})
export class SelectFormFieldComponent extends FormFieldComponent implements OnInit {
  options: any[];
  filteredOptions: any[];

  public value: string = '';
  public editing: boolean = false;
  private _originalValue;
  private _readonly;
  public multiple: boolean = false;
  public multiSelectOptionsText: string = '';

  ngOnInit(): void {
    super.ngOnInit();

    this.value = this.model[this.fieldDef.name];
    this._originalValue = this.value;
    this.multiple = this.fieldDef.meta?.multi_select;
    if (this.fieldDef?.meta?.options?.length > 0) {
      const options = this.fieldDef.meta.options;
      this.options = options.map((value) => ({ id: value, name: value }));
    } else {
      this.options = this.data.options[this.fieldDef.meta.option_name];

      //RRS: [ch4080] - hide dynamic pipelines
      if (this.fieldDef.meta.option_name == 'pipelines') {
        this.options = this.options.filter((pipeline) => pipeline?.pipeline_type != 'dynamic');
      }

      //#3203: ensure only the correct project_stages show up for the given pipeline

      this.options = this.options.filter((option) => {
        if (option.hidden) {
          return false;
        } else {
          if (option?.pipeline_ids) {
            if (this.model.pipeline_id) {
              return option?.pipeline_ids.includes(this.model.pipeline_id);
            }
          }
        }
        return true;
      });

      if (this.fieldDef.meta && this.fieldDef.meta.readonly) {
        this._readonly = this.fieldDef.meta.readonly;
      }
      if (this.readonly) {
        this._readonly = this.readonly;
      }
      this.readonly = this._readonly;
    }
    this.filteredOptions = this.options;

    try {
      if (Array.isArray(this.value)) {
        this.multiSelectOptionsText = this.value.map((option) => option).join(', ');
      }
    } catch (e) {}
  }

  edit() {
    if (!this.editing && !this._readonly) {
      this.editing = true;
    }
  }

  onSelectionChange($event: Event) {
    this.onSave();
  }

  filterOptions($event) {
    this.filteredOptions = this.options.filter((option) => option.name.toLowerCase().indexOf($event) > -1);
  }

  /**
   * When the model is updated after save, re-run our logic
   * @param model
   */
  updateChangedModel(model: any): void {
    this.model = model;
    //this.value = this.model[this.fieldDef.name] * 100;
    this.value = this.model[this.fieldDef.name];

    // we want to track the original value, so that we don't hit save to many times
    this._originalValue = this.value;
  }

  onBlur($event: Event) {
    this.onSave();
    this.editing = false;
  }

  onSave() {
    if (this._originalValue != this.value) {
      this.updateModel();
      this.update.emit({ [this.fieldDef.name]: this.value });
    }
  }

  updateModel() {
    this.model[this.fieldDef.name] = this.value;
    this._originalValue = this.value;
  }
}
