import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { CalculatorComponent } from 'app/layout/common/calculator/calculator.component';
import { SharedModule } from 'app/shared/shared.module';
import { CalculatorModalComponent } from 'app/layout/common/calculator/calculator-modal/calculator-modal.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [CalculatorComponent, CalculatorModalComponent],
    imports: [
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        CurrencyMaskModule,
        MatDialogModule,
    ],
    exports: [CalculatorComponent]
})
export class CalculatorModule {}
