import { Injectable } from '@angular/core';
import { Store, Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { FileFormat } from 'app/shared/enums';
import { generateDownloadableFile } from 'app/shared/functions';
import { tap } from 'rxjs/operators';
import { ProjectsService } from '../projects.service';
import { Project } from '../projects.types';
import { GraphqlProject } from './actions';

export interface ProjectStateModel {
  graphqlResults: Project[];
}

@State<ProjectStateModel>({
  name: new StateToken('projectState'),
  defaults: {
    graphqlResults: null,
  },
})
@Injectable()
export class ProjectState {
  constructor(private readonly store: Store, private readonly projectService: ProjectsService) {}

  @Selector()
  static getGraphqlResults(state: ProjectStateModel) {
    return state.graphqlResults;
  }

  @Action(GraphqlProject.Action)
  graphqlProject(context: StateContext<ProjectStateModel>, { grapqlParams }: { grapqlParams: GraphqlProject.Params }) {
    const state = context.getState();

    return this.projectService.graphql(grapqlParams.fields, grapqlParams.filters, grapqlParams.exportOptions).pipe(
      tap((result) => {
        if (grapqlParams.exportOptions) {
          generateDownloadableFile(
            result as any,
            grapqlParams.exportOptions.format,
            grapqlParams.exportOptions.fileName,
          );
        } else {
          context.setState({ ...state, graphqlResults: result });
        }
      }),
    );
  }
}
