import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { User, UserAssociation } from 'app/core/models/user.types';
import { Project, Property } from 'app/modules/projects/projects.types';
import { Contact } from 'app/modules/contacts/contacts.types';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // Observables
  private _user: BehaviorSubject<User | null>;
  private _accountUserAssociation: BehaviorSubject<UserAssociation | null>;
  private _recentProjects: BehaviorSubject<Project[] | null>;
  private _recentContacts: BehaviorSubject<Contact[] | null>;
  private _recentProperties: BehaviorSubject<Contact[] | null>;
  private _role: BehaviorSubject<String | null>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the defaults
    this._user = new BehaviorSubject(null);
    this._role = new BehaviorSubject(null);
    this._recentProjects = new BehaviorSubject(null);
    this._recentContacts = new BehaviorSubject(null);
    this._recentProperties = new BehaviorSubject(null);
    this._accountUserAssociation = new BehaviorSubject(null);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Setter and getter for user
  set user(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  // Setter and getter for user
  set role(value: String) {
    // Store the value
    this._role.next(value);
  }

  get role$(): Observable<String> {
    return this._role.asObservable();
  }

  get recentProjects$(): Observable<Project[]> {
    return this._recentProjects.asObservable();
  }

  get recentContacts$(): Observable<Contact[]> {
    return this._recentContacts.asObservable();
  }

  get recentProperties$(): Observable<Contact[]> {
    return this._recentProperties.asObservable();
  }

  set accountUserAssociation(value: UserAssociation) {
    // Store the value
    this._accountUserAssociation.next(value);
  }

  get accountUserAssociation$(): Observable<UserAssociation> {
    return this._accountUserAssociation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user data
   *
   * @param user
   */
  update(user: User): Observable<User> {
    return this._httpClient.put<any>('user', { user }).pipe(
      map((response) => {
        // Execute the observable
        this._user.next(user);
        return response.user;
      }),
    );
  }

  uploadProfilePhoto(file: File): Observable<User> {
    const uploadData = new FormData();
    uploadData.append('image[attachment]', file);

    return this._httpClient.post<any>('user/avatar', uploadData).pipe(
      map((response) => {
        // Execute the observable
        this._user.next(response.user);
        return response.user;
      }),
    );
  }

  deleteProfilePhoto(): Observable<User> {
    return this._httpClient.delete<{ user: User }>('user/avatar').pipe(
      map((response) => {
        this._user.next(response.user);
        return response.user;
      }),
      catchError((err) => of(err)),
    );
  }

  getRecentProjects(accountId: string): Observable<Project[]> {
    return this._httpClient.get<any>(`user/recent_records?related_to_type=Project&account_id=${accountId}`).pipe(
      map((response) => {
        // Execute the observable
        this._recentProjects.next(response?.projects);
        return response?.projects;
      }),
    );
  }

  getRecentContacts(accountId: string): Observable<Project[]> {
    return this._httpClient.get<any>(`user/recent_records?related_to_type=Contact&account_id=${accountId}`).pipe(
      map((response) => {
        // Execute the observable
        this._recentContacts.next(response?.contacts);
        return response?.contacts;
      }),
    );
  }

  getRecentProperties(accountId: string): Observable<Property[]> {
    return this._httpClient.get<any>(`user/recent_records?related_to_type=Property&account_id=${accountId}`).pipe(
      map((response) => {
        // Execute the observable
        this._recentProperties.next(response?.properties);
        return response?.properties;
      }),
    );
  }

  getAccountUserAssociation(accountId: string): Observable<UserAssociation[]> {
    return this._httpClient.get<any>(`user/account_user_association?account_id=${accountId}`).pipe(
      map((response) => {
        // Execute the observable
        this._accountUserAssociation.next(response?.user_association);
        return response?.user_association;
      }),
    );
  }

  getUserSignature(): Observable<UserAssociation[]> {
    return this._httpClient.get<any>(`signature`).pipe(
      map((response) => {
        return response.body;
      }),
      catchError((error) => of('')),
    );
  }

  saveUserSignature(signature: any): Observable<User> {
    return this._httpClient.put<any>('signature', signature).pipe(
      map((response) => {
        return response.body;
      }),
    );
  }

  saveSettings(user: User, settings: any): Observable<User> {
    return this._httpClient.put<any>('user/settings', { settings: settings }).pipe(
      map((response) => {
        return response.user;
      }),
    );
  }

  getPusherAuth(): Observable<any> {
    return this._httpClient.post<any>('user/pusher_auth', {}).pipe(
      map((response) => {
        console.log(response);
        return response.pusher_token;
      }),
    );
  }
}
