import { NgModule } from '@angular/core';
import { ReadableFieldPipe } from './readable-field.pipe';
import { CustomFilterPipe } from './custom-filter.pipe';
import { FormatNumberPipe } from './format-number-pipe';
import { SafePipe } from './safe-pipe';
import { LinkifyPipe } from './linkify-pipe';
import { PhonePipe } from './phone-pipe';
import { OperatorToDisplayStringPipe } from './operator-to-display-string-pipe';
import { SortPipe } from './sort-pipe';
import { HtmlSanitizerPipe } from './html-sanitizer.pipe';
import { SortByPipe } from './sort-by-pipe';
import { FilterByPipe } from './filter-pipe';
import { FormatTaskTitlePipe } from './format-task-title.pipe';
import { TsCurrencyPipe } from './ts-currency.pipe';
import { FieldSearchPipe } from './field-definition-search.pipe';
import { RelatedToTypeTitlePipe } from './related-to-type.pipe';

@NgModule({
  imports: [],
  declarations: [
    FormatNumberPipe,
    SafePipe,
    FieldSearchPipe,
    LinkifyPipe,
    PhonePipe,
    OperatorToDisplayStringPipe,
    SortPipe,
    CustomFilterPipe,
    HtmlSanitizerPipe,
    ReadableFieldPipe,
    SortByPipe,
    FilterByPipe,
    FormatTaskTitlePipe,
    TsCurrencyPipe,
    RelatedToTypeTitlePipe,
  ],
  exports: [
    FormatNumberPipe,
    SafePipe,
    FieldSearchPipe,
    LinkifyPipe,
    PhonePipe,
    OperatorToDisplayStringPipe,
    SortPipe,
    CustomFilterPipe,
    HtmlSanitizerPipe,
    ReadableFieldPipe,
    SortByPipe,
    FilterByPipe,
    FormatTaskTitlePipe,
    TsCurrencyPipe,
    RelatedToTypeTitlePipe,
  ],
  providers: [
    FormatNumberPipe,
    SafePipe,
    FieldSearchPipe,
    LinkifyPipe,
    PhonePipe,
    OperatorToDisplayStringPipe,
    SortPipe,
    CustomFilterPipe,
    HtmlSanitizerPipe,
    ReadableFieldPipe,
    SortByPipe,
    FilterByPipe,
    FormatTaskTitlePipe,
    TsCurrencyPipe,
    RelatedToTypeTitlePipe,
  ],
})
export class PipesModule {}
