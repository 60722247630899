import { Component, Inject, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { pick } from 'lodash-es';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Account } from 'app/core/models/account.types';
import { AccountService } from 'app/core';
import { CompaniesService } from 'app/modules/companies/companies.service';
import { Company, Category } from 'app/modules/companies/companies.types';

@Component({
  selector: 'project-add-company',
  templateUrl: './project-add-company.component.html',
  styleUrls: ['./project-add-company.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectAddCompanyComponent extends OnDestroyMixin implements OnInit {
  companyForm: UntypedFormGroup;
  public categories: Category[];
  companies: Company[];
  companiesCount: number = 0;
  account: Account;
  companyId: null;
  private _companyName: string;
  public results: any[] | null;
  public companyResults: any[] | null;
  public uploadedFile: any;
  public isLoading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly matDialogRef: MatDialogRef<ProjectAddCompanyComponent>,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly companiesService: CompaniesService,
    private readonly accountService: AccountService,
  ) {
    super();
    this.accountService.currentAccount.pipe(untilComponentDestroyed(this)).subscribe((accountData) => {
      this.account = accountData;
      this.companiesService.accountId = `${this.account.id}`;
    });
  }

  ngOnInit(): void {
    this.companyForm = this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required]],
      website: [''],
    });
    this.companyId = this.data.contactId;

    if (this.data.byPass && this.companyId) {
      this.companiesService
        .getCompanyById(this.companyId)
        .pipe(untilComponentDestroyed(this))
        .subscribe((response: any) => {
          this.companyForm.patchValue(pick(response.company, 'id', 'name', 'website'));

          this._companyName = response.company.name;

          this.changeDetectorRef.markForCheck();
        });
    }

    this.companyForm
      .get('name')
      .valueChanges.pipe(untilComponentDestroyed(this), debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        let query = value;

        if (!query || (!query.id && query !== this._companyName)) {
          this.companiesService
            .getCompanies(1, 20, 'name', 'asc', query)
            .pipe(untilComponentDestroyed(this))
            .subscribe((response) => {
              this.companyResults = response.companies;
              this.changeDetectorRef.markForCheck();
            });
        }
      });
  }

  public get isSaveBtnDisabled(): boolean {
    return this.isLoading || this.companyForm.pristine || this.companyForm.invalid;
  }

  public saveCompanyAssociation(): Subscription {
    const companyFormValue = this.companyForm.value;

    const result = companyFormValue?.id
      ? this.companiesService.updateCompany(companyFormValue)
      : this.companiesService.createCompany(companyFormValue);

    return result
      .pipe(untilComponentDestroyed(this))
      .subscribe((company) => this.matDialogRef.close({ contact: company, contact_id: company.id }));
  }

  autocompleteSelected($event): void {
    this.companyForm.patchValue($event.option.value, { emitEvent: false });
  }

  autocompleteCompanySelected($event): void {
    const value = $event?.option?._element?.nativeElement?.innerText;
    let selected = this.companyResults.find((company) => company.id === $event.option.value);
    if (value != 'No companies found!') {
      this.companyForm.patchValue(selected, { emitEvent: false });
    }
  }

  discard(): void {
    this.matDialogRef.close();
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  // Function attached to debounce-click directive to flag the button's disable state.
  public isDebounceLoading(isLoading: boolean): boolean {
    return (this.isLoading = isLoading);
  }
}
