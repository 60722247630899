<mat-form-field class="flex-auto gt-xs:pr-3 no-edit" *ngIf="!editing" (click)="edit()" (focus)="edit()">
  <mat-label>{{ fieldDef.label }}</mat-label>
  <mat-chip-list #chipList aria-label="Tag selection">
    <mat-chip *ngFor="let tag of tags" [selectable]="false" [removable]="false">
      {{ tag }}
    </mat-chip>
  </mat-chip-list>
</mat-form-field>
<mat-form-field class="flex-auto gt-xs:pr-3 yes-edit" *ngIf="editing">
  <mat-label>{{ fieldDef.label }}</mat-label>
  <mat-chip-list #chipList aria-label="Tag selection">
    <mat-chip *ngFor="let tag of tags" [selectable]="selectable" [removable]="removable" (removed)="removeTag(tag)">
      {{ tag }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      placeholder="New tag..."
      #tagInput
      [formControl]="tagCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="addTag($event)"
    />
    <button mat-icon-button matSuffix (click)="onBlur($event)" class="treo-message-dismiss-button">
      <mat-icon matSuffix [svgIcon]="'save'"></mat-icon>
    </button>
  </mat-chip-list>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTag($event)">
    <mat-option *ngFor="let tag of foundTags" [value]="tag">
      {{ tag }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
