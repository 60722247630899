import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef, Input } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Project, Property } from 'app/modules/projects/projects.types';
import { ProjectsService } from 'app/modules/projects/projects.service';

@Component({
  selector: 'project-dialog-properties-panel',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectDialogPropertiesPanelComponent implements OnInit, OnDestroy {
  @Input() project: any;
  @Input() account: any;

  private _unsubscribeAll: Subject<void>;

  properties: Property[] = [];
  displayColumns: string[];
  folderId: number;

  /**
   * Constructor
   */
  constructor(private _projectsService: ProjectsService, private _changeDetectorRef: ChangeDetectorRef) {
    this._unsubscribeAll = new Subject();
    this.displayColumns = ['name', 'created'];
  }

  ngOnInit() {
    this.fillProperties();

    this._projectsService.project$.pipe(takeUntil(this._unsubscribeAll)).subscribe((project: Project) => {
      this.project = project;
      this.fillProperties();
      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  fillProperties() {
    if (this.project && this.project.properties && this.project.properties.length > 0) {
      this.properties = [...this.project.properties];
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {}

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
