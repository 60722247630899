import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

/**
 * How to use it:
 * <button
 *  DebounceClick
 *  (debounceClick)="doSomething()" (Function from parent component to be called)
 *  (debounceLoading)="isDebounceLoading($event)" (Boolean used as a disable flag for context button)
 *  [debounceTime]="700"
 * >
 *  Save Something
 * </button>
 */

@Directive({
  selector: '[DebounceClick]',
})
export class DebounceClickDirective implements OnInit, OnDestroy {
  @Input() debounceTime: number = 300;
  @Output() debounceClick: EventEmitter<unknown> = new EventEmitter();
  @Output() debounceLoading: EventEmitter<boolean> = new EventEmitter();

  private clicks$: Subject<unknown> = new Subject();
  private subscription$: Subscription;

  ngOnInit() {
    this.subscription$ = this.clicks$
      .pipe(
        debounceTime(this.debounceTime),
        tap(() => this.debounceLoading.emit(true)),
      )
      .subscribe({
        next: (event) => this.debounceClick.emit(event),
        complete: () => this.debounceLoading.emit(false),
      });
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks$.next(event);
  }
}
