import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError, ReplaySubject, Subject, EMPTY } from 'rxjs';
import { map, switchMap, take, tap, distinctUntilChanged, catchError, pluck } from 'rxjs/operators';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import {
  Account,
  SavedView,
  ProjectType,
  PropertyType,
  ProjectStage,
  FieldDef,
  InvestmentType,
  Team,
  Vehicle,
  SavedFilter,
  MarketStatus,
  Activity,
  PipelineTemplate,
  AccountIntegration,
  LoanPurpose,
  ProjectPhase,
} from 'app/core/models/account.types';
import { User, UserAssociation, Invitation } from 'app/core/models/user.types';
import { Category } from 'app/modules/contacts/contacts.types';
import { Permission } from '../models/permission';

const vehiclesCacheBuster$ = new Subject<void>();
const propertyTypesCacheBuster$ = new Subject<void>();
const projectStagesCacheBuster$ = new Subject<void>();
const investmentTypesCacheBuster$ = new Subject<void>();
const projectTypesCacheBuster$ = new Subject<void>();
const usersCacheBuster$ = new Subject<void>();
const teamsCacheBuster$ = new Subject<void>();
const fieldDefsCacheBuster$ = new Subject<void>();
const savedFiltersCacheBuster$ = new Subject<void>();
const invitationsCacheBuster$ = new Subject<void>();
const marketStatusesCacheBuster$ = new Subject<void>();
const categoriesCacheBuster$ = new Subject<void>();
const loanPurposesCacheBuster$ = new Subject<void>();
const savedViewsCacheBuster$ = new Subject<void>();
const projectPhasesCacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private _subdomain: string;
  private currentAccountSubject = new BehaviorSubject<Account>({} as Account);
  public currentAccount = this.currentAccountSubject.asObservable().pipe(distinctUntilChanged());
  private hasSubdomainSubject = new ReplaySubject<boolean>(1);
  public hasSubdomain = this.hasSubdomainSubject.asObservable();
  private _hasSubdomain = false;
  private _userAssociations: BehaviorSubject<UserAssociation[] | null>;
  private _users: BehaviorSubject<User[] | null>;
  private _teams: BehaviorSubject<Team[] | null>;
  private _vehicles: BehaviorSubject<Vehicle[] | null>;
  private _invitations: BehaviorSubject<Invitation[] | null>;
  private _accountIntegrationsError: BehaviorSubject<HttpErrorResponse>;
  private _projectTypes: BehaviorSubject<ProjectType[] | null>;
  private _propertyTypes: BehaviorSubject<PropertyType[] | null>;
  private _projectStages: BehaviorSubject<ProjectStage[] | null>;
  private _investmentTypes: BehaviorSubject<InvestmentType[] | null>;
  private _fieldDefs: BehaviorSubject<FieldDef[] | null>;
  private _defaultFieldDefs: BehaviorSubject<FieldDef[] | null>;
  private _savedFilters: BehaviorSubject<SavedFilter[] | null>;
  private _marketStatuses: BehaviorSubject<MarketStatus[] | null>;
  private _categories: BehaviorSubject<Category[] | null>;
  private _loanPurposes: BehaviorSubject<LoanPurpose[] | null>;
  private _savedViews: BehaviorSubject<SavedView[] | null>;
  private _savedView: BehaviorSubject<SavedView | null>;
  private _projectPhases: BehaviorSubject<ProjectPhase[] | null>;

  constructor(private _httpClient: HttpClient) {
    this._userAssociations = new BehaviorSubject(null);
    this._users = new BehaviorSubject(null);
    this._accountIntegrationsError = new BehaviorSubject(null);
    this._projectTypes = new BehaviorSubject(null);
    this._propertyTypes = new BehaviorSubject(null);
    this._projectStages = new BehaviorSubject(null);
    this._investmentTypes = new BehaviorSubject(null);
    this._fieldDefs = new BehaviorSubject(null);
    this._defaultFieldDefs = new BehaviorSubject(null);
    this._teams = new BehaviorSubject(null);
    this._vehicles = new BehaviorSubject(null);
    this._savedFilters = new BehaviorSubject(null);
    this._invitations = new BehaviorSubject(null);
    this._marketStatuses = new BehaviorSubject(null);
    this._categories = new BehaviorSubject(null);
    this._loanPurposes = new BehaviorSubject(null);
    this._savedViews = new BehaviorSubject(null);
    this._savedView = new BehaviorSubject(null);
    this._projectPhases = new BehaviorSubject(null);
  }

  get userAssociations$(): Observable<UserAssociation[]> {
    return this._userAssociations.asObservable();
  }

  get users$(): Observable<User[]> {
    return this._users.asObservable();
  }

  get invitations$(): Observable<Invitation[]> {
    return this._invitations.asObservable();
  }

  /**
   * Getter for account integrations network error
   */
  get accountIntegrationsError$(): Observable<HttpErrorResponse> {
    return this._accountIntegrationsError.asObservable();
  }

  /**
   * Getter for contacts
   */
  get projectTypes$(): Observable<ProjectType[]> {
    return this._projectTypes.asObservable();
  }

  /**
   * Getter for contacts
   */
  get propertyTypes$(): Observable<PropertyType[]> {
    return this._propertyTypes.asObservable();
  }

  /**
   * Getter for contacts
   */
  get projectStages$(): Observable<ProjectStage[]> {
    return this._projectStages.asObservable();
  }

  get projectPhases$(): Observable<ProjectPhase[]> {
    return this._projectPhases.asObservable();
  }

  get investmentTypes$(): Observable<InvestmentType[]> {
    return this._investmentTypes.asObservable();
  }

  get categories$(): Observable<Category[]> {
    return this._categories.asObservable();
  }

  get teams$(): Observable<Team[]> {
    return this._teams.asObservable();
  }

  get vehicles$(): Observable<Team[]> {
    return this._vehicles.asObservable();
  }

  get fieldDefs$(): Observable<FieldDef[]> {
    return this._fieldDefs.asObservable();
  }

  get defaultFieldDefs$(): Observable<FieldDef[]> {
    return this._defaultFieldDefs.asObservable();
  }

  get savedFilters$(): Observable<SavedFilter[]> {
    return this._savedFilters.asObservable();
  }

  get marketStatuses$(): Observable<MarketStatus[]> {
    return this._marketStatuses.asObservable();
  }

  get loanPurposes$(): Observable<LoanPurpose[]> {
    return this._loanPurposes.asObservable();
  }

  get savedViews$(): Observable<SavedView[]> {
    return this._savedViews.asObservable();
  }

  get savedView$(): Observable<SavedView> {
    return this._savedView.asObservable();
  }

  get savedViewValue(): SavedView {
    return this._savedView.value;
  }

  loadAccount(subdomain: string): Observable<Account> {
    return this._httpClient.get<{ account: Account }>(`/accounts/by_subdomain/${subdomain}`).pipe(
      tap((response) => {
        this.setAccount(response.account);
        this.getUsers().subscribe();
      }),
      map(({ account }) => account),
    );
  }

  getAccount(subdomain: string): Observable<Account> {
    return this._httpClient.get<any>(`/accounts/by_subdomain/${subdomain}`).pipe(map((response) => response.account));
  }

  updateAccount(account: Partial<Account & { configuration?: any }>): Observable<Account> {
    return this._httpClient.put<any>(`accounts/${account.id}`, { account: account }).pipe(
      map((response) => {
        this.setAccount(response.account);
        return response.account;
      }),
    );
  }

  getSingleBySubdomain(subdomain: string): Observable<any> {
    return this._httpClient.get<any>(`/accounts/by_subdomain/${subdomain}`).pipe(
      tap((data) => {
        return data.account;
      }),
    );
  }

  populateAccountFromSubdomain() {
    if (this._subdomain) {
      this.getSingleBySubdomain(this._subdomain).subscribe((account) => {
        this.setAccount(account);
      });
    }
  }

  @Cacheable({
    cacheBusterObserver: usersCacheBuster$,
  })
  getUsers(): Observable<User[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`accounts/${account.id}/user_associations`).pipe(
          map((reponse) => {
            // Update the labels
            this._userAssociations.next(reponse.user_associations);

            let users = reponse.user_associations.map((ua) => ua.user);
            this._users.next(users);

            // Return the deleted status
            return users;
          }),
        ),
      ),
    );
  }

  updateUserAssociation(userAssociation): Observable<UserAssociation> {
    let account = this.getCurrentAccount();
    return this.userAssociations$.pipe(
      take(1),
      switchMap((userAssociations) =>
        this._httpClient
          .put<any>(
            `accounts/${account.id}/user_associations/${userAssociation.id}?user_id=${userAssociation.user.id}`,
            { user_association: userAssociation },
          )
          .pipe(
            map((response) => {
              let updatedUserAssociation = response.user_association;

              // Find the index of the updated contact
              const index = userAssociations.findIndex((item) => item.id === updatedUserAssociation.id);
              // Update the contact
              userAssociations[index] = updatedUserAssociation;

              // Update the contacts
              this._userAssociations.next(userAssociations);

              // Return the updated contact
              return updatedUserAssociation;
            }),
          ),
      ),
    );
  }

  deleteUserAssociation(userAssociation): Observable<UserAssociation> {
    let account = this.getCurrentAccount();
    return this.userAssociations$.pipe(
      take(1),
      switchMap((userAssociations) =>
        this._httpClient
          .delete<any>(
            `accounts/${account.id}/user_associations/${userAssociation.id}?user_id=${userAssociation.user.id}`,
          )
          .pipe(
            map((response) => {
              //[sc-9647]
              if (userAssociation.related_to_type == 'Account') {
                // Find the index of the deleted label within the labels
                const index = userAssociations.findIndex((item) => item.id === userAssociation.id);

                // Delete the label
                userAssociations[index] = response.user_association;

                // Update the labels
                this._userAssociations.next(userAssociations);
              } else {
                // Find the index of the deleted label within the labels
                const index = userAssociations.findIndex((item) => item.id === userAssociation.id);

                // Delete the label
                userAssociations.splice(index, 1);

                // Update the labels
                this._userAssociations.next(userAssociations);
              }

              // Return the deleted status
              return response.user_association;
            }),
          ),
      ),
    );
  }

  toggleActiveUserAssociation(userAssociation): Observable<UserAssociation> {
    let account = this.getCurrentAccount();
    return this.userAssociations$.pipe(
      take(1),
      switchMap((userAssociations) =>
        this._httpClient
          .post<any>(
            `accounts/${account.id}/user_associations/${userAssociation.id}/toggle_active_user?user_id=${userAssociation.user.id}`,
            { user_association: userAssociation },
          )
          .pipe(
            map((response) => {
              //[sc-9647]
              if (userAssociation.related_to_type == 'Account') {
                // Find the index of the deleted label within the labels
                const index = userAssociations.findIndex((item) => item.id === userAssociation.id);

                // Delete the label
                userAssociations[index] = response.user_association;

                // Update the labels
                this._userAssociations.next(userAssociations);
              } else {
                // Find the index of the deleted label within the labels
                const index = userAssociations.findIndex((item) => item.id === userAssociation.id);

                // Delete the label
                userAssociations.splice(index, 1);

                // Update the labels
                this._userAssociations.next(userAssociations);
              }

              // Return the deleted status
              return response.user_association;
            }),
          ),
      ),
    );
  }

  @Cacheable({
    cacheBusterObserver: invitationsCacheBuster$,
  })
  getInvitations(): Observable<Invitation[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`accounts/${account.id}/invitations`).pipe(
          map((reponse) => {
            // Update the labels
            this._invitations.next(reponse.invitations);

            // Return the deleted status
            return reponse.invitations;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: invitationsCacheBuster$,
  })
  sendInvitation(invitation: Invitation): Observable<Invitation> {
    let account = this.getCurrentAccount();
    return this.invitations$.pipe(
      take(1),
      switchMap((invitations) =>
        this._httpClient.post<any>(`accounts/${account.id}/invitations`, { invitation: invitation }).pipe(
          map((reponse) => {
            return reponse.invitation;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: invitationsCacheBuster$,
  })
  deleteInvitation(id: number): Observable<Invitation> {
    let account = this.getCurrentAccount();
    return this.invitations$.pipe(
      take(1),
      switchMap((invitations) =>
        this._httpClient.delete<any>(`invitations/${id}?account_id=${account.id}`).pipe(
          map((response) => {
            // Find the index of the deleted label within the labels
            const index = invitations.findIndex((item) => item.id === id);

            // Delete the label
            invitations.splice(index, 1);

            // Update the labels
            this._invitations.next(invitations);

            // Return the deleted status
            return response.invitation;
          }),
        ),
      ),
    );
  }

  setAccount(account: Account) {
    this.currentAccountSubject.next(account);
    this.hasSubdomainSubject.next(true);
    this._hasSubdomain = true;
  }

  getCurrentAccount(): Account {
    return this.currentAccountSubject.value;
  }

  setSubdomain(subdomain) {
    this._subdomain = subdomain;
  }

  getSubdomain() {
    return this._subdomain;
  }

  doesHaveSubdomain() {
    return this._hasSubdomain;
  }

  getSavedFiltersValue(): SavedFilter[] {
    return this._savedFilters.value;
  }

  /**
   * Get countries
   */
  @Cacheable({
    cacheBusterObserver: projectStagesCacheBuster$,
  })
  getProjectStages(): Observable<ProjectStage[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`project_stages?account_id=${account.id}`).pipe(
          map((response) => {
            this._projectStages.next(response.project_stages);
            return response.project_stages;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: projectStagesCacheBuster$,
  })
  updateProjectStage(projectStage: ProjectStage): Observable<ProjectStage> {
    let account = this.getCurrentAccount();
    return this.projectStages$.pipe(
      take(1),
      switchMap((projectStages) =>
        this._httpClient
          .put<any>(`project_stages/${projectStage.id}?account_id=${account.id}`, { project_stage: projectStage })
          .pipe(
            map((response) => {
              let updatedProjectStage = response.project_stage;

              // Find the index of the updated contact
              const index = projectStages.findIndex((item) => item.id === updatedProjectStage.id);
              // Update the contact
              projectStages[index] = updatedProjectStage;

              // Update the contacts
              this._projectStages.next(projectStages);

              // Return the updated contact
              return updatedProjectStage;
            }),
          ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: projectStagesCacheBuster$,
  })
  createProjectStage(projectStage: ProjectStage): Observable<ProjectStage> {
    let account = this.getCurrentAccount();
    return this.projectStages$.pipe(
      take(1),
      switchMap((projectStages) =>
        this._httpClient.post<any>(`project_stages?account_id=${account.id}`, { project_stage: projectStage }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._projectStages.next([reponse.project_stage, ...projectStages]);

            // Return the new contact
            return reponse.project_stage;
          }),
        ),
      ),
    );
  }

  @Cacheable({
    cacheBusterObserver: projectPhasesCacheBuster$,
  })
  getProjectPhases(): Observable<ProjectPhase[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`project_phases?account_id=${account.id}`).pipe(
          map((response) => {
            this._projectPhases.next(response.project_phases);
            return response.project_phases;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: projectPhasesCacheBuster$,
  })
  updateProjectPhase(projectPhase: ProjectPhase): Observable<ProjectPhase> {
    let account = this.getCurrentAccount();
    return this.projectPhases$.pipe(
      take(1),
      switchMap((projectPhases) =>
        this._httpClient
          .put<any>(`project_phases/${projectPhase.id}?account_id=${account.id}`, { project_phase: projectPhase })
          .pipe(
            map((response) => {
              let updatedProjectPhase = response.project_phase;

              // Find the index of the updated contact
              const index = projectPhases.findIndex((item) => item.id === updatedProjectPhase.id);
              // Update the contact
              projectPhases[index] = updatedProjectPhase;

              // Update the contacts
              this._projectPhases.next(projectPhases);

              // Return the updated contact
              return updatedProjectPhase;
            }),
          ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: projectPhasesCacheBuster$,
  })
  createProjectPhase(projectPhase: ProjectPhase): Observable<ProjectPhase> {
    let account = this.getCurrentAccount();
    return this.projectPhases$.pipe(
      take(1),
      switchMap((projectPhases) =>
        this._httpClient.post<any>(`project_phases?account_id=${account.id}`, { project_phase: projectPhase }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._projectPhases.next([reponse.project_phase, ...projectPhases]);

            // Return the new contact
            return reponse.project_phase;
          }),
        ),
      ),
    );
  }

  /**
   * Get countries
   */
  @Cacheable({
    cacheBusterObserver: projectTypesCacheBuster$,
  })
  getProjectTypes(): Observable<ProjectType[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`project_types?account_id=${account.id}`).pipe(
          map((response) => {
            this._projectTypes.next(response.project_types);
            return response.project_types;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: projectTypesCacheBuster$,
  })
  updateProjectType(projectType: ProjectType): Observable<ProjectType> {
    let account = this.getCurrentAccount();
    return this.projectTypes$.pipe(
      take(1),
      switchMap((projectTypes) =>
        this._httpClient
          .put<any>(`project_types/${projectType.id}?account_id=${account.id}`, { project_type: projectType })
          .pipe(
            map((response) => {
              let updatedProjectType = response.project_type;

              // Find the index of the updated contact
              const index = projectTypes.findIndex((item) => item.id === updatedProjectType.id);
              // Update the contact
              projectTypes[index] = updatedProjectType;

              // Update the contacts
              this._propertyTypes.next(projectTypes);

              // Return the updated contact
              return updatedProjectType;
            }),
          ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: projectTypesCacheBuster$,
  })
  createProjectType(projectType: ProjectType): Observable<ProjectType> {
    let account = this.getCurrentAccount();
    return this.projectTypes$.pipe(
      take(1),
      switchMap((projectTypes) =>
        this._httpClient.post<any>(`project_types?account_id=${account.id}`, { project_type: projectType }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._projectTypes.next([reponse.project_type, ...projectTypes]);

            // Return the new contact
            return reponse.project_type;
          }),
        ),
      ),
    );
  }

  /**
   * Get countries
   */
  @Cacheable({
    cacheBusterObserver: propertyTypesCacheBuster$,
  })
  getPropertyTypes(): Observable<PropertyType[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`property_types?account_id=${account.id}`).pipe(
          map((response) => {
            this._propertyTypes.next(response.property_types);
            return response.property_types;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: propertyTypesCacheBuster$,
  })
  updatePropertyType(propertyType: PropertyType): Observable<PropertyType> {
    let account = this.getCurrentAccount();
    return this.propertyTypes$.pipe(
      take(1),
      switchMap((propertyTypes) =>
        this._httpClient
          .put<any>(`property_types/${propertyType.id}?account_id=${account.id}`, { property_type: propertyType })
          .pipe(
            map((response) => {
              let updatedPropertyType = response.property_type;

              // Find the index of the updated contact
              const index = propertyTypes.findIndex((item) => item.id === updatedPropertyType.id);
              // Update the contact
              propertyTypes[index] = updatedPropertyType;

              // Update the contacts
              this._propertyTypes.next(propertyTypes);

              // Return the updated contact
              return updatedPropertyType;
            }),
          ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: propertyTypesCacheBuster$,
  })
  createPropertyType(propertyType: PropertyType): Observable<PropertyType> {
    let account = this.getCurrentAccount();
    return this.propertyTypes$.pipe(
      take(1),
      switchMap((propertyTypes) =>
        this._httpClient.post<any>(`property_types?account_id=${account.id}`, { property_type: propertyType }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._propertyTypes.next([reponse.property_type, ...propertyTypes]);

            // Return the new contact
            return reponse.property_type;
          }),
        ),
      ),
    );
  }

  @Cacheable({
    cacheBusterObserver: investmentTypesCacheBuster$,
  })
  getInvestmentTypes(): Observable<InvestmentType[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`investment_types?account_id=${account.id}`).pipe(
          map((response) => {
            this._investmentTypes.next(response.investment_types);
            return response.investment_types;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: investmentTypesCacheBuster$,
  })
  updateInvestmentType(investmentType: InvestmentType): Observable<InvestmentType> {
    let account = this.getCurrentAccount();
    return this.investmentTypes$.pipe(
      take(1),
      switchMap((investmentTypes) =>
        this._httpClient
          .put<any>(`investment_types/${investmentType.id}?account_id=${account.id}`, {
            investment_type: investmentType,
          })
          .pipe(
            map((response) => {
              let updatedInvestmentType = response.investment_type;

              // Find the index of the updated contact
              const index = investmentTypes.findIndex((item) => item.id === updatedInvestmentType.id);
              // Update the contact
              investmentTypes[index] = updatedInvestmentType;

              // Update the contacts
              this._investmentTypes.next(investmentTypes);

              // Return the updated contact
              return updatedInvestmentType;
            }),
          ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: investmentTypesCacheBuster$,
  })
  createInvestmentType(investmentType: InvestmentType): Observable<InvestmentType> {
    let account = this.getCurrentAccount();
    return this.investmentTypes$.pipe(
      take(1),
      switchMap((investmentTypes) =>
        this._httpClient
          .post<any>(`investment_types?account_id=${account.id}`, { investment_type: investmentType })
          .pipe(
            map((reponse) => {
              // Update the contacts with the new contact
              this._investmentTypes.next([reponse.investment_type, ...investmentTypes]);

              // Return the new contact
              return reponse.investment_type;
            }),
          ),
      ),
    );
  }

  @Cacheable({
    cacheBusterObserver: marketStatusesCacheBuster$,
  })
  getMarketStatuses(): Observable<MarketStatus[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`market_statuses?account_id=${account.id}`).pipe(
          map((response) => {
            this._marketStatuses.next(response.market_statuses);
            return response.market_statuses;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: marketStatusesCacheBuster$,
  })
  updateMarketStatus(marketStatus: MarketStatus): Observable<MarketStatus> {
    let account = this.getCurrentAccount();
    return this.marketStatuses$.pipe(
      take(1),
      switchMap((marketStatuses) =>
        this._httpClient
          .put<any>(`market_statuses/${marketStatus.id}?account_id=${account.id}`, { market_status: marketStatus })
          .pipe(
            map((response) => {
              let updatedMarketStatus = response.market_status;

              // Find the index of the updated contact
              const index = marketStatuses.findIndex((item) => item.id === updatedMarketStatus.id);
              // Update the contact
              marketStatuses[index] = updatedMarketStatus;

              // Update the contacts
              this._marketStatuses.next(marketStatuses);

              // Return the updated contact
              return updatedMarketStatus;
            }),
          ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: marketStatusesCacheBuster$,
  })
  createMarketStatus(marketStatus: MarketStatus): Observable<MarketStatus> {
    let account = this.getCurrentAccount();
    return this.marketStatuses$.pipe(
      take(1),
      switchMap((marketStatuses) =>
        this._httpClient.post<any>(`market_statuses?account_id=${account.id}`, { market_status: marketStatus }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._marketStatuses.next([reponse.market_status, ...marketStatuses]);

            // Return the new contact
            return reponse.market_status;
          }),
        ),
      ),
    );
  }

  @Cacheable({
    cacheBusterObserver: teamsCacheBuster$,
  })
  getTeams(): Observable<Team[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`accounts/${account.id}/teams`).pipe(
          map((response) => {
            this._teams.next(response.teams);
            return response.teams;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: teamsCacheBuster$,
  })
  updateTeam(team: Team): Observable<Team> {
    let account = this.getCurrentAccount();
    return this.teams$.pipe(
      take(1),
      switchMap((teams) =>
        this._httpClient.put<any>(`accounts/${account.id}/teams/${team.id}`, { team: team }).pipe(
          map((response) => {
            let updatedTeam = response.team;

            // Find the index of the updated contact
            const index = teams.findIndex((item) => item.id === updatedTeam.id);
            // Update the contact
            teams[index] = updatedTeam;

            // Update the contacts
            this._teams.next(teams);

            // Return the updated contact
            return updatedTeam;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: teamsCacheBuster$,
  })
  createTeam(team: Team): Observable<Team> {
    let account = this.getCurrentAccount();
    return this.teams$.pipe(
      take(1),
      switchMap((teams) =>
        this._httpClient.post<any>(`accounts/${account.id}/teams`, { team: team }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._teams.next([reponse.team, ...teams]);

            // Return the new contact
            return reponse.team;
          }),
        ),
      ),
    );
  }

  addUserToTeam(team: Team, user: User): Observable<Team> {
    let account = this.getCurrentAccount();
    return this.teams$.pipe(
      take(1),
      switchMap((teams) =>
        this._httpClient.post<any>(`accounts/${account.id}/teams/${team.id}/add_user`, { user_id: user.id }).pipe(
          map((response) => {
            let updatedTeam = response.team;

            // Find the index of the updated contact
            const index = teams.findIndex((item) => item.id === updatedTeam.id);
            // Update the contact
            teams[index] = updatedTeam;

            // Update the contacts
            this._teams.next(teams);
            /// Return the new contact
            return response.team;
          }),
        ),
      ),
    );
  }

  removeUserFromTeam(team: Team, user: User): Observable<Team> {
    let account = this.getCurrentAccount();
    return this.teams$.pipe(
      take(1),
      switchMap((teams) =>
        this._httpClient.post<any>(`accounts/${account.id}/teams/${team.id}/remove_user`, { user_id: user.id }).pipe(
          map((response) => {
            let updatedTeam = response.team;

            // Find the index of the updated contact
            const index = teams.findIndex((item) => item.id === updatedTeam.id);
            // Update the contact
            teams[index] = updatedTeam;

            // Update the contacts
            this._teams.next(teams);
            /// Return the new contact
            return response.team;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: teamsCacheBuster$,
  })
  deleteTeam(id: number): Observable<any> {
    let account = this.getCurrentAccount();
    return this.teams$.pipe(
      take(1),
      switchMap((teams) =>
        this._httpClient.delete<any>(`accounts/${account.id}/teams/${id}`).pipe(
          map((response) => {
            // Find the index of the deleted label within the labels
            const index = teams.findIndex((item) => item.id === id);

            // Delete the label
            teams.splice(index, 1);

            // Update the labels
            this._teams.next(teams);

            // Return the deleted status
            return response.team;
          }),
        ),
      ),
    );
  }

  @Cacheable({
    cacheBusterObserver: categoriesCacheBuster$,
  })
  getCategories(): Observable<Category[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`categories?account_id=${account.id}`).pipe(
          map((response) => {
            this._categories.next(response.categories);
            return response.categories;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: categoriesCacheBuster$,
  })
  createCategory(category: Category): Observable<Category> {
    let account = this.getCurrentAccount();
    return this.categories$.pipe(
      take(1),
      switchMap((categories) =>
        this._httpClient.post<any>(`categories?account_id=${account.id}`, { category: category }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._categories.next([reponse.category, ...categories]);

            // Return the new contact
            return reponse.category;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: categoriesCacheBuster$,
  })
  updateCategory(category: Category): Observable<Category> {
    let account = this.getCurrentAccount();
    return this.categories$.pipe(
      take(1),
      switchMap((categories) =>
        this._httpClient.put<any>(`categories/${category.id}?account_id=${account.id}`, { category: category }).pipe(
          map((response) => {
            let updatedCategory = response.category;

            // Find the index of the updated contact
            const index = categories.findIndex((item) => item.id === updatedCategory.id);
            // Update the contact
            categories[index] = updatedCategory;

            // Update the contacts
            this._categories.next(categories);

            // Return the updated contact
            return updatedCategory;
          }),
        ),
      ),
    );
  }

  @Cacheable({
    cacheBusterObserver: vehiclesCacheBuster$,
  })
  getVehicles(): Observable<Team[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`vehicles?account_id=${account.id}`).pipe(
          map((response) => {
            this._vehicles.next(response.vehicles);
            return response.vehicles;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: vehiclesCacheBuster$,
  })
  updateVehicle(vehicle: Vehicle): Observable<Vehicle> {
    let account = this.getCurrentAccount();
    return this.vehicles$.pipe(
      take(1),
      switchMap((vehicles) =>
        this._httpClient.put<any>(`vehicles/${vehicle.id}?account_id=${account.id}`, { vehicle: vehicle }).pipe(
          map((response) => {
            let updatedVehicle = response.vehicle;

            // Find the index of the updated contact
            const index = vehicles.findIndex((item) => item.id === updatedVehicle.id);
            // Update the contact
            vehicles[index] = updatedVehicle;

            // Update the contacts
            this._vehicles.next(vehicles);

            // Return the updated contact
            return updatedVehicle;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: vehiclesCacheBuster$,
  })
  createVehicle(vehicle: Vehicle): Observable<Vehicle> {
    let account = this.getCurrentAccount();
    return this.vehicles$.pipe(
      take(1),
      switchMap((vehicles) =>
        this._httpClient.post<any>(`vehicles?account_id=${account.id}`, { vehicle: vehicle }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._vehicles.next([reponse.vehicle, ...vehicles]);

            // Return the new contact
            return reponse.vehicle;
          }),
        ),
      ),
    );
  }

  @Cacheable({
    cacheBusterObserver: loanPurposesCacheBuster$,
  })
  getLoanPurposes(): Observable<LoanPurpose[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`loan_purposes?account_id=${account.id}`).pipe(
          map((response) => {
            this._loanPurposes.next(response.loan_purposes);
            return response.loan_purposes;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: loanPurposesCacheBuster$,
  })
  updateLoanPurpose(loanPurpose: LoanPurpose): Observable<LoanPurpose> {
    let account = this.getCurrentAccount();
    return this.loanPurposes$.pipe(
      take(1),
      switchMap((loanPurposes) =>
        this._httpClient
          .put<any>(`loan_purposes/${loanPurpose.id}?account_id=${account.id}`, { loan_purpose: loanPurpose })
          .pipe(
            map((response) => {
              let updatedLoanPurpose = response.loan_purpose;

              // Find the index of the updated contact
              const index = loanPurposes.findIndex((item) => item.id === updatedLoanPurpose.id);
              // Update the contact
              loanPurposes[index] = updatedLoanPurpose;

              // Update the contacts
              this._loanPurposes.next(loanPurposes);

              // Return the updated contact
              return updatedLoanPurpose;
            }),
          ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: loanPurposesCacheBuster$,
  })
  createLoanPurpose(loanPurpose: LoanPurpose): Observable<LoanPurpose> {
    let account = this.getCurrentAccount();
    return this.loanPurposes$.pipe(
      take(1),
      switchMap((loanPurposes) =>
        this._httpClient.post<any>(`loan_purposes?account_id=${account.id}`, { loan_purpose: loanPurpose }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._loanPurposes.next([reponse.loan_purpose, ...loanPurposes]);

            // Return the new contact
            return reponse.loan_purpose;
          }),
        ),
      ),
    );
  }

  /**
   * Get Field Defs
   */
  @Cacheable({
    cacheBusterObserver: fieldDefsCacheBuster$,
  })
  getFieldDefs(format: string = ''): Observable<FieldDef[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`fields?format=${format}&account_id=${account.id}`).pipe(
          map((response) => {
            if (format == 'csv' || format == 'pdf') {
              return response.data;
            } else {
              if (response?.fields?.length) {
                const defaultFieldDefs = response.fields.filter((f) => f?.meta?.is_calculated);
                this._defaultFieldDefs.next(defaultFieldDefs);
              }
              this._fieldDefs.next(response.fields);
              return response.fields;
            }
          }),
        ),
      ),
    );
  }

  getFieldDefsByAccountId(accountId: string): Observable<FieldDef[]> {
    return this._httpClient
      .get<{ fields: FieldDef[] }>(`fields?account_id=${accountId}`)
      .pipe(map(({ fields }) => fields));
  }

  @Cacheable({
    cacheBusterObserver: savedFiltersCacheBuster$,
  })
  getSavedFilters(): Observable<SavedFilter[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`saved_filters?account_id=${account.id}`).pipe(
          map((response) => {
            this._savedFilters.next(response.saved_filters);
            return response.saved_filters;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: savedFiltersCacheBuster$,
  })
  saveFilter(savedFilter: SavedFilter): Observable<SavedFilter> {
    let account = this.getCurrentAccount();
    return this.savedFilters$.pipe(
      take(1),
      switchMap((savedFilters) =>
        this._httpClient.post<any>(`saved_filters?account_id=${account.id}`, { saved_filter: savedFilter }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._savedFilters.next([reponse.saved_filter, ...savedFilters]);

            // Return the new contact
            return reponse.saved_filter;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: savedFiltersCacheBuster$,
  })
  updateFilter(savedFilter: SavedFilter): Observable<SavedFilter> {
    let account = this.getCurrentAccount();
    return this.savedFilters$.pipe(
      take(1),
      switchMap((savedFilters) =>
        this._httpClient
          .put<any>(`saved_filters/${savedFilter.id}?account_id=${account.id}`, { saved_filter: savedFilter })
          .pipe(
            map((response) => {
              let updatedFilter = response.saved_filter;
              // Find the index of the updated contact
              const index = savedFilters.findIndex((item) => item.id === updatedFilter.id);

              // Update the contact
              savedFilters[index] = updatedFilter;

              // Update the contacts
              this._savedFilters.next(savedFilters);

              // Return the new contact
              return response.saved_filter;
            }),
          ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: savedFiltersCacheBuster$,
  })
  deleteFilter(id: number): Observable<any> {
    let account = this.getCurrentAccount();
    return this.savedFilters$.pipe(
      take(1),
      switchMap((savedFilters) =>
        this._httpClient.delete<any>(`saved_filters/${id}?account_id=${account.id}`).pipe(
          map((response) => {
            // Find the index of the deleted label within the labels
            const index = savedFilters.findIndex((item) => item.id === id);

            // Delete the label
            savedFilters.splice(index, 1);

            // Update the labels
            this._savedFilters.next(savedFilters);

            // Return the deleted status
            return response.saved_filter;
          }),
        ),
      ),
    );
  }

  getSavedFilterById(id: number): Observable<SavedFilter> {
    return this._savedFilters.pipe(
      take(1),
      map((savedFilters) => {
        // Find the product
        const savedFilter = savedFilters.find((item) => item.id === id) || null;

        // Return the product
        return savedFilter;
      }),
      switchMap((savedFilter) => {
        if (!savedFilter) {
          return throwError('Could not found product with id of ' + id + '!');
        }

        return of(savedFilter);
      }),
    );
  }

  /**
   * Add CustomField
   */
  @CacheBuster({
    cacheBusterNotifier: fieldDefsCacheBuster$,
  })
  createCustomField(fieldDef): Observable<FieldDef> {
    let account = this.getCurrentAccount();
    return this.fieldDefs$.pipe(
      take(1),
      switchMap((fieldDefs) =>
        this._httpClient.post<any>(`custom_fields?account_id=${account.id}`, { custom_field: fieldDef }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._fieldDefs.next([reponse?.custom_field, ...fieldDefs]);

            // Return the new contact
            return reponse?.custom_field;
          }),
        ),
      ),
    );
  }

  updateCustomField(fieldDef): Observable<FieldDef> {
    const account = this.getCurrentAccount();
    return this.fieldDefs$.pipe(
      take(1),
      switchMap((fieldDefs) =>
        this._httpClient
          .put<any>(`custom_fields/${fieldDef.id}?account_id=${account.id}`, { custom_field: fieldDef })
          .pipe(
            map((reponse) => {
              this._fieldDefs.next(fieldDefs.map((item) => (item.id === fieldDef.id ? reponse.custom_field : item)));

              return reponse.custom_field;
            }),
          ),
      ),
    );
  }

  deleteCustomField(fieldDef): Observable<FieldDef> {
    const account = this.getCurrentAccount();
    return this.fieldDefs$.pipe(
      take(1),
      switchMap((fieldDefs) =>
        this._httpClient.delete<any>(`custom_fields/${fieldDef.id}?account_id=${account.id}`).pipe(
          map(() => {
            const index = fieldDefs.findIndex((item) => item.id === fieldDef.id);

            fieldDefs.splice(index, 1);

            this._fieldDefs.next([...fieldDefs]);

            return fieldDef;
          }),
        ),
      ),
    );
  }

  getActivities(params: any): Observable<Activity[]> {
    let account = this.getCurrentAccount();
    params.account_id = account.id;
    return this._httpClient.get<{ activities: Activity[] }>(`activities`, { params: params }).pipe(
      map((response) => {
        return response.activities;
      }),
    );
  }

  deleteActivity(id: number): Observable<Activity> {
    return this._httpClient.delete<Activity>(`activities/${id}`).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getPipelineTemplates(): Observable<PipelineTemplate[]> {
    return this._httpClient.get<any>(`/pipeline_templates`).pipe(
      map((response) => {
        return response.templates;
      }),
    );
  }

  typeaheadSearch(query: string, searchIn: string = 'Market,Company'): Observable<any> {
    let account = this.getCurrentAccount();
    return this._httpClient.get<any>(`search/names?q=${query}&in=${searchIn}&account_id=${account.id}`).pipe(
      map((response) => {
        return response.result;
      }),
    );
  }

  getAccountIntegrations(): Observable<AccountIntegration[]> {
    let account = this.getCurrentAccount();
    return this._httpClient.get<any>(`integrations?account_id=${account.id}`).pipe(
      pluck('account_integrations'),
      catchError((err: HttpErrorResponse) => {
        this._accountIntegrationsError.next(err);
        return EMPTY;
      }),
    );
  }

  saveAccountIntegration(accountIntegration: AccountIntegration): Observable<AccountIntegration> {
    let account = this.getCurrentAccount();
    accountIntegration.extra = JSON.stringify(accountIntegration.jsonExtra);
    if (accountIntegration.id) {
      return this._httpClient
        .put<any>(`integrations/${accountIntegration.id}?account_id=${account.id}`, {
          account_integration: accountIntegration,
        })
        .pipe(
          map((response) => {
            return response.account_integration;
          }),
        );
    } else {
      return this._httpClient
        .post<any>(`integrations?account_id=${account.id}`, { account_integration: accountIntegration })
        .pipe(
          map((response) => {
            return response.account_integration;
          }),
        );
    }
  }

  getClikToken(): Observable<any> {
    let account = this.getCurrentAccount();
    return this._httpClient.get<any>(`clikai/setup?account_id=${account.id}`).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  @Cacheable({
    cacheBusterObserver: savedViewsCacheBuster$,
  })
  getSavedViews(): Observable<SavedView[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`saved_views?account_id=${account.id}`).pipe(
          map((response) => {
            this._savedViews.next(response.saved_views);
            return response.saved_views;
          }),
        ),
      ),
    );
  }

  getSavedViewById(id: string): Observable<SavedView> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`saved_views/${id}?account_id=${account.id}`).pipe(
          map((response) => {
            this._savedView.next(response.saved_view);
            return response.saved_view;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: savedViewsCacheBuster$,
  })
  createSavedView(savedView: SavedView): Observable<SavedView> {
    let account = this.getCurrentAccount();
    return this.savedViews$.pipe(
      take(1),
      switchMap((savedViews) =>
        this._httpClient.post<any>(`saved_views?account_id=${account.id}`, { saved_view: savedView }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._savedViews.next([reponse.saved_view, ...(savedViews || [])]);

            // Return the new contact
            return reponse.saved_view;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: savedViewsCacheBuster$,
  })
  updateSavedView(savedView: SavedView): Observable<SavedView> {
    let account = this.getCurrentAccount();
    return this.savedViews$.pipe(
      take(1),
      switchMap((savedViews) =>
        this._httpClient
          .put<any>(`saved_views/${savedView.id}?account_id=${account.id}`, { saved_view: savedView })
          .pipe(
            map((response) => {
              let updatedSavedView = response.saved_view;

              // Find the index of the updated contact
              const index = savedViews.findIndex((item) => item.id === updatedSavedView.id);

              // Update the contact
              savedViews[index] = updatedSavedView;

              // Update the contacts
              this._savedViews.next(savedViews);
              this._savedView.next(updatedSavedView);

              // Return the updated contact
              return updatedSavedView;
            }),
          ),
      ),
    );
  }

  bustSavedFiltersCacheBuster(): void {
    savedFiltersCacheBuster$.next();
  }

  bustInvitationsCacheBuster(): void {
    invitationsCacheBuster$.next();
  }

  fieldDefsCacheBuster(): void {
    fieldDefsCacheBuster$.next();
  }

  getUserPermissionByAccountId(accountId: number): Observable<Permission[]> {
    return this._httpClient
      .get<{ permissions: Permission[] }>(`user/permissions?account_id=${accountId}`)
      .pipe(map(({ permissions }) => permissions));
  }

  scheduleExport(objectType): Observable<any> {
    let account = this.getCurrentAccount();
    return this._httpClient.post<any>(`/accounts/${account.id}/schedule_export?object_type=${objectType}`, {}).pipe(
      tap((response) => {
        return response;
      }),
    );
  }
}
