import { FileFormat } from 'app/shared/enums';

export function generateDownloadableFile(hashData: string, fileFormat: FileFormat, fileName: string): void {
  const binaryString = atob(hashData);
  const len = binaryString.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);

  for (let i = 0; i < len; i++) {
    view[i] = binaryString.charCodeAt(i);
  }

  downloadBlobAs(fileName, fileFormat, [view]);
}

export function downloadBlobAs(fileName: string, fileFormat: FileFormat, blobParts: BlobPart[]): void {
  const contentType = getContentType(fileFormat);
  const blob = new Blob(blobParts, { type: `${contentType}` });
  const downloadURL = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadURL;
  link.download = `${fileName}.${fileFormat}`;
  link.click();
}

export function objectToCSV(value: any[], columns: string[]): string {
  const newLine = '\r\n';
  let result = '';
  let row = '';
  for (const column in columns) {
    row += columns[column] + ',';
  }
  row = row.slice(0, -1);
  result += row + newLine;

  for (const item of value) {
    let line = '';
    for (const column of columns) {
      line += (item[column] ?? `""`) + ',';
    }
    line = line.slice(0, -1);
    result += line + newLine;
  }

  return result;
}

function getContentType(fileFormat: FileFormat): string {
  switch (fileFormat) {
    case FileFormat.CSV:
      return `text/${fileFormat}`;
    case FileFormat.PDF:
      return `application/${fileFormat}`;
    case FileFormat.DOCX:
      return `application/vnd.openxmlformats-officedocument.wordprocessingml.document`;
    default:
      throw new Error('Unsupported format');
  }
}
