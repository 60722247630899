import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { TreoAutogrowModule } from '@treo/directives/autogrow';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocompleteModule } from '@angular/material/autocomplete';
import { Overlay } from '@angular/cdk/overlay';
import { MentionModule } from 'angular-mentions';
import { QuillModule } from 'ngx-quill';

import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskModule, IConfig } from 'ngx-mask';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

import { TaskStatusComponent } from './task-status/task-status.component';
import { CommentViewComponent } from './comment/comment-view.component';
import { ContactSharingComponent } from './contact-sharing/contact-sharing.component';
import { MailboxMailListComponent } from './mailbox-mail-list/mailbox-mail-list.component';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { AddContactComponent } from './add-contact/add-contact.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SelectFieldComponent } from './select-field/select-field.component';
import { ListFilterComponent } from './list-filter/list-filter.component';
import { SortableDropdownEditorComponent } from './sortable-dropdown-editor/sortable-dropdown-editor.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MailboxMailViewComponent } from './mailbox-mail-list/details/details.component';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { SharedActivityComponent } from './activity/activity.component';
import { ListFilterItemsComponent } from './list-filter/list-filter-items/list-filter-items.component';
import { FieldHistoryComponent } from './field-history/field-history.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    TaskStatusComponent,
    CommentViewComponent,
    ContactSharingComponent,
    MailboxMailListComponent,
    AddContactComponent,
    BreadcrumbComponent,
    SelectFieldComponent,
    ListFilterComponent,
    SortableDropdownEditorComponent,
    MailboxMailViewComponent,
    SharedActivityComponent,
    ListFilterItemsComponent,
    FieldHistoryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDividerModule,
    RouterModule,
    QuillModule.forRoot(),
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatMomentDateModule,
    MatProgressBarModule,
    MatRadioModule,
    MatRippleModule,
    MentionModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    TreoAutogrowModule,
    MatTooltipModule,
    MatToolbarModule,
    DragDropModule,
    MatSortModule,
    MatPaginatorModule,
    MatTableModule,
    PipesModule,
    CurrencyMaskModule,
    DigitOnlyModule,
    MatProgressSpinnerModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    TaskStatusComponent,
    CommentViewComponent,
    ContactSharingComponent,
    MailboxMailListComponent,
    AddContactComponent,
    BreadcrumbComponent,
    SelectFieldComponent,
    ListFilterComponent,
    SortableDropdownEditorComponent,
    MailboxMailViewComponent,
    SharedActivityComponent,
    ListFilterItemsComponent,
    FieldHistoryComponent,
  ],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => {
        return () => overlay.scrollStrategies.block();
      },
      deps: [Overlay],
    },
  ],
})
export class WidgetsModule {}
