import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormFieldComponent } from '../form-field.types';

@Component({
  templateUrl: './text-form-field.component.html',
  styleUrls: ['./text-form-field.component.scss'],
})
export class TextFormFieldComponent extends FormFieldComponent implements OnInit {
  @ViewChild('inputControl') inputControl: ElementRef;

  public editing: boolean = false;
  public value;

  private _originalValue;
  private _readonly;

  ngOnInit(): void {
    super.ngOnInit();

    this.value = this.model[this.fieldDef.name];

    // we want to track the original value, so that we don't hit save to many times
    this._originalValue = this.value;
    if (this.fieldDef.meta && this.fieldDef.meta.readonly) {
      this._readonly = this.fieldDef.meta.readonly;
    }
    if (this.readonly) {
      this._readonly = this.readonly;
    }
    this.readonly = this._readonly;
  }

  edit() {
    if (!this.editing && !this._readonly) {
      this.editing = true;
      setTimeout(() => {
        this.inputControl.nativeElement.focus();
      }, 300);
    }
  }

  onBlur($event: Event) {
    this.onSave();
    this.editing = false;
  }

  /**
   * When the model is updated after save, re-run our logic
   * @param model
   */
  updateChangedModel(model: any): void {
    this.model = model;
    //this.value = this.model[this.fieldDef.name] * 100;
    this.value = this.model[this.fieldDef.name];

    // we want to track the original value, so that we don't hit save to many times
    this._originalValue = this.value;
  }

  onSave() {
    if (this._originalValue != this.value) {
      this.updateModel();
      this.update.emit({ [this.fieldDef.name]: this.value });
    }
  }

  updateModel() {
    this.model[this.fieldDef.name] = this.value;
    this._originalValue = this.value;
  }
}
