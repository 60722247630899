import { Pipe, PipeTransform } from '@angular/core';

type CustomFnType = (value: any[], filter: any) => any[];

@Pipe({ name: 'filterBy' })
export class FilterByPipe implements PipeTransform {
  transform(value: any[], property = '', filter: any = '', customFn: CustomFnType = null): any[] {
    if (customFn) {
      return customFn(value, filter);
    }

    if (!property || !filter) return value;

    return value.filter((item) => item[property]?.toLowerCase().indexOf(filter.toLowerCase()) > -1);
  }
}
