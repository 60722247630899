<mat-form-field class="flex-auto gt-xs:pr-3" (click)="edit()" (focus)="edit()">
  <mat-label
    >{{ fieldDef.label }} <i class="fa fa-info-circle pointer-events-auto" [matTooltip]="showTip" *ngIf="showTip"> </i
  ></mat-label>
  <div class="w-full">
    <mat-select
      [placeholder]="fieldDef.label"
      (selectionChange)="onSelectionChange($event)"
      [(ngModel)]="'' + value"
      (blur)="onBlur($event)"
      [disabled]="isDisabled"
    >
      <mat-option *ngFor="let option of options" [value]="option.id">{{ option.value }}</mat-option>
    </mat-select>
  </div>
</mat-form-field>
