import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { AccountService } from 'app/core';
import { Activity, FieldDef } from 'app/core/models';
import { Project, Property } from 'app/modules/projects/projects.types';
import { RelatedToType } from 'app/shared/enums';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-field-history',
  templateUrl: './field-history.component.html',
  styleUrls: ['./field-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldHistoryComponent implements OnInit {
  @Input() model: Project | Property;
  @Input() fieldDef: FieldDef;
  @Input() data: any;

  get options(): any {
    return this.data?.options;
  }

  private get relatedToType(): RelatedToType {
    return this.fieldDef.related_to_type;
  }

  activities$: Observable<Activity[]>;

  constructor(private readonly accountService: AccountService) {}

  ngOnInit(): void {
    if (![RelatedToType.Project, RelatedToType.Property].includes(this.relatedToType)) {
      return;
    }

    const propName = `${this.relatedToType.toLowerCase()}_id`;

    this.activities$ = this.accountService.getActivities({ [propName]: this.model.id, field: this.fieldDef.name });
  }
}
