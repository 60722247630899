import { Component, ElementRef, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Account } from 'app/core/models/account.types';
import { AccountService } from 'app/core';
import { FormFieldComponent } from '../form-field.types';

@Component({
  templateUrl: './typeahead-form-field.component.html',
  styleUrls: ['./typeahead-form-field.component.scss'],
})
export class TypeaheadFormFieldComponent extends FormFieldComponent implements OnInit {
  @ViewChild('inputControl') inputControl: ElementRef;

  public formCtrl: UntypedFormControl;
  public searchResults: any[] | null;
  private _unsubscribeAll: Subject<void>;
  public editing: boolean = false;
  public value;
  private _originalValue;
  private _readonly;
  public account: Account;

  constructor(private _accountService: AccountService, private _changeDetectorRef: ChangeDetectorRef) {
    super();

    this._unsubscribeAll = new Subject();

    this._accountService.currentAccount.subscribe((accountData) => {
      this.account = accountData;
    });
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.formCtrl = new UntypedFormControl();

    this.value = this.model[this.fieldDef.name];

    // we want to track the original value, so that we don't hit save to many times
    this._originalValue = this.value;
    if (this.fieldDef.meta && this.fieldDef.meta.readonly) {
      this._readonly = this.fieldDef.meta.readonly;
    }
    if (this.readonly) {
      this._readonly = this.readonly;
    }
    this.readonly = this._readonly;

    this.formCtrl.disable();

    this.formCtrl.valueChanges
      .pipe(takeUntil(this._unsubscribeAll), debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        this._accountService.typeaheadSearch(value, this.fieldDef.name).subscribe((response) => {
          this.searchResults = response;
          this._changeDetectorRef.markForCheck();
        });
      });
  }

  edit() {
    if (!this.editing && !this._readonly) {
      this.editing = true;
      this.formCtrl.enable();
      setTimeout(() => {
        this.inputControl.nativeElement.focus();
      }, 300);
    }
  }

  onBlur($event: Event) {
    //this.onSave();
    this.editing = false;
    this.formCtrl.disable();
  }

  autoCompleteSelected($event): void {
    //this.contactForm.patchValue($event.option.value, { emitEvent: false });
  }

  autoCompleteClosed(): void {
    this.onSave();
    this.editing = false;
    this.formCtrl.disable();
  }

  /**
   * When the model is updated after save, re-run our logic
   * @param model
   */
  updateChangedModel(model: any): void {
    this.model = model;
    //this.value = this.model[this.fieldDef.name] * 100;
    this.value = this.model[this.fieldDef.name];

    // we want to track the original value, so that we don't hit save to many times
    this._originalValue = this.value;
  }

  onSave() {
    if (this._originalValue != this.value) {
      this.updateModel();
      this.update.emit({ [this.fieldDef.name]: this.value });
    }
  }

  updateModel() {
    this.model[this.fieldDef.name] = this.value;
    this._originalValue = this.value;
  }
}
