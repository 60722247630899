import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { TreoModule } from '@treo';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TreoConfigModule } from '@treo/services/config';
import { TreoMockApiModule } from '@treo/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { mockDataServices } from 'app/data/mock';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { RollbarService, rollbarFactory, RollbarErrorHandler } from 'app/core/services/rollbar.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PlatformModule } from '@angular/cdk/platform';
import { environment } from '../environments/environment';
import { NgxsModule } from '@ngxs/store';
import { ReportingState } from './modules/reporting/state/state';
import { AccountState } from './state/account/state';
import { ProjectState } from './modules/projects/state/state';

declare global {
  interface Window {
    analytics: any;
    rnw: any;
  }
}

const routerConfig: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  preloadingStrategy: PreloadAllModules,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Treo & Treo Mock API
    TreoModule,
    TreoConfigModule.forRoot({ theme: 'light', layout: 'modern' }),
    TreoMockApiModule.forRoot(mockDataServices),
    PlatformModule,
    MatSnackBarModule,

    // Core
    CoreModule,

    // Layout
    LayoutModule,

    // 3rd party modules
    MarkdownModule.forRoot({}),

    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

    NgxsModule.forRoot([AccountState, ProjectState, ReportingState]),
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
  ],
})
export class AppModule {}
