export default class UrlUtils {
  static saveToLocalStorage(key: string, data: any, isObject?: boolean): void {
    localStorage.setItem(key, isObject ? JSON.stringify(data) : data);
  }

  static getFromLocalStorage(key: string, isObject?: boolean): any {
    if (!isObject) {
      return localStorage.getItem(key) || '';
    }

    const filters = localStorage.getItem(key);
    if (filters) {
      try {
        return JSON.parse(filters);
      } catch (e) {
        return {};
      }
    }
    return {};
  }

  /**
   * Determine if a value is a formula that beings with =
   * @param value
   * @returns value or eval'd value
   */
  static checkAndEvalFormula(value) {
    if (value && typeof value === 'string' && value.startsWith('=')) {
      let startsWithEquals = value.startsWith('=');
      if (startsWithEquals) {
        return eval(value.substring(1));
      }
    }
    return value;
  }
}
