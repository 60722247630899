import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { Router } from '@angular/router';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'notifications',
})
export class NotificationsComponent implements OnInit, OnDestroy {
  unreadCount: number;

  // Private
  private _notifications: Notification[];
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<void>;

  @ViewChild('notificationsOrigin')
  private _notificationsOrigin: MatButton;

  @ViewChild('notificationsPanel')
  private _notificationsPanel: TemplateRef<any>;

  /**
   * Constructor
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {NotificationsService} _notificationsService
   * @param {Overlay} _overlay
   * @param {ViewContainerRef} _viewContainerRef
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _notificationsService: NotificationsService,
    private _overlay: Overlay,
    private router: Router,
    private _viewContainerRef: ViewContainerRef,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Set the defaults
    this.unreadCount = 0;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for data
   */
  @Input()
  set notifications(value: Notification[]) {
    // Store the data
    this._notificationsService.store(value);
  }

  get notifications(): Notification[] {
    return this._notifications;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to notification changes
    this._notificationsService.notifications$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((notifications: Notification[]) => {
        // Load the notifications
        this._notifications = notifications;

        // Calculate the unread count
        this._calculateUnreadCount();

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    // Dispose the overlay if it's still on the DOM
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Calculate the unread count
   *
   * @private
   */
  private _calculateUnreadCount(): void {
    let count = 0;

    if (this.notifications && this.notifications.length) {
      count = this.notifications.filter((notification) => notification.is_read === false).length;
    }

    this.unreadCount = count;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create route from given link
   */
  createRouteFromLink(link): string[] {
    // Split the link and add a leading slash
    const route = link.split('/');
    route.unshift('/');

    // Return the route
    return route;
  }

  goToFolders(): void {
    this.router.navigate(['/folders']);
  }

  goToCalendar(): void {
    this.router.navigate(['/calendar']);
  }

  /**
   * Open the notifications panel
   */
  openPanel(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: '',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
        .withFlexibleDimensions()
        .withViewportMargin(16)
        .withLockedPosition()
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    // Create a portal from the template
    const templatePortal = new TemplatePortal(this._notificationsPanel, this._viewContainerRef);

    // Attach the portal to the overlay
    this._overlayRef.attach(templatePortal);

    // Subscribe to the backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      // If overlay exists and attached...
      if (this._overlayRef && this._overlayRef.hasAttached()) {
        // Detach it
        this._overlayRef.detach();
      }

      // If template portal exists and attached...
      if (templatePortal && templatePortal.isAttached) {
        // Detach it
        templatePortal.detach();
      }
    });
  }

  /**
   * Mark all notifications as read
   */
  markAllAsRead(): void {
    // Mark all as read
    this._notificationsService.markAllAsRead().subscribe();
  }

  /**
   * Toggle read status of the given notification
   */
  toggleRead(notification): void {
    // Toggle the read status
    notification.is_read = !notification.is_read;

    // Update the notification
    this._notificationsService.update(notification.id, notification).subscribe();
  }

  /**
   * Delete the given notification
   */
  delete(notification): void {
    // Delete the notification
    //this._notificationsService.delete(notification.id).subscribe();
  }
}
