<p class="text-md font-medium mt-3 pr-3">Tasks</p>
<mat-divider
  role="separator"
  class="mat-divider mt-1 mb-1 mat-divider-horizontal"
  aria-orientation="horizontal"
></mat-divider>

<div class="flex flex-col overflow-auto mb-3">
  <div class="tasks-table">
    <div class="body">
      <div class="row" *ngFor="let task of tasks; let i = index">
        <ng-container *ngIf="!task.editing && task.task_type === 'task'">
          <div class="col">
            <task-status [task]="task" [taskStatuses]="workflowStates" condensedView="true"></task-status>
          </div>
          <div class="col">{{ task.title }}</div>
          <div class="col">
            <project-dialog-date-dropdown
              [(ngModel)]="task.due_date"
              (onUpdate)="saveTask(task)"
            ></project-dialog-date-dropdown>
          </div>
          <div class="col due-time">
            <input
              [ngxTimepicker]="toggleTimepicker"
              [ngClass]="{ 'due-set': task.due_date }"
              placeholder="Not set"
              aria-label="Not set"
              [value]="task.due_date | date: 'shortTime'"
              [disableClick]="true"
              readonly
              class="tm-input"
            />
            <ngx-material-timepicker-toggle [for]="toggleTimepicker" class="tm-picker"></ngx-material-timepicker-toggle>
            <ngx-material-timepicker
              #toggleTimepicker
              [enableKeyboardInput]="true"
              [minutesGap]="5"
              preventOverlayClick="true"
              [theme]="darkTheme"
              (timeSet)="taskTimeChanged($event, task)"
              timepickerClass="cp-timer-container1"
            ></ngx-material-timepicker>
          </div>
          <div class="col">
            <project-dialog-user-dropdown
              [(ngModel)]="task.assigned_user_id"
              (onUpdate)="saveTask(task)"
              [users]="options.options.users"
            ></project-dialog-user-dropdown>
          </div>
          <div class="col">
            <div class="flex">
              <button mat-icon-button class="delete-task" (click)="editTask(task)">
                <mat-icon [svgIcon]="'create'"></mat-icon>
              </button>
              <button mat-icon-button class="delete-task" (click)="deleteTask(task)">
                <mat-icon [svgIcon]="'delete'"></mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="task.editing">
          <div class="col editing">
            <mat-form-field class="treo-mat-no-subscript">
              <mat-label></mat-label>
              <input matInput [(ngModel)]="task.title" />
              <button mat-icon-button matSuffix (click)="cancelTaskEditing(task)">
                <mat-icon [svgIcon]="'cancel'"></mat-icon>
              </button>
              <button mat-icon-button matSuffix (click)="saveTask(task)">
                <mat-icon [svgIcon]="'save'"></mat-icon>
              </button>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div>
          <button class="ml-2 xs:hidden add-task" mat-flat-button (click)="createTask()">
            <mat-icon class="icon-size-20" [svgIcon]="'add'"></mat-icon>
            Add Task
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
