export const environment = {
  production: true,
  baseUrl: 'https://stage.termsheet.com',
  apiVersion: 'api/v2',
  versionCheckURL: 'https://dashboard.termsheetuat.com/version.json',
  cookieDomain: 'termsheetuat.com',
  appDomain: 'termsheetuat.com',
  mapbox: {
    accessToken: 'pk.eyJ1Ijoicm9nZXNtaXRoIiwiYSI6ImNqdHB2OHg5azAwbzA0M3BlZ3dxanZ2NXcifQ.PBWyfzuqPZMB6EaL6mQdvQ',
  },
  env: 'stagingUI',
  dataDog: {
    env: 'staging',
    applicationId: '',
    clientToken: '',
    site: '',
    service: '',
  },
  pusher: {
    instanceLocator: 'v1:us1:293dfee0-776e-4024-accd-63f80b5d820f',
    instanceId: 'd5149367-2d46-462f-82c3-8e838a3b754f',
    channelApp: {
      key: '92535bc5500134da1eaa',
      cluster: 'us2',
    },
  },
  loveland: {
    apiToken: 'yvByetT_qAZ2UGHgX7LNBvxtZoFZhpU3-PTs-kwbfHQkxJR3aLFmUwi8DZmzKvBJ',
    vectorTileUrl: 'https://tiles.makeloveland.com/api/v1/parcels?format=mvt&token=',
  },
  rollbar: {
    accessToken: '1b1a90d4408b492f88af7ce6b2f9802a',
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: 'stagingUI',
    code_version: '2a35f874c',
    source_map_enabled: true,
    guess_uncaught_frames: true,
  },
};
