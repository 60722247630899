import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditableComponent } from './editable.component';
import { EditableDirective } from './editable.directive';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TreoFindByKeyPipeModule } from '@treo/pipes/find-by-key';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocompleteModule } from '@angular/material/autocomplete';
import { Overlay } from '@angular/cdk/overlay';

import { NgxMaskModule, IConfig } from 'ngx-mask';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

import { TextEditableComponent } from './text-editable/text-editable.component';
import { DateTimeEditableComponent } from './datetime-editable/datetime-editable.component';
import { DecimalEditableComponent } from './decimal-editable/decimal-editable.component';
import { NumberEditableComponent } from './number-editable/number-editable.component';
import { MoneyEditableComponent } from './money-editable/money-editable.component';
import { ImagesEditableComponent } from './images-editable/images-editable.component';
import { PercentEditableComponent } from './percent-editable/percent-editable.component';
import { TagsEditableComponent } from './tags-editable/tags-editable.component';
import { TextAreaEditableComponent } from './textarea-editable/textarea-editable.component';
import { TypeaheadEditableComponent } from './typeahead-editable/typeahead-editable.component';
import { SelectEditableComponent } from './select-editable/select-editable.component';
import { YearEditableComponent } from './year-editable/year-editable.component';
import { CheckboxEditableComponent } from './checkbox-editable/checkbox-editable.component';
import { UrlEditableComponent } from './url-editable/url-editable.component';
import { RecordEditableComponent } from './record-editable/record-editable.component';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { ModalsModule } from 'app/shared/modals/modals.module';
import { WidgetsModule } from 'app/shared/components/widgets/widgets.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
    declarations: [
        EditableComponent,
        TextEditableComponent,
        DateTimeEditableComponent,
        DecimalEditableComponent,
        NumberEditableComponent,
        MoneyEditableComponent,
        ImagesEditableComponent,
        PercentEditableComponent,
        TagsEditableComponent,
        TextAreaEditableComponent,
        TypeaheadEditableComponent,
        SelectEditableComponent,
        YearEditableComponent,
        CheckboxEditableComponent,
        UrlEditableComponent,
        RecordEditableComponent,
        EditableDirective,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TreoFindByKeyPipeModule,
        MatFormFieldModule,
        MatSelectModule,
        MatMomentDateModule,
        MatDatepickerModule,
        MatIconModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        CurrencyMaskModule,
        PipesModule,
        ModalsModule,
        WidgetsModule,
        MatTooltipModule,
        MatProgressBarModule,
        NgxMaskModule.forRoot(),
    ],
    exports: [EditableComponent],
    providers: [
        {
            provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => {
                return () => overlay.scrollStrategies.block();
            },
            deps: [Overlay],
        },
    ]
})
export class EditableModule {}
