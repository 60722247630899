/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, Input, OnChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProjectsService } from 'app/modules/projects/projects.service';
import { Comment } from 'app/core/models/comment.types';
import { User, UserAssociation } from 'app/core/models/user.types';
import { UserService } from 'app/layout/common/user/user.service';
import { CommentsService } from 'app/core/services/comments.service';
import { AccountService } from 'app/core';
import { startsWith, trim } from 'lodash-es';
import { Project } from 'app/modules/projects/projects.types';
import { Team } from 'app/core/models/account.types';

@Component({
  selector: 'comment-view',
  templateUrl: './comment-view.component.html',
  styleUrls: ['./comment-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CommentViewComponent implements OnInit, OnChanges {
  @Input() account: any;
  @Input() relatedToId: number;
  @Input() relatedToType: string;
  @Input() readonly: boolean = false;
  private _unsubscribeAll: Subject<void>;
  public comments: Comment[];
  public commentForm: UntypedFormGroup;
  public replyCommentForm: UntypedFormGroup;
  public editCommentForm: UntypedFormGroup;
  public user: User;
  public teams: Team[];
  quillModules: any;
  mentionConfig = {
    mentions: [
      {
        items: [],
        triggerChar: '@',
        labelKey: 'full_name',
        allowSpace: true,
        maxItems: 4,
        mentionSelect: this.userMentioned.bind(this),
      },
      {
        items: [],
        triggerChar: '#',
        labelKey: 'full_name',
        allowSpace: true,
        maxItems: 4,
        mentionSelect: this.userMentioned.bind(this),
      },
    ],
  };

  /**
   * Constructor
   */
  constructor(
    private _commentsService: CommentsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder,
    private _userService: UserService,
    private _accountService: AccountService,
    private _projectsService: ProjectsService,
  ) {
    this._unsubscribeAll = new Subject();

    this.quillModules = {
      toolbar: [
        ['bold', 'italic'],
        ['link', 'blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image'],
      ],
    };

    this.commentForm = this._formBuilder.group({
      body: ['', [Validators.required]],
    });

    this.editCommentForm = this._formBuilder.group({
      id: [],
      body: ['', [Validators.required]],
    });

    this.replyCommentForm = this._formBuilder.group({
      id: [],
      parent_id: [],
      body: ['', [Validators.required]],
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._commentsService.accountId = this.account.id;

    /*this._commentsService.comments$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((comments: Comment[]) => {

        if (comments) {
          this.comments = comments;
        }


        // Mark for check
        this._changeDetectorRef.markForCheck();
      });*/

    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
      this.user = user;
      this._changeDetectorRef.markForCheck();
    });

    this._accountService.userAssociations$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((invitations: UserAssociation[]) => {
        this.mentionConfig.mentions[0].items = invitations.filter((i) => i.user?.full_name != null).map((i) => i.user);
        //[ch4229] - allow user to enter @everyone
        this.mentionConfig.mentions[0].items.push({
          full_name: 'everyone',
          isContact: false,
        });
      });

    this._accountService.teams$.pipe(takeUntil(this._unsubscribeAll)).subscribe((teams: Team[]) => {
      this.teams = teams;
      this.teams?.forEach((team) => {
        this.mentionConfig.mentions[0].items.push({
          full_name: team.mention_name,
          isContact: false,
        });
      });
    });

    this._projectsService.project$.pipe(takeUntil(this._unsubscribeAll)).subscribe((project: Project) => {
      if (project) {
        this.mentionConfig.mentions[1].items = project?.contact_associations?.map((user) => {
          if (user?.contact) {
            const { first_name, last_name } = user.contact;
            user.contact.full_name = trim(`${first_name ? first_name : ''} ${last_name ? last_name : ''}`) || 'unknown';
            user.contact.isContact = true;
            return user?.contact;
          }
          return { first_name: '', last_name: '' };
        });
        // Mark for check
        this._changeDetectorRef.markForCheck();
      }
    });

    this.loadComments();
  }

  loadComments(): void {
    this._commentsService.getComments(this.relatedToId, this.relatedToType).subscribe((comments) => {
      this.comments = comments;
      this._changeDetectorRef.markForCheck();
    });
  }

  ngOnChanges() {
    if (this._commentsService.accountId) {
      //this._commentsService.getComments(this.relatedToId, this.relatedToType).subscribe();
      this.loadComments();
    }
  }

  saveComment(formDirective: FormGroupDirective): void {
    // Get the contact object
    const comment = this.commentForm.value;

    comment.commentable_type = this.relatedToType;
    comment.commentable_id = this.relatedToId;

    this._commentsService.createComment(comment).subscribe((comment) => {
      formDirective.resetForm();
      this.commentForm.reset();
      this.loadComments();
    });
  }

  /**
   * Display edit comment view
   * @param comment
   */
  editComment(comment: Comment): void {
    this.editCommentForm.patchValue(comment);
    comment.editing = true;
  }

  replyToComment(comment: Comment): void {
    comment.replying = true;
    this.replyCommentForm.get('parent_id').setValue(comment.id);
  }

  cancelCommentReply(comment: Comment): void {
    comment.replying = false;
    this.replyCommentForm.get('parent_id').setValue(null);
  }

  saveCommentReply(): void {
    const comment = this.replyCommentForm.value;

    comment.commentable_type = this.relatedToType;
    comment.commentable_id = this.relatedToId;

    this._commentsService.createComment(comment).subscribe((comment) => {
      this.replyCommentForm.reset();
      this.loadComments();
    });
  }

  /**
   * Delete comment
   * @param comment
   */
  deleteComment(comment: Comment): void {
    if (confirm('Are you sure?')) {
      this._commentsService.deleteComment(comment.id).subscribe((resp) => {
        this.loadComments();
      });
    }
  }

  /**
   * Hide edit comment view
   * @param comment
   */
  cancelEditComment(comment: Comment): void {
    comment.editing = false;
  }

  updateComment(formDirective: FormGroupDirective): void {
    // Get the contact object
    const comment = this.editCommentForm.value;

    this._commentsService.updateComment(comment).subscribe((comment) => {
      formDirective.resetForm();
      this.editCommentForm.reset();
      comment.editing = false;
      this.loadComments();
    });
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {}

  userMentioned(value: User): any {
    if (
      value.full_name == 'everyone' ||
      this.teams.map((tm) => tm.mention_name.toLowerCase()).includes(value.full_name?.toLowerCase())
    ) {
      return `@${value.full_name?.replace(/\s/g, '')} `;
    } else {
      return `${value.isContact ? '#' : '@'}${value.full_name?.replace(/\s/g, '')}_${value.id} `;
    }
  }

  formatMessage(body: string): string {
    return body
      ?.split(' ')
      ?.map((item) => {
        const pos = item?.lastIndexOf('_');
        const startPos = item?.indexOf('@');
        const startPost2 = item.indexOf('#');
        return item.includes('@')
          ? `<mark class="mentioned-contact">${
              pos > -1 ? item.substring(startPos, pos) : item.substring(startPos)
            }</mark>`
          : item.includes('#')
          ? `<a href="/contacts/${item.split('_')?.pop()}" class="link" target="_blank">${
              pos ? item.substring(startPost2, pos) : item.substring(startPost2)
            }</a>`
          : item;
      })
      ?.join(' ');
  }
}
