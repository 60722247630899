import { Component, Input, ElementRef, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { FormFieldComponent, FormFieldInterfaceComponent, PartialFormField } from '../form-field.types';
import { FormatNumberPipe } from 'app/shared/pipes/format-number-pipe';

@Component({
  templateUrl: './checkbox-form-field.component.html',
  styleUrls: ['./checkbox-form-field.component.scss'],
})
export class CheckboxFormFieldComponent extends FormFieldComponent implements OnInit {
  @ViewChild('inputControl') inputControl: ElementRef;

  public editing: boolean = false;
  public value;
  public options: any[] = [
    { id: 'true', value: 'Yes' },
    { id: 'false', value: 'No' },
    { id: 'notset', value: 'Not Set' },
  ];
  private _originalValue;
  private _readonly;

  ngOnInit(): void {
    super.ngOnInit();

    this.value = this.model[this.fieldDef.name];

    if (this.value === null || this.value === undefined) this.value = 'notset';
    else {
      let booleanValue = new Boolean(this.value);

      if (booleanValue === true || booleanValue === false) {
        this.value = JSON.parse(this.value.toString());
        this._originalValue = this.value;
      }
    }

    this._originalValue = this.value;

    if (this.fieldDef.meta && this.fieldDef.meta.readonly) {
      this._readonly = this.fieldDef.meta.readonly;
    }
    if (this.readonly) {
      this._readonly = this.readonly;
    }
    this.readonly = this._readonly;
  }

  edit() {
    if (!this.editing && !this._readonly) {
      this.editing = true;
    }
  }
  onSelectionChange($event: Event) {
    this.onSave();
    this.editing = false;
  }

  onBlur($event: Event) {
    this.onSave();
    this.editing = false;
  }

  onSave() {
    if (this.value !== 'notset') {
      if (this._originalValue != this.value) {
        this.updateModel();
        this.update.emit({ [this.fieldDef.name]: this.value });
      }
    }
  }

  updateModel() {
    this.model[this.fieldDef.name] = this.value;
    this._originalValue = this.value;
  }

  updateChangedModel?(model: any) {
    /* noop */
  }
}
