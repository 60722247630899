import { Injectable } from '@angular/core';
import { Store, Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ReportLayout } from '../../reports/reports.types';
import { BuildChartData, ClearSelectedReport, CreateReport, GetReportById, GetReports, UpdateReport } from './actions';
import { ReportingService } from '../reporting.service';
import { CreateReportModel, GetReportsFilter, ReportLayoutChart, ReportLayoutDefinition } from '../reporting.types';
import { AccountState } from 'app/state/account/state';

export interface ReportingStateModel {
  layouts: ReportLayout<ReportLayoutDefinition>[];
  selectedLayout: ReportLayout<ReportLayoutDefinition>;
  chartData: ReportLayoutChart[];
}

@State<ReportingStateModel>({
  name: new StateToken<ReportingStateModel>('reportingState'),
  defaults: { layouts: null, selectedLayout: null, chartData: null },
})
@Injectable()
export class ReportingState {
  constructor(private readonly store: Store, private readonly reportingService: ReportingService) {}

  @Selector()
  static getLayouts(state: ReportingStateModel): ReportLayout<ReportLayoutDefinition>[] {
    return state.layouts;
  }

  @Selector()
  static getSelectedLayout(state: ReportingStateModel): ReportLayout<ReportLayoutDefinition> {
    return state.selectedLayout;
  }

  @Selector()
  static getChartData(state: ReportingStateModel): any {
    return state.chartData;
  }

  @Action(GetReports)
  getReports(context: StateContext<ReportingStateModel>, { filter }: { filter: GetReportsFilter }) {
    const state = context.getState();
    const account = this.store.selectSnapshot(AccountState.getAccount);

    return this.reportingService
      .getReports<any>({ ...filter, accountId: Number(account.id) })
      .pipe(
        tap((layouts) =>
          context.setState({ ...state, layouts: layouts.filter(({ report_type }) => report_type === filter.type) }),
        ),
      );
  }

  @Action(GetReportById)
  getReportById(context: StateContext<ReportingStateModel>, { id }: { id: number }) {
    const state = context.getState();
    const account = this.store.selectSnapshot(AccountState.getAccount);

    return this.reportingService.getReportById<any>(id, Number(account.id)).pipe(
      tap((reportLayout) => {
        const newState = {
          ...state,
          selectedLayout: {
            ...reportLayout,
            definition: JSON.parse(reportLayout.definition),
            filters: JSON.parse(reportLayout.filters),
          },
        };

        context.setState(newState);

        this.store.dispatch(
          new BuildChartData.Action({
            reportLayoutId: reportLayout.id,
            charts: newState.selectedLayout.definition.visualizations,
          }),
        );
      }),
    );
  }

  @Action(CreateReport)
  createReport(context: StateContext<ReportingStateModel>, { model }: { model: CreateReportModel }) {
    const state = context.getState();
    const account = this.store.selectSnapshot(AccountState.getAccount);

    return this.reportingService
      .create<any>({
        name: model.name,
        account_id: Number(account.id),
        report_type: model.reportType,
        definition: JSON.stringify({ related_to_type: model.relatedToType, visualizations: [], columns: [] }),
      })
      .pipe(
        tap((reportLayout) =>
          context.setState({
            ...state,
            selectedLayout: { ...reportLayout, definition: JSON.parse(reportLayout.definition) },
          }),
        ),
      );
  }

  @Action(UpdateReport)
  updateReport(context: StateContext<ReportingStateModel>, { model }: { model: ReportLayout<any> }) {
    const state = context.getState();
    const account = this.store.selectSnapshot(AccountState.getAccount);

    return this.reportingService
      .update<any>({
        ...model,
        account_id: Number(account.id),
        definition: JSON.stringify(model.definition),
        filters: model.filters,
      })
      .pipe(
        tap((reportLayout) => {
          const newState = {
            ...state,
            selectedLayout: {
              ...reportLayout,
              definition: JSON.parse(reportLayout.definition),
              filters: JSON.parse(reportLayout.filters),
            },
          };

          context.setState(newState);

          this.store.dispatch(
            new BuildChartData.Action({
              reportLayoutId: reportLayout.id,
              charts: newState.selectedLayout.definition.visualizations,
            }),
          );
        }),
      );
  }

  @Action(ClearSelectedReport)
  clearSelectedReport(context: StateContext<ReportingStateModel>) {
    context.setState({ ...context.getState(), selectedLayout: null, chartData: null });
  }

  @Action(BuildChartData.Action)
  buildChartData(context: StateContext<ReportingStateModel>, payload: { buildChartDataParams: BuildChartData.Params }) {
    const state = context.getState();
    const {
      buildChartDataParams: { reportLayoutId, charts },
    } = payload;

    return this.reportingService.buildChartData(reportLayoutId, charts).pipe(
      tap((chartData) =>
        context.setState({
          ...state,
          chartData: chartData.reduce((p, c) => {
            return [...p, ...c];
          }, []),
        }),
      ),
    );
  }
}
