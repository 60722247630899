import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { TaskList, Task, Tag, TaskListTemplate, TaskStatus } from './tasks.types';

@Injectable({
  providedIn: 'root',
})
export class TasksTabService {
  private _taskList: BehaviorSubject<TaskList | null>;
  private _taskLists: BehaviorSubject<TaskList[] | null>;
  private _taskListTemplates: BehaviorSubject<TaskListTemplate[] | null>;
  private _taskListTemplate: BehaviorSubject<TaskListTemplate | null>;
  private _tasks: BehaviorSubject<Task[] | null>;
  private _task: BehaviorSubject<Task | null>;
  private _tags: BehaviorSubject<Tag[] | null>;
  private _moduleId: string;
  private _moduleBaseUrl: string;
  private _isGlobalTasks: boolean;
  private _accountId: string;

  workflowStatuses: TaskStatus[] = [
    {
      key: 'n_a',
      label: 'N/A',
      color: 'gray',
      icon: 'more_horiz',
    },
    {
      key: 'not_started',
      label: 'Not Started',
      color: 'gray',
      icon: 'more_horiz',
    },
    {
      key: 'needs_review',
      label: 'Needs Review',
      color: 'red',
      icon: 'error_outline',
    },
    {
      key: 'in_progress',
      label: 'In Progress',
      color: 'blue',
      icon: 'more_horiz',
    },
    {
      key: 'complete',
      label: 'Complete',
      color: 'green',
      icon: 'done',
    },
  ];

  constructor(private _httpClient: HttpClient) {
    // Set the private defaults
    this._taskList = new BehaviorSubject(null);
    this._taskLists = new BehaviorSubject(null);
    this._taskListTemplates = new BehaviorSubject(null);
    this._taskListTemplate = new BehaviorSubject(null);
    this._tasks = new BehaviorSubject(null);
    this._task = new BehaviorSubject(null);
    this._tags = new BehaviorSubject(null);
    this._moduleId = '';
    this._moduleBaseUrl = '';
    this._isGlobalTasks = false;
    this._accountId = '';
  }

  get tags$(): Observable<Tag[]> {
    return this._tags.asObservable();
  }

  get taskList$(): Observable<TaskList> {
    return this._taskList.asObservable();
  }

  get taskLists$(): Observable<TaskList[]> {
    return this._taskLists.asObservable();
  }

  get tasks$(): Observable<Task[]> {
    return this._tasks.asObservable();
  }

  get task$(): Observable<Task> {
    return this._task.asObservable();
  }

  get taskListTemplates$(): Observable<TaskListTemplate[]> {
    return this._taskListTemplates.asObservable();
  }

  get taskListTemplate$(): Observable<TaskListTemplate> {
    return this._taskListTemplate.asObservable();
  }

  set accountId(val: string) {
    this._accountId = val;
  }

  set moduleId(val: string) {
    this._moduleId = val;
    this._isGlobalTasks = false;
  }

  get moduleId() {
    return this._moduleId;
  }

  set moduleBaseUrl(val: string) {
    this._moduleBaseUrl = val;
    this._isGlobalTasks = false;
  }

  get moduleBaseUrl() {
    return this._moduleBaseUrl;
  }

  set isGlobalTasks(val: boolean) {
    this._isGlobalTasks = val;
  }

  get isGlobalTasks() {
    return this._isGlobalTasks;
  }

  getTaskLists(): Observable<TaskList[]> {
    const url = this.isGlobalTasks
      ? `accounts/${this._accountId}/task_lists`
      : `${this.moduleBaseUrl}/${this.moduleId}/task_lists?account_id=${this._accountId}`;

    return this._httpClient.get<any>(url).pipe(
      tap((response: any) => {
        this._taskLists.next(response.task_lists);
      }),
    );
  }

  getTaskListById(id: string): Observable<TaskList> {
    const url = this.isGlobalTasks
      ? `accounts/${this._accountId}/task_lists/${id}`
      : `${this.moduleBaseUrl}/${this.moduleId}/task_lists/${id}?account_id=${this._accountId}`;

    return this._httpClient.get<any>(url).pipe(
      tap((response) => {
        this._taskList.next(response.task_list);
        return response.task_list;
      }),
    );
  }

  getTaskById(id: string): Observable<Task> {
    const url = (taskList: TaskList) => {
      if (this.isGlobalTasks) {
        return `accounts/${this._accountId}/task_lists/${taskList.id}/tasks/${id}`;
      } else {
        return `${this.moduleBaseUrl}/${this.moduleId}/task_lists/${taskList.id}/tasks/${id}?account_id=${this._accountId}`;
      }
    };

    return this.taskList$.pipe(
      take(1),
      switchMap((taskList) =>
        this._httpClient.get<any>(url(taskList)).pipe(
          tap((response) => {
            this._task.next(response.task);
            return response.task;
          }),
        ),
      ),
    );
  }

  getTasks(taskListId: number, filters: any = {}, format: string = ''): Observable<Task[]> {
    const url = (taskListId: number) => {
      if (this.isGlobalTasks) {
        return `accounts/${this._accountId}/task_lists/${taskListId}/tasks`;
      } else {
        return `${this.moduleBaseUrl}/${this.moduleId}/task_lists/${taskListId}/tasks`;
      }
    };

    const {
      workflowStateIn = [],
      assignedUserIdIn = [],
      dueDateGteq = '',
      dueDateLteq = '',
      taskTypeIn = [],
    } = filters;

    if (taskTypeIn.length > 0) {
      taskTypeIn.push('section');
    }

    return this._httpClient
      .get<any>(url(taskListId), {
        params: {
          workflow_state_in: workflowStateIn.join(','),
          assigned_user_id_in: assignedUserIdIn.join(','),
          due_date_gteq: dueDateGteq,
          due_date_lteq: dueDateLteq,
          task_type_in: taskTypeIn.join(','),
          format: format,
        },
      })
      .pipe(
        map((response) => {
          if (format && response && format == 'csv') {
            return response.data;
          }
          let tasks = response.tasks;
          this._tasks.next(tasks);

          return tasks;
        }),
      );
  }

  createTaskList(taskList: TaskList): Observable<TaskList> {
    const url = this.isGlobalTasks
      ? `accounts/${this._accountId}/task_lists`
      : `${this.moduleBaseUrl}/${this.moduleId}/task_lists?account_id=${this._accountId}`;

    return this.taskLists$.pipe(
      take(1),
      switchMap((taskLists) =>
        this._httpClient
          .post<any>(url, {
            task_list: taskList,
          })
          .pipe(
            map((reponse) => {
              // Update the contacts with the new contact
              this._taskLists.next([reponse.task_list, ...taskLists]);

              // Return the new contact
              return reponse.task_list;
            }),
          ),
      ),
    );
  }

  updateTaskList(taskList: TaskList): Observable<TaskList> {
    const url = (taskList: TaskList) => {
      if (this.isGlobalTasks) {
        return `accounts/${this._accountId}/task_lists/${taskList.id}`;
      } else {
        return `${this.moduleBaseUrl}/${this.moduleId}/task_lists/${taskList.id}?account_id=${this._accountId}`;
      }
    };

    return this.taskLists$.pipe(
      take(1),
      switchMap((taskLists) =>
        this._httpClient
          .put<any>(url(taskList), {
            task_list: taskList,
          })
          .pipe(
            map((reponse) => {
              const updatedTaskLists = taskLists.map((taskList) => {
                if (taskList.id === reponse.task_list.id) {
                  return reponse.task_list;
                }
                return taskList;
              });
              this._taskLists.next(updatedTaskLists);
              return reponse.task_list;
            }),
          ),
      ),
    );
  }

  deleteTaskList(id: number): Observable<any> {
    const url = this.isGlobalTasks
      ? `accounts/${this._accountId}/task_lists/${id}`
      : `${this.moduleBaseUrl}/${this.moduleId}/task_lists/${id}?account_id=${this._accountId}`;

    return this.taskLists$.pipe(
      take(1),
      switchMap((taskLists) =>
        this._httpClient.delete<any>(url).pipe(
          map((reponse) => {
            // Find the index of the deleted label within the labels
            const index = taskLists.findIndex((item) => item.id === id);

            // Delete the label
            taskLists.splice(index, 1);

            // Update the labels
            this._taskLists.next(taskLists);

            // Return the deleted status
            return reponse.task_list;
          }),
        ),
      ),
    );
  }

  loadTasks(): Observable<Task[]> {
    return this._httpClient.get<any>(`${this.moduleBaseUrl}/${this.moduleId}/tasks?account_id=${this._accountId}`).pipe(
      tap((response) => {
        let tasks = response.tasks;
        this._tasks.next(tasks);
        return tasks;
      }),
    );
  }

  createTask(
    taskListId: number,
    task: Task,
    pos?: number,
    isSubtask?: boolean,
    updateSubject = true,
    append = true,
  ): Observable<Task> {
    task.workflow_state = task.workflow_state ?? 'not_started';

    const url = (taskListId: number) => {
      if (this.isGlobalTasks) {
        return `accounts/${this._accountId}/task_lists/${taskListId}/tasks`;
      } else {
        return `${this.moduleBaseUrl}/${this.moduleId}/task_lists/${taskListId}/tasks?account_id=${this._accountId}`;
      }
    };

    return this.tasks$.pipe(
      take(1),
      switchMap((tasks) =>
        this._httpClient
          .post<any>(url(taskListId), {
            task,
          })
          .pipe(
            map((response) => {
              let newTask = response.task;
              if (updateSubject) {
                if (!isSubtask) {
                  if (pos) {
                    tasks.splice(pos, 0, newTask);
                  } else {
                    if (append) {
                      tasks = [...tasks, newTask];
                    }
                  }
                  this._tasks.next(tasks);
                }
              }
              return newTask;
            }),
          ),
      ),
    );
  }

  updateTask(taskListId: number, task: Task, isSubtask?: boolean, updateSubject = true): Observable<Task> {
    const url = (taskListId: number, task: Task) => {
      if (this.isGlobalTasks) {
        return `accounts/${this._accountId}/task_lists/${taskListId}/tasks/${task.id}`;
      } else {
        return `${this.moduleBaseUrl}/${this.moduleId}/task_lists/${taskListId}/tasks/${task.id}?account_id=${this._accountId}`;
      }
    };

    return this.tasks$.pipe(
      take(1),
      switchMap((tasks) =>
        this._httpClient.put<any>(url(taskListId, task), { task }).pipe(
          map((response: { task: Task }) => {
            // Find the index of the updated task
            const index = tasks.findIndex((item) => item.id === task.id);

            let updatedTask = response.task;

            if (index === -1) {
              this.handleSubTaskUpdate(updatedTask, tasks);
            }

            // Update the task
            tasks[index] = updatedTask;

            // Update the tasks
            if (updateSubject) {
              this._tasks.next(tasks);
            }

            if (this._task.value?.id === updatedTask.id) {
              this._task.next(updatedTask);
            }

            return updatedTask;
          }),
          switchMap((updatedTask) =>
            this.task$.pipe(
              take(1),
              map(() => {
                // Update the task if it's selected
                if (!isSubtask && updateSubject) this._task.next(updatedTask);

                // Return the updated task
                return updatedTask;
              }),
            ),
          ),
        ),
      ),
    );
  }

  deleteTask(taskListId: number, id: number, updateSubject = true): Observable<any> {
    const url = (taskListId: number, id: number) => {
      if (this.isGlobalTasks) {
        return `accounts/${this._accountId}/task_lists/${taskListId}/tasks/${id}`;
      } else {
        return `${this.moduleBaseUrl}/${this.moduleId}/task_lists/${taskListId}/tasks/${id}?account_id=${this._accountId}`;
      }
    };

    return this.tasks$.pipe(
      take(1),
      switchMap((tasks) =>
        this._httpClient.delete<any>(url(taskListId, id)).pipe(
          map((reponse) => {
            // Find the index of the deleted label within the labels
            const index = tasks.findIndex((item) => item.id === id);

            // Delete the label
            tasks.splice(index, 1);

            // Update the labels
            if (updateSubject) {
              this._tasks.next(tasks);
            }

            return reponse.task;
          }),
        ),
      ),
    );
  }

  getTaskListTemplates(): Observable<TaskListTemplate[]> {
    return this._httpClient.get<any>(`task_list_templates?account_id=${this._accountId}`).pipe(
      tap((response: any) => {
        this._taskListTemplates.next(response.task_list_templates);
      }),
    );
  }

  getTaskListTemplateById(id: number): Observable<TaskListTemplate> {
    return this._httpClient.get<any>(`task_list_templates/${id}?account_id=${this._accountId}`).pipe(
      map((response: any) => {
        this._taskListTemplate.next(response.task_list_template);
        return response.task_list_template;
      }),
    );
  }

  createTaskListTemplate(taskListTemplate: TaskListTemplate): Observable<TaskListTemplate> {
    return this._httpClient
      .post<any>(`task_list_templates?account_id=${this._accountId}`, { task_list_template: taskListTemplate })
      .pipe(
        map((response) => {
          return response.task_list_template;
        }),
      );
  }

  updateTaskListTemplate(taskListTemplate: TaskListTemplate): Observable<TaskListTemplate> {
    return this._httpClient
      .put<any>(`task_list_templates/${taskListTemplate.id}?account_id=${this._accountId}`, {
        task_list_template: taskListTemplate,
      })
      .pipe(
        map((response) => {
          return response.task_list_template;
        }),
      );
  }

  deleteTaskListTemplate(id: number): Observable<any> {
    return this.taskListTemplates$.pipe(
      take(1),
      switchMap((taskListTemplates) =>
        this._httpClient.delete<any>(`task_list_templates/${id}?account_id=${this._accountId}`).pipe(
          map((reponse) => {
            // Find the index of the deleted label within the labels
            const index = taskListTemplates.findIndex((item) => item.id === id);

            // Delete the label
            taskListTemplates.splice(index, 1);

            // Update the labels
            this._taskListTemplates.next(taskListTemplates);

            // Return the deleted status
            return reponse.task_list_template;
          }),
        ),
      ),
    );
  }

  updateTasksOrders(taskList: TaskList, tasks: Task[]): Observable<Task[]> {
    const url = (taskList: TaskList) => {
      if (this.isGlobalTasks) {
        return `accounts/${this._accountId}/task_lists/${taskList.id}/tasks/update_order`;
      } else {
        return `${this.moduleBaseUrl}/${this.moduleId}/task_lists/${taskList.id}/tasks/update_order?account_id=${this._accountId}`;
      }
    };

    let rows = [];
    for (let task of tasks) {
      rows.push({ task_id: task.id, rank: task.rank, parent_id: task.parent_id });
    }

    return this._httpClient.post<any>(url(taskList), { tasks: rows }).pipe(
      map((response) => {
        return response.results;
      }),
    );
  }

  getTags(): Observable<Tag[]> {
    return this._httpClient.get<Tag[]>('api/apps/tasks/tags').pipe(
      tap((response: any) => {
        this._tags.next(response);
      }),
    );
  }

  createTag(tag: Tag): Observable<Tag> {
    return this.tags$.pipe(
      take(1),
      switchMap((tags) =>
        this._httpClient.put<Tag>('api/apps/tasks/tag', { tag }).pipe(
          map((newTag) => {
            // Update the tags with the new tag
            this._tags.next([...tags, newTag]);

            // Return new tag from observable
            return newTag;
          }),
        ),
      ),
    );
  }

  updateTag(id: string, tag: Tag): Observable<Tag> {
    return this.tags$.pipe(
      take(1),
      switchMap((tags) =>
        this._httpClient
          .patch<Tag>('api/apps/tasks/tag', {
            id,
            tag,
          })
          .pipe(
            map((updatedTag) => {
              // Find the index of the updated tag
              const index = tags.findIndex((item) => item.id === id);

              // Update the tag
              tags[index] = updatedTag;

              // Update the tags
              this._tags.next(tags);

              // Return the updated tag
              return updatedTag;
            }),
          ),
      ),
    );
  }

  deleteTag(id: string): Observable<any> {
    return null;
  }

  getTaskStatuses(): Observable<TaskStatus[]> {
    return of(this.workflowStatuses);
  }

  flattenTasks(tasks: Task[]): Task[] {
    return (
      tasks?.reduce((acc, task) => {
        if (task?.children) {
          acc?.push(task, ...this.flattenTasks(task?.children));
        } else {
          acc?.push(task);
        }
        return acc;
      }, []) ?? []
    );
  }

  orderNestedTasks(tasks: Task[]): Task[] {
    tasks.sort((a, b) => a.rank - b.rank);
    return (
      tasks?.map((task) => {
        if (task.children) {
          task.children?.sort((a, b) => a.rank - b.rank);
          this.orderNestedTasks(task.children);
        }
        return task;
      }) ?? []
    );
  }

  isSubtask(task: Task, tasks: Task[]): boolean {
    const flattenTasks = this.flattenTasks(tasks);
    const parentId = task?.parent_id;
    const parentTask = flattenTasks?.find((i) => i.id == parentId);
    if (parentTask !== undefined && parentTask?.task_type !== 'section') {
      return true;
    }
    return false;
  }

  search(filter: { fjson: string; sort: { field: string; order: string }[] }): Observable<Task[]> {
    return this._httpClient
      .post(`accounts/${this._accountId}/tasks/search`, filter)
      .pipe(map((response: { tasks: Task[] }) => response.tasks?.filter(({ task_type }) => task_type === 'task')));
  }

  private handleSubTaskUpdate(updatedTask: Task, tasks: Task[]): void {
    const flattenedTasks = this.flattenTasks(tasks);
    let pathItems = [updatedTask.id];
    let pointer = updatedTask.id;
    let tempTask: Task = null;

    while ((tempTask = flattenedTasks.find(({ id }) => id === pointer))) {
      if (!tempTask.parent_id) {
        break;
      }

      pathItems.push(tempTask.parent_id);
      pointer = tempTask.parent_id;
    }

    pathItems = pathItems.filter((pathItem) => pathItem !== updatedTask.id).reverse();
    tempTask = null;

    for (const pathItem of pathItems) {
      tempTask = (tempTask?.children ?? tasks).find(({ id }) => id === pathItem);
      let index = -1;
      if ((index = tempTask.children.findIndex(({ id }) => id === updatedTask.id)) > -1) {
        tempTask.children[index] = { ...tempTask.children[index], ...updatedTask };
      }
    }
  }
}
