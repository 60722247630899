import {
  Component,
  Input,
  OnInit,
  forwardRef,
  ChangeDetectorRef,
  ViewContainerRef,
  ViewChild,
  TemplateRef,
  OnDestroy,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnChanges,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TemplatePortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { MatButton } from '@angular/material/button';

import * as moment from 'moment';

@Component({
  selector: 'project-dialog-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProjectDialogUserDropdownComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectDialogUserDropdownComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
  @Input() users: any;
  @Output() onUpdate: EventEmitter<any> = new EventEmitter();

  private _value: any;

  onChange = (val) => {};
  onTouched = () => {};

  private _dueDatePanelOverlayRef: OverlayRef;
  private _dueDatePanelTemplatePortal: TemplatePortal;

  @ViewChild('dueDatePanelOrigin')
  private _dueDatePanelOrigin: MatButton;

  @ViewChild('dueDatePanel')
  private _dueDatePanel: TemplateRef<any>;

  /**
   * Constructor
   *
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _overlay: Overlay,
    private _changeDetectorRef: ChangeDetectorRef,
    private _viewContainerRef: ViewContainerRef,
  ) {}

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  lookupUser(id: number) {
    let users = this.users.filter((user) => user.id == id);
    if (users.length > 0) {
      return users[0];
    } else {
      return null;
    }
  }

  // Allows Angular to update the model (rating).
  // Update the model and changes needed for the view here.
  writeValue(value: any): void {
    this._value = value;
    this.onChange(value);
  }
  // Allows Angular to register a function to call when the model (rating) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }
  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  /**
   * Check if the task is overdue or not
   */
  isOverdue(): boolean {
    return moment(this.value, moment.ISO_8601).isBefore(moment(), 'days');
  }

  ngOnInit() {}

  ngOnChanges() {}

  ngOnDestroy() {}

  userInitials(name): string {
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  onSelectUser(user): void {
    this.value = user.id;
    this.onUpdate.emit('');
    this.closeDueDatePanel();
  }

  updateDueDate($event: moment.Moment): void {
    this.value = $event;
    this.onUpdate.emit($event);
    this.closeDueDatePanel();
  }

  removeDueDate(): void {
    this.value = null;
    this.onUpdate.emit(null);
    this.closeDueDatePanel();
  }

  /**
   * Open due date panel
   */
  openDueDatePanel(): void {
    // Create the overlay
    this._dueDatePanelOverlayRef = this._overlay.create({
      backdropClass: '',
      hasBackdrop: true,
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._dueDatePanelOrigin._elementRef.nativeElement)
        .withFlexibleDimensions()
        .withViewportMargin(64)
        .withLockedPosition()
        .withPositions([
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
        ]),
    });

    // Create a portal from the template
    this._dueDatePanelTemplatePortal = new TemplatePortal(this._dueDatePanel, this._viewContainerRef);

    // Attach the portal to the overlay
    this._dueDatePanelOverlayRef.attach(this._dueDatePanelTemplatePortal);

    // Subscribe to the backdrop click
    this._dueDatePanelOverlayRef.backdropClick().subscribe(() => {
      // Close the panel
      this.closeDueDatePanel();
    });
  }

  /**
   * Close the due date panel
   */
  closeDueDatePanel(): void {
    // If overlay exists and attached...
    if (this._dueDatePanelOverlayRef && this._dueDatePanelOverlayRef.hasAttached()) {
      // Detach it
      this._dueDatePanelOverlayRef.detach();
    }

    // If template portal exists and attached...
    if (this._dueDatePanelTemplatePortal && this._dueDatePanelTemplatePortal.isAttached) {
      // Detach it
      this._dueDatePanelTemplatePortal.detach();
    }

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }
}
