<mat-form-field class="flex-auto gt-xs:pr-3" (click)="edit()" (focus)="edit()" *ngIf="!multiple">
  <mat-label
    >{{ fieldDef.label }} <i class="fa fa-info-circle pointer-events-auto" [matTooltip]="showTip" *ngIf="showTip"> </i
  ></mat-label>
  <div class="w-full">
    <mat-select
      [placeholder]="fieldDef.label"
      (selectionChange)="onSelectionChange($event)"
      [(ngModel)]="value"
      (blur)="onBlur($event)"
      [disabled]="isDisabled"
    >
      <mat-option [value]="''"></mat-option>
      <mat-option *ngFor="let option of filteredOptions" [value]="option.id">{{
        option?.name || option?.full_name
      }}</mat-option>
    </mat-select>
  </div>
</mat-form-field>

<mat-form-field class="flex-auto gt-xs:pr-3" *ngIf="multiple">
  <mat-label
    >{{ fieldDef.label }} <i class="fa fa-info-circle pointer-events-auto" [matTooltip]="showTip" *ngIf="showTip"> </i
  ></mat-label>
  <div class="w-full" [matTooltip]="multiSelectOptionsText">
    <mat-select
      multiple
      [placeholder]="fieldDef.label"
      (selectionChange)="onSelectionChange($event)"
      [(ngModel)]="value"
      (blur)="onBlur($event)"
      [disabled]="isDisabled"
    >
      <mat-option *ngFor="let option of filteredOptions" [value]="option.id">
        {{ option?.name || option?.full_name }}
      </mat-option>
    </mat-select>
  </div>
</mat-form-field>
