import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { TreoFindByKeyPipeModule } from '@treo/pipes/find-by-key';
import { MatSelectModule } from '@angular/material/select';
import { TreoAutogrowModule } from '@treo/directives/autogrow';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocompleteModule } from '@angular/material/autocomplete';
import { Overlay } from '@angular/cdk/overlay';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InlineFieldDirective } from './inline-field.directive';
import { InlineFieldComponent } from './inline-field.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

import { TextInlineFieldComponent } from './text-inline-field/text-inline-field.component';
import { DateTimeInlineFieldComponent } from './datetime-inline-field/datetime-inline-field.component';
import { DecimalInlineFieldComponent } from './decimal-inline-field/decimal-inline-field.component';
import { NumberInlineFieldComponent } from './number-inline-field/number-inline-field.component';
import { MoneyInlineFieldComponent } from './money-inline-field/money-inline-field.component';
import { PercentInlineFieldComponent } from './percent-inline-field/percent-inline-field.component';
import { TagsInlineFieldComponent } from './tags-inline-field/tags-inline-field.component';
import { TextAreaInlineFieldComponent } from './textarea-inline-field/textarea-inline-field.component';
import { TypeaheadInlineFieldComponent } from './typeahead-inline-field/typeahead-inline-field.component';
import { SelectInlineFieldComponent } from './select-inline-field/select-inline-field.component';
import { YearInlineFieldComponent } from './year-inline-field/year-inline-field.component';
import { CheckboxInlineFieldComponent } from './checkbox-inline-field/checkbox-inline-field.component';
import { UrlInlineFieldComponent } from './url-inline-field/url-inline-field.component';
import { RecordInlineFieldComponent } from './record-inline-field/record-inline-field.component';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { WidgetsModule } from 'app/shared/components/widgets/widgets.module';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        InlineFieldDirective,
        InlineFieldComponent,
        TextInlineFieldComponent,
        DateTimeInlineFieldComponent,
        DecimalInlineFieldComponent,
        NumberInlineFieldComponent,
        MoneyInlineFieldComponent,
        PercentInlineFieldComponent,
        TagsInlineFieldComponent,
        TextAreaInlineFieldComponent,
        TypeaheadInlineFieldComponent,
        SelectInlineFieldComponent,
        YearInlineFieldComponent,
        CheckboxInlineFieldComponent,
        UrlInlineFieldComponent,
        RecordInlineFieldComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDividerModule,
        TreoFindByKeyPipeModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatMomentDateModule,
        MatProgressBarModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        MatChipsModule,
        MatAutocompleteModule,
        TreoAutogrowModule,
        PipesModule,
        WidgetsModule,
        CurrencyMaskModule,
        MatTooltipModule,
        NgxMaskModule.forRoot(),
    ],
    exports: [InlineFieldComponent],
    providers: [
        {
            provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => {
                return () => overlay.scrollStrategies.block();
            },
            deps: [Overlay],
        },
    ]
})
export class InlineFieldsModule {}
