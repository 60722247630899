import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Message } from 'app/layout/common/messages/messages.types';
import { MessagesService } from 'app/layout/common/messages/messages.service';

@Component({
  selector: 'messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'messages',
})
export class MessagesComponent implements OnInit, OnDestroy {
  unreadCount: number;

  // Private
  private _messages: Message[];
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<void>;

  @ViewChild('messagesOrigin')
  private _messagesOrigin: MatButton;

  @ViewChild('messagesPanel')
  private _messagesPanel: TemplateRef<any>;

  /**
   * Constructor
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {MessagesService} _messagesService
   * @param {Overlay} _overlay
   * @param {ViewContainerRef} _viewContainerRef
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _messagesService: MessagesService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Set the defaults
    this.unreadCount = 0;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for messages
   */
  @Input()
  set messages(value: Message[]) {
    // Store the data
    this._messagesService.store(value);
  }

  get messages(): Message[] {
    return this._messages;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to message changes
    this._messagesService.messages$.pipe(takeUntil(this._unsubscribeAll)).subscribe((messages: Message[]) => {
      // Load the messages
      this._messages = messages;

      // Calculate the unread count
      this._calculateUnreadCount();

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    // Dispose the overlay if it's still on the DOM
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Calculate the unread count
   *
   * @private
   */
  private _calculateUnreadCount(): void {
    let count = 0;

    if (this.messages && this.messages.length) {
      count = this.messages.filter((message) => message.read === false).length;
    }

    this.unreadCount = count;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create route from given link
   */
  createRouteFromLink(link): string[] {
    // Split the link and add a leading slash
    const route = link.split('/');
    route.unshift('/');

    // Return the route
    return route;
  }

  /**
   * Open the messages panel
   */
  openPanel(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: '',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._messagesOrigin._elementRef.nativeElement)
        .withFlexibleDimensions()
        .withViewportMargin(16)
        .withLockedPosition()
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    // Create a portal from the template
    const templatePortal = new TemplatePortal(this._messagesPanel, this._viewContainerRef);

    // Attach the portal to the overlay
    this._overlayRef.attach(templatePortal);

    // Subscribe to the backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      // If overlay exists and attached...
      if (this._overlayRef && this._overlayRef.hasAttached()) {
        // Detach it
        this._overlayRef.detach();
      }

      // If template portal exists and attached...
      if (templatePortal && templatePortal.isAttached) {
        // Detach it
        templatePortal.detach();
      }
    });
  }

  /**
   * Mark all messages as read
   */
  markAllAsRead(): void {
    // Mark all as read
    this._messagesService.markAllAsRead().subscribe();
  }

  /**
   * Toggle read status of the given message
   */
  toggleRead(message): void {
    // Toggle the read status
    message.read = !message.read;

    // Update the message
    this._messagesService.update(message.id, message).subscribe();
  }

  /**
   * Delete the given message
   */
  delete(message): void {
    // Delete the message
    this._messagesService.delete(message.id).subscribe();
  }
}
