<div class="priority-and-due-date">
  <div class="due-date">
    <button mat-icon-button (click)="openDueDatePanel()" #dueDatePanelOrigin>
      <ng-container *ngIf="!lookupUser(value) as user">
        <mat-icon [svgIcon]="'account_circle'"></mat-icon>
      </ng-container>
      <ng-container *ngIf="lookupUser(value) as user">
        <span *ngIf="value">{{ userInitials(user.full_name) }}</span>
      </ng-container>
    </button>
  </div>
  <ng-template #dueDatePanel>
    <div class="tasks-details-user-panel">
      <div class="user-panel-content">
        <div *ngFor="let user of users" class="user-profile-row">
          <button (click)="onSelectUser(user)">{{ user.full_name }}</button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
