import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Account } from 'app/core/models';
import { SetAccount } from './actions';

export interface AccountStateModel {
  account: Account;
}

@State<AccountStateModel>({
  name: new StateToken<AccountStateModel>('accountState'),
  defaults: { account: null },
})
@Injectable()
export class AccountState {
  constructor() {}

  @Selector()
  static getAccount(state: AccountStateModel): Account {
    return state.account;
  }

  @Action(SetAccount)
  getReports(context: StateContext<AccountStateModel>, { account }: AccountStateModel) {
    const state = context.getState();
    context.setState({ ...state, account });
  }
}
