import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { TaskList, Task, Tag, TaskListTemplate, TaskStatus } from '../../shared/modules/tasks/tasks.types';
import { ContactPagination } from '../contacts/contacts.types';

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  // Private
  private _taskList: BehaviorSubject<TaskList | null>;
  private _taskLists: BehaviorSubject<TaskList[] | null>;
  private _taskListTemplates: BehaviorSubject<TaskList[] | null>;
  private _tasks: BehaviorSubject<Task[] | null>;
  private _pagination: BehaviorSubject<ContactPagination | null>;
  private _task: BehaviorSubject<Task | null>;
  private _tags: BehaviorSubject<Tag[] | null>;
  private _accountId: string;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the private defaults
    this._taskList = new BehaviorSubject(null);
    this._taskLists = new BehaviorSubject(null);
    this._taskListTemplates = new BehaviorSubject(null);
    this._tasks = new BehaviorSubject(null);
    this._task = new BehaviorSubject(null);
    this._tags = new BehaviorSubject(null);
    this._pagination = new BehaviorSubject(null);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for tags
   */
  get tags$(): Observable<Tag[]> {
    return this._tags.asObservable();
  }

  /**
   * Getter for contact
   */
  get taskList$(): Observable<TaskList> {
    return this._taskList.asObservable();
  }

  /**
   * Getter for contacts
   */
  get taskLists$(): Observable<TaskList[]> {
    return this._taskLists.asObservable();
  }

  /**
   * Getter for contacts
   */
  get tasks$(): Observable<Task[]> {
    return this._tasks.asObservable();
  }

  /**
   * Getter for contacts
   */
  get task$(): Observable<Task> {
    return this._task.asObservable();
  }

  /**
   * Getter for contact
   */
  get taskListTemplates$(): Observable<TaskListTemplate[]> {
    return this._taskListTemplates.asObservable();
  }

  /**
   * Getter for pagination
   */
  get pagination$(): Observable<ContactPagination> {
    return this._pagination.asObservable();
  }

  set accountId(val: string) {
    this._accountId = val;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get contacts
   *
   * @param sortField
   * @param sortDirection
   */
  getTaskLists(): Observable<TaskList[]> {
    return this._httpClient.get<any>(`/task_lists?account_id=${this._accountId}`).pipe(
      tap((response: any) => {
        this._taskLists.next(response.task_lists);
      }),
    );
  }

  /**
   * Get project by id
   */
  getTaskListById(id: string): Observable<TaskList> {
    return this._httpClient.get<any>(`/task_lists/${id}?account_id=${this._accountId}`).pipe(
      tap((response) => {
        this._taskList.next(response.task_list);
        return response.task_list;
      }),
    );
  }

  /**
   * Get pipeline by id
   */
  getTaskById(id: string): Observable<Task> {
    return this.taskList$.pipe(
      take(1),
      switchMap((taskList) =>
        this._httpClient.get<any>(`accounts/${this._accountId}/tasks/${id}`).pipe(
          tap((response) => {
            this._task.next(response.task);
            return response.task;
          }),
        ),
      ),
    );
  }

  createTaskList(taskList: TaskList): Observable<TaskList> {
    return this.taskLists$.pipe(
      take(1),
      switchMap((taskLists) =>
        this._httpClient.post<any>(`/task_lists?account_id=${this._accountId}`, { task_list: taskList }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._taskLists.next([reponse.task_list, ...taskLists]);

            // Return the new contact
            return reponse.task_list;
          }),
        ),
      ),
    );
  }

  updateTaskList(taskList: TaskList): Observable<TaskList> {
    return this.taskLists$.pipe(
      take(1),
      switchMap((taskLists) =>
        this._httpClient
          .put<any>(`/task_lists/${taskList.id}?account_id=${this._accountId}`, { task_list: taskList })
          .pipe(
            map((reponse) => {
              // Update the contacts with the new contact
              //this._pipelines.next([reponse.pipeline, ...pipelines]);

              // Return the new contact
              return reponse.task_list;
            }),
          ),
      ),
    );
  }

  deleteTaskList(id: number): Observable<any> {
    return this.taskLists$.pipe(
      take(1),
      switchMap((taskLists) =>
        this._httpClient.delete<any>(`/task_lists/${id}?account_id=${this._accountId}`).pipe(
          map((reponse) => {
            // Find the index of the deleted label within the labels
            const index = taskLists.findIndex((item) => item.id === id);

            // Delete the label
            taskLists.splice(index, 1);

            // Update the labels
            this._taskLists.next(taskLists);

            // Return the deleted status
            return reponse.task_list;
          }),
        ),
      ),
    );
  }

  getTasks(
    page: number = 1,
    size: number = 50,
    sort = 'due_date',
    order: string = 'DESC',
    filters: any = {},
  ): Observable<{ pagination: ContactPagination; contacts: Task[] }> {
    const {
      pipelineIdIn = [],
      projectStageIdIn = [],
      workflowStateIn = [],
      assignedUserIdIn = [],
      dueDateGteq = '',
      dueDateLteq = '',
      taskTypeIn = [],
    } = filters;

    return this._httpClient
      .get<any>(`accounts/${this._accountId}/tasks`, {
        params: {
          page: '' + page,
          per_page: '' + size,
          sort: sort,
          order: order,
          pipeline_id_in: pipelineIdIn.join(','),
          project_stage_id_in: projectStageIdIn.join(','),
          workflow_state_in: workflowStateIn.join(','),
          assigned_user_id_in: assignedUserIdIn.join(','),
          task_type_in: taskTypeIn.join(','),
          due_date_gteq: dueDateGteq,
          due_date_lteq: dueDateLteq,
        },
      })
      .pipe(
        tap((response) => {
          const lastPage = Math.max(Math.ceil(response.count / size), 1);
          const begin = page * size;
          const end = Math.min(size * (page + 1), response.count);

          const pagination = {
            length: response.count,
            size: size,
            page: page - 1,
            lastPage: lastPage - 1,
            startIndex: begin,
            endIndex: end,
          };

          let tasks = response.results;
          this._pagination.next(pagination);
          this._tasks.next(tasks);

          return {
            pagination,
            tasks,
          };
        }),
      );
  }

  getTasksByContactId(contactId: string): Observable<Task[]> {
    return this._httpClient.get<any>(`contacts/${contactId}/tasks?account_id=${this._accountId}`).pipe(
      map((response) => {
        this._tasks.next(response.tasks);
        return response.tasks;
      }),
    );
  }

  createTask(task: Task): Observable<Task> {
    return this.tasks$.pipe(
      take(1),
      switchMap((tasks) =>
        this._httpClient.post<any>(`accounts/${this._accountId}/tasks`, { task }).pipe(
          map((response) => {
            let newTask = response.task;
            tasks = [newTask, ...tasks];
            //let newTaskList = taskList
            //tasks = tasks;
            // Update the contacts with the new contact
            this._tasks.next(tasks);

            //this.loadTasks().subscribe();
            return newTask;
          }),
        ),
      ),
    );
  }

  updateTask(task: Task): Observable<Task> {
    return this.tasks$.pipe(
      take(1),
      switchMap((tasks) =>
        this._httpClient
          .put<any>(`accounts/${this._accountId}/tasks/${task.id}?account_id=${this._accountId}`, { task })
          .pipe(
            map((response) => {
              // Find the index of the updated task
              const index = tasks.findIndex((item) => item.id === task.id);

              let updatedTask = response.task;

              // Update the task
              tasks[index] = updatedTask;

              // Update the tasks
              this._tasks.next(tasks);

              //this.loadTasks().subscribe();
              // Return the updated task
              return updatedTask;
            }),
            switchMap((updatedTask) =>
              this.task$.pipe(
                take(1),
                filter((item) => item && item.id === updatedTask.id),
                tap(() => {
                  // Update the task if it's selected
                  this._task.next(updatedTask);

                  // Return the updated task
                  return updatedTask;
                }),
              ),
            ),
          ),
      ),
    );
  }

  simpleUpdateTask(task: Task): Observable<Task> {
    return this._httpClient
      .put<any>(`accounts/${this._accountId}/tasks/${task.id}?account_id=${this._accountId}`, { task })
      .pipe(
        map((response) => {
          return response.task;
        }),
      );
  }

  /**
   * Delete the task
   *
   * @param id
   */
  deleteTask(id: number): Observable<any> {
    return this.tasks$.pipe(
      take(1),
      switchMap((tasks) =>
        this._httpClient.delete<any>(`accounts/${this._accountId}/tasks/${id}?account_id=${this._accountId}`).pipe(
          map((reponse) => {
            // Find the index of the deleted label within the labels
            const index = tasks.findIndex((item) => item.id === id);

            // Delete the label
            tasks.splice(index, 1);

            // Update the labels
            this._tasks.next(tasks);

            //this.loadTasks().subscribe();
            // Return the deleted status
            return reponse.task;
          }),
        ),
      ),
    );
  }

  /**
   * Update tasks orders
   *
   * @param tasks
   */
  updateTasksOrders(tasks: Task[]): Observable<Task[]> {
    let rows = [];
    for (let task of tasks) {
      rows.push({ task_id: task.id, rank: task.rank });
    }
    return this._httpClient
      .post<any>(`accounts/${this._accountId}/tasks/update_order?account_id=${this._accountId}`, { tasks: rows })
      .pipe(
        map((response) => {
          return response.results;
        }),
      );
  }

  /**
   * Get contacts
   *
   * @param sortField
   * @param sortDirection
   */
  getTaskListTemplates(accountId?: string): Observable<TaskListTemplate[]> {
    return this._httpClient.get<any>(`task_list_templates?account_id=${this._accountId || accountId}`).pipe(
      tap((response: any) => {
        this._taskListTemplates.next(response.task_list_templates);
      }),
    );
  }

  createTaskListTemplate(taskListTemplate: TaskListTemplate): Observable<TaskListTemplate> {
    return this._httpClient.post<TaskListTemplate>(`task_list_templates?account_id=${this._accountId}`, {
      task_list_template: taskListTemplate,
    });
  }

  updateTaskListTemplate(taskListTemplate: TaskListTemplate): Observable<TaskListTemplate> {
    return this._httpClient.put<TaskListTemplate>(
      `task_list_templates/${taskListTemplate.id}?account_id=${this._accountId}`,
      { task_list_template: taskListTemplate },
    );
  }

  /**
   * Get tags
   */
  getTags(): Observable<Tag[]> {
    return this._httpClient.get<Tag[]>('api/apps/tasks/tags').pipe(
      tap((response: any) => {
        this._tags.next(response);
      }),
    );
  }

  /**
   * Crate tag
   *
   * @param tag
   */
  createTag(tag: Tag): Observable<Tag> {
    return this.tags$.pipe(
      take(1),
      switchMap((tags) =>
        this._httpClient.put<Tag>('api/apps/tasks/tag', { tag }).pipe(
          map((newTag) => {
            // Update the tags with the new tag
            this._tags.next([...tags, newTag]);

            // Return new tag from observable
            return newTag;
          }),
        ),
      ),
    );
  }

  /**
   * Update the tag
   *
   * @param id
   * @param tag
   */
  updateTag(id: string, tag: Tag): Observable<Tag> {
    return this.tags$.pipe(
      take(1),
      switchMap((tags) =>
        this._httpClient
          .patch<Tag>('api/apps/tasks/tag', {
            id,
            tag,
          })
          .pipe(
            map((updatedTag) => {
              // Find the index of the updated tag
              const index = tags.findIndex((item) => item.id === id);

              // Update the tag
              tags[index] = updatedTag;

              // Update the tags
              this._tags.next(tags);

              // Return the updated tag
              return updatedTag;
            }),
          ),
      ),
    );
  }

  /**
   * Delete the tag
   *
   * @param id
   */
  deleteTag(id: string): Observable<any> {
    return null;
  }

  getTaskStatuses(): Observable<TaskStatus[]> {
    const workflowStatues: TaskStatus[] = [
      {
        key: 'n_a',
        label: 'N/A',
        color: 'gray',
        icon: 'more_horiz',
      },
      {
        key: 'not_started',
        label: 'Not Started',
        color: 'gray',
        icon: 'more_horiz',
      },
      {
        key: 'needs_review',
        label: 'Needs Review',
        color: 'red',
        icon: 'error_outline',
      },
      {
        key: 'in_progress',
        label: 'In Progress',
        color: 'blue',
        icon: 'more_horiz',
      },
      {
        key: 'complete',
        label: 'Complete',
        color: 'green',
        icon: 'done',
      },
    ];

    return of(workflowStatues);
  }
}
