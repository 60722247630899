import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { TreoNavigationModule } from '@treo/components/navigation';
import { SharingLayoutComponent } from 'app/layout/layouts/sharing/sharing.component';

@NgModule({
  declarations: [SharingLayoutComponent],
  imports: [RouterModule, SharedModule, TreoNavigationModule],
  exports: [SharingLayoutComponent],
})
export class SharingLayoutModule {}
