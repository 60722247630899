import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { switchMap } from 'rxjs/operators';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

import { Account } from 'app/core/models';
import { AccountService } from 'app/core';
import { TasksService } from 'app/modules/tasks/tasks.service';
import { RelatedToType } from 'app/shared/enums';
import { Task, TaskStatus } from 'app/shared/modules/tasks/tasks.types';
import { FormFieldComponent, FormFieldInterfaceComponent, PartialFormField } from '../form-field.types';
import { ProjectAddContactComponent } from 'app/modules/projects/modals/project-add-contact/project-add-contact.component';
import { ProjectAddCompanyComponent } from 'app/modules/projects/modals/project-add-company/project-add-company.component';
import { FoldersTabService } from 'app/shared/modules/folders/folders.service';
import { FileResource } from 'app/shared/modules/folders/folders.types';
import { FoldersTabViewFileComponent } from 'app/shared/modules/folders/view-file/view-file.component';
import { ProjectsService } from 'app/modules/projects/projects.service';
import { PropertiesService } from 'app/modules/properties/properties.service';
import { Project, Property } from 'app/modules/projects/projects.types';

@Component({
  templateUrl: './contact-form-field.component.html',
  styleUrls: ['./contact-form-field.component.scss'],
})
export class ContactFormFieldComponent extends FormFieldComponent implements OnInit {
  @ViewChild('inputControl') inputControl: ElementRef;

  editing = false;
  value: any;
  customObjType = RelatedToType.Contact;
  workflowStates: TaskStatus[];

  isNewModel = false;
  options: any[];
  filteredOptions: any[];
  account: Account;

  fileResource: FileResource;
  uploadingFile = false;

  private originalValue: any;
  private _readonly: boolean;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router,
    private readonly _matDialog: MatDialog,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _tasksService: TasksService,
    private readonly _accountService: AccountService,
    private readonly foldersTabService: FoldersTabService,
    private readonly projectsService: ProjectsService,
    private readonly propertiesService: PropertiesService,
    private readonly snackBar: MatSnackBar,
  ) {
    super();

    const currentAccount = this._accountService.getCurrentAccount();
    this._tasksService.accountId = currentAccount.id;
    this.foldersTabService.accountId = currentAccount.id;
    this.account = currentAccount;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.value = this.model[this.fieldDef.name];
    this.parseContactType();
    this.originalValue = this.value;
    this._readonly = this.fieldDef?.meta?.readonly;
    this.isNewModel = this.model?.id == null;
    this.customObjType = this.fieldDef?.meta?.record_type;
    this.handleCustomObjType();
  }

  private handleCustomObjType() {
    if (this.fieldDef?.meta?.related_to_type === RelatedToType.Task) {
      this.customObjType = RelatedToType.Task;
    }

    switch (this.customObjType) {
      case RelatedToType.User:
        this.options = this.data.options['users'];
        this.filteredOptions = this.options;
        break;

      case RelatedToType.FileResource:
        this.setFolderSettings();
        this.foldersTabService
          .getFile(this.model.root_folder_resource_id, this.model[this.fieldDef.name])
          .pipe(untilComponentDestroyed(this))
          .subscribe((fileResource) => {
            this.fileResource = fileResource;
          });
        break;

      case RelatedToType.Task:
        if (this.value) this.value = JSON.parse(String(this.value));
        this._tasksService
          .getTaskStatuses()
          .pipe(untilComponentDestroyed(this))
          .subscribe((taskStatuses) => {
            this.workflowStates = taskStatuses;
          });
    }
  }

  goToRecord() {
    let route = this._activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    let vals = this.getValues();
    if (this.customObjType == RelatedToType.Contact) {
      this._router.navigate(['/contacts', vals[0]]);
    } else if (this.customObjType == RelatedToType.Company) {
      this._router.navigate(['/companies', vals[0]]);
    } else if (this.customObjType == RelatedToType.Task) {
      this._router.navigate([
        '/projects',
        this.model.pipeline_id,
        this.value.project_id,
        'tasks',
        this.value.task_list_id,
        this.value.id,
      ]);
    }
  }

  updateTask(task: Task): void {
    this._tasksService.simpleUpdateTask(task).pipe(untilComponentDestroyed(this)).subscribe();
  }

  public openContactModal(): void {
    const vals = this.getValues();
    let val;
    if (vals) {
      val = vals[0];
    }
    const data = {
      panelClass: 'mailbox-compose-dialog',
      data: { contactId: val, related_to_type: this.fieldDef.related_to_type, object: this.model, byPass: true },
    };

    const dialogRef =
      this.customObjType == RelatedToType.Contact
        ? this._matDialog.open(ProjectAddContactComponent, data)
        : this._matDialog.open(ProjectAddCompanyComponent, data);

    dialogRef
      .afterClosed()
      .pipe(untilComponentDestroyed(this))
      .subscribe((result) => {
        this.onSave(result?.contact?.id);

        // If this form is being used before a model has been saved i.e. the create form.
        if (this.isNewModel) {
          this.value = result?.contact?.name;

          // JERRY FOR OPENAREA
          if (
            (this.account?.subdomain == 'openarea' || this.account?.subdomain == 'area') &&
            this.customObjType == RelatedToType.Company
          ) {
            this.update.emit({ ['title']: `${result?.contact?.name} Deal` });
          }
        }
      });
  }

  removeModelField(event: Event): void {
    event.stopPropagation();

    if (this.customObjType === RelatedToType.FileResource && !this.fileResource) {
      this.fileNotFoundSnackBar();
      return;
    }

    if (this.isNewModel) {
      this.updateModel(null);
      this.update.emit({ [this.fieldDef.name]: null });
      this._changeDetectorRef.markForCheck();
      this.value = null;
    } else if (confirm('Are you sure?')) {
      this.updateModel(null);
      this.update.emit({ [this.fieldDef.name]: null });
      this._changeDetectorRef.markForCheck();
    }
  }

  updateChangedModel(model: any): void {
    this.model = model;
    this.value = this.model[this.fieldDef.name];
    this.parseContactType();
    this.originalValue = this.value;
  }

  getValues() {
    if (this.isNewModel) {
      return [this.model[this.fieldDef.name], this.value];
    } else {
      if (this.model[this.fieldDef.name] && typeof this.model[this.fieldDef.name] === 'string')
        return this.model[this.fieldDef.name].split('|');
      else return null;
    }
  }

  parseContactType() {
    const customObjType = this.fieldDef?.meta?.record_type;
    if ([RelatedToType.Contact, RelatedToType.Company, RelatedToType.FileResource].includes(customObjType)) {
      if (this.value) {
        const value = this.getValues();
        this.value = value[1];
      }
    } else if (customObjType == RelatedToType.User) {
      if (this.value) {
        const values = this.getValues();
        if (values && values.length == 2) {
          this.value = values[0];
          this.value = parseInt(this.value);
        }
      }
    }
  }

  onSave(value: any) {
    if (value && this.originalValue != value) {
      this.updateModel(value);
      this.update.emit({ [this.fieldDef.name]: value });
      this._changeDetectorRef.markForCheck();
    }
  }

  updateModel(value: any) {
    this.model[this.fieldDef.name] = value;
    this.originalValue = value;
  }

  filterQueryParams(): string | undefined {
    const value = this.getValues();

    if (!value) return undefined;

    let fjson = [[this.fieldDef.name, 'in', [[parseInt(value[0]), value[1]]]]];
    let queryFilter = {
      fjson: JSON.stringify(fjson),
      q: '',
    };

    return JSON.stringify(queryFilter);
  }

  edit() {
    if (!this.editing && !this._readonly) {
      this.editing = true;
    }
  }

  onBlur($event: Event) {
    this.editing = false;
  }

  onSelectionChange($event: MatSelectChange) {
    this.onSave($event.value);
  }

  isFilterable() {
    return this.fieldDef.related_to_type === RelatedToType.Property;
  }

  hasNoRecordType(): boolean {
    return !this.fieldDef?.meta?.record_type;
  }

  setFolderSettings() {
    if (this.data?.relatedToType === RelatedToType.Project) {
      this.projectsService.project$.pipe(untilComponentDestroyed(this)).subscribe((project: Project) => {
        this.foldersTabService.moduleId = project.id;
        this.foldersTabService.moduleBaseUrl = 'projects';
      });
    } else if (this.data?.relatedToType === RelatedToType.Property) {
      this.propertiesService.property$.pipe(untilComponentDestroyed(this)).subscribe((property: Property) => {
        this.foldersTabService.moduleId = property.id.toString();
        this.foldersTabService.moduleBaseUrl = 'properties';
      });
    }
  }

  onFileChanged(event: InputEvent) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (!file) return;

    const folderId = this.model?.root_folder_resource_id;
    const fileResource = {
      folder_resource_id: folderId,
      name: file?.name,
    };

    this.uploadingFile = true;
    this.foldersTabService
      .createFile(folderId, fileResource)
      .pipe(
        untilComponentDestroyed(this),
        switchMap((fileResource) => this.foldersTabService.uploadDocument(folderId, fileResource.id, file)),
      )
      .subscribe((fileResource) => {
        this.fileResource = fileResource;
        this.onSave(fileResource.id);
        this.uploadingFile = false;
      });
  }

  viewFile(fileResource: FileResource): void {
    if (!this.value && this.readonly) {
      return;
    }

    if (!fileResource) {
      this.fileNotFoundSnackBar();
      return;
    }

    this._matDialog.open(FoldersTabViewFileComponent, {
      panelClass: 'mailbox-compose-dialog',
      minWidth: '100vw',
      height: '100vh',
      data: {
        fileResource: fileResource,
      },
    });
  }

  downloadFile(file: FileResource): void {
    if (!file) {
      this.fileNotFoundSnackBar();
      return;
    }

    window.open(file.document_url, '_blank');
  }

  private fileNotFoundSnackBar(): void {
    this.snackBar.open('File not found', 'Close', { duration: 3000 });
  }
}
