/* eslint-disable max-len */
import { TreoNavigationItem } from '@treo/components/navigation';

export const defaultNavigation: TreoNavigationItem[] = [
  {
    id: 'starter',
    title: 'Starter',
    subtitle: 'Treo Starter Kit',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: 'starter.example',
        title: 'Example component',
        type: 'basic',
        link: '/example',
      },
      {
        id: 'starter.dummy.1',
        title: 'Dummy menu item #1',
        type: 'basic',
      },
      {
        id: 'starter.dummy.2',
        title: 'Dummy menu item #1',
        type: 'basic',
      },
    ],
  },
];
export const compactNavigation: TreoNavigationItem[] = [
  {
    id: 'starter',
    title: 'Starter',
    type: 'aside',
    icon: 'apps',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
export const futuristicNavigation: TreoNavigationItem[] = [
  {
    id: 'starter.example',
    title: 'Example component',
    type: 'basic',
    icon: 'heroicons:chart-pie',
    link: '/example',
  },
  {
    id: 'starter.dummy.1',
    title: 'Dummy menu item #1',
    icon: 'heroicons:calendar',
    type: 'basic',
  },
  {
    id: 'starter.dummy.2',
    title: 'Dummy menu item #1',
    icon: 'heroicons:user-group',
    type: 'basic',
  },
];
export const horizontalNavigation: TreoNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    link: '/dashboard',
    icon: 'heroicons_outline:home',
    type: 'basic',
  },
  {
    id: 'projects',
    title: 'Deals',
    link: '/projects',
    icon: 'heroicons_outline:office-building',
    type: 'basic',
  },
  {
    id: 'contacts',
    title: 'Contacts',
    link: '/contacts',
    icon: 'heroicons_outline:user-group',
    type: 'basic',
  },
  {
    id: 'calendar',
    title: 'Calendar',
    link: '/calendar',
    icon: 'heroicons_outline:calendar',
    type: 'basic',
  },
  {
    id: 'research',
    title: 'Parceled Pro [Beta]',
    link: '/research',
    icon: 'heroicons_outline:globe',
    type: 'basic',
  },
  /*{
      id: 'tasks',
      title: 'Tasks',
      link: '/tasks',
      type: 'basic'
    },
    {
      id: 'reports',
      title: 'Reports',
      link: '/reports',
      type: 'basic'
    },
    {
      id: 'settings',
      title: 'Settings',
      link: '/settings',
      type: 'basic'
    }*/
];
