import { Pipe, PipeTransform } from '@angular/core';
import formatNumber from 'accounting-js/lib/formatNumber.js';

@Pipe({ name: 'operatorToDisplayString' })
export class OperatorToDisplayStringPipe implements PipeTransform {
  transform(value: string): any {
    if (value) {
      if (value == 'eq') {
        return 'Equals';
      } else if (value == 'gt') {
        return 'Greater Than';
      } else if (value == 'gte') {
        return 'Greater Than or Equals';
      } else if (value == 'lt') {
        return 'Less Than';
      } else if (value == 'lte') {
        return 'Less Than or Equals';
      } else if (value == 'contains') {
        return 'Contains';
      } else if (value == 'like') {
        return 'Like';
      } else if (value == 'in') {
        return 'In';
      } else if (value == 'not') {
        return 'Not';
      } else if (value == 'is') {
        return 'Is';
      }
    }
    return value;
  }
}
