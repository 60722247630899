import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormFieldComponent } from '../form-field.types';
import { FormatNumberPipe } from 'app/shared/pipes/format-number-pipe';
import UrlUtils from 'app/shared/Utils/url.utils';

@Component({
  templateUrl: './decimal-form-field.component.html',
  styleUrls: ['./decimal-form-field.component.scss'],
})
export class DecimalFormFieldComponent extends FormFieldComponent implements OnInit {
  @ViewChild('inputControl') inputControl: ElementRef;

  public editing: boolean = false;
  public value;
  private _originalValue;
  private _readonly;

  constructor(private _formatNumber: FormatNumberPipe) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.value = this.model[this.fieldDef.name];

    // we want to track the original value, so that we don't hit save to many times
    this._originalValue = this.value;
    this.value = this._formatNumber.transform(this.value);
    if (this.fieldDef.meta && this.fieldDef.meta.readonly) {
      this._readonly = this.fieldDef.meta.readonly;
    }
    if (this.readonly) {
      this._readonly = this.readonly;
    }
    this.readonly = this._readonly;
  }

  /**
   * When the model is updated after save, re-run our logic
   * @param model
   */
  updateChangedModel(model: any): void {
    this.model = model;
    //this.value = this.model[this.fieldDef.name] * 100;
    this.value = this.model[this.fieldDef.name];

    // we want to track the original value, so that we don't hit save to many times
    this._originalValue = this.value;
  }

  edit() {
    if (!this.editing && !this._readonly) {
      this.editing = true;
      setTimeout(() => {
        this.inputControl.nativeElement.focus();
      }, 300);
    }
  }

  onBlur($event: Event) {
    this.value = UrlUtils.checkAndEvalFormula(this.value)?.toString();
    this.onSave();
    this.editing = false;
  }

  onSave() {
    if (this._originalValue != this.value) {
      this.updateModel();
      this.update.emit({ [this.fieldDef.name]: this.value?.replace(/,/g, '') });
    }
  }

  updateModel() {
    this.model[this.fieldDef.name] = this._formatNumber.transform(this.value);
    this._originalValue = this._formatNumber.transform(this.value);
    this.value = this._formatNumber.transform(this.value);
  }
}
