import { Directive, ElementRef, Input } from '@angular/core';
import { AuthService } from 'app/core';
import { Permission } from 'app/core/enums/permission';
import isBoolean from 'lodash-es/isBoolean';

@Directive({
  selector: '[appAuthorize]',
})
export class AuthorizeDirective {
  @Input('appAuthorize') set appAuthorize(value: Permission | boolean) {
    let isAuthorized = false;

    if (isBoolean(value)) {
      isAuthorized = value;
    } else {
      isAuthorized = this.authService.hasPermission(value);
    }

    if (!isAuthorized) {
      this.htmlElement.classList.add('display-none');
    } else {
      this.htmlElement.classList.remove('display-none');
    }
  }

  private get htmlElement(): HTMLElement {
    return this.elementRef.nativeElement as HTMLElement;
  }

  constructor(private readonly elementRef: ElementRef, private readonly authService: AuthService) {}
}
