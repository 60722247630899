import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Contact, Country, Tag, ContactPagination, Category } from './contacts.types';
import { Project } from 'app/modules/projects/projects.types';
import { Note } from '../../shared/modules/notes/notes.types';
import { LoanQuote } from '../projects/details/loans/loans.types';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  // Private
  private _contact: BehaviorSubject<Contact | null>;
  private _contacts: BehaviorSubject<Contact[] | null>;
  private _importContacts: BehaviorSubject<any | null>;
  private _dealAssociatedContacts: BehaviorSubject<any | null>;
  private _pagination: BehaviorSubject<ContactPagination | null>;
  private _dealAssociatedContactsPagination: BehaviorSubject<ContactPagination | null>;
  private _countries: BehaviorSubject<Country[] | null>;
  private _tags: BehaviorSubject<Tag[] | null>;
  private _categories: BehaviorSubject<Category[] | null>;
  private _accountId: string;

  constructor(private _httpClient: HttpClient) {
    this._contact = new BehaviorSubject(null);
    this._contacts = new BehaviorSubject(null);
    this._importContacts = new BehaviorSubject(null);
    this._dealAssociatedContacts = new BehaviorSubject(null);
    this._pagination = new BehaviorSubject(null);
    this._dealAssociatedContactsPagination = new BehaviorSubject(null);
    this._countries = new BehaviorSubject(null);
    this._tags = new BehaviorSubject(null);
    this._categories = new BehaviorSubject(null);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for contact
   */
  get contact$(): Observable<Contact> {
    return this._contact.asObservable();
  }

  /**
   * Getter for contacts
   */
  get contacts$(): Observable<Contact[]> {
    return this._contacts.asObservable();
  }

  /**
   * Getter for imports contacts
   */
  get importContacts$(): Observable<Contact[]> {
    return this._importContacts.asObservable();
  }

  /**
   * Getter for deal associated contacts
   */
  get dealAssociatedContacts$(): Observable<Contact[]> {
    return this._dealAssociatedContacts.asObservable();
  }

  /**
   * Getter for countries
   */
  get countries$(): Observable<Country[]> {
    return this._countries.asObservable();
  }

  /**
   * Getter for account contacts pagination
   */
  get pagination$(): Observable<ContactPagination> {
    return this._pagination.asObservable();
  }

  /**
   * Getter for deal associated contacts pagination
   */
  get dealAssociatedContactsPagination$(): Observable<ContactPagination> {
    return this._dealAssociatedContactsPagination.asObservable();
  }

  /**
   * Getter for tags
   */
  get tags$(): Observable<Tag[]> {
    return this._tags.asObservable();
  }

  /**
   * Getter for categories
   */
  get categories$(): Observable<Category[]> {
    return this._categories.asObservable();
  }

  set accountId(val: string) {
    this._accountId = val;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get contacts from account context.
   * @param page current page
   * @param size pages per view
   * @param sort sort by column_name
   * @param order contacts list order
   * @param search search query string
   * @param filters query filter object
   * @param csv generate csv report from contacts
   * @returns object { pagination: ContactPagination, contacts: Contact[] }
   */
  getContacts(
    page: number = 1,
    size: number = 20,
    sort = 'first_name',
    order: 'asc' | 'desc' | '' = 'asc',
    search: string = '',
    filters: any = {},
    csv = false,
    dontEmitValues = false,
  ): Observable<{ pagination: ContactPagination; contacts: Contact[] }> {
    const {
      name = '',
      email = '',
      phone = '',
      tags = '',
      category_name = '',
      job = '',
      company = '',
      associated_with_user_id = [],
    } = filters;
    //set the page number to 1 if 0 as can happen in pagination
    if (page < 1) {
      page = 1;
    }
    return this._httpClient
      .get<{ pagination: ContactPagination; contacts: Contact[] }>(
        `contacts${csv ? '.csv' : ''}?account_id=${this._accountId}`,
        {
          params: {
            page: '' + page,
            per_page: '' + size,
            sort: sort,
            order: order,
            search: search,
            name: name,
            email: email,
            phone: phone,
            tags: tags?.length ? tags.join(',') : '',
            category_id: category_name,
            job_title: job,
            company: company,
            associated_with_user_id: associated_with_user_id?.length ? associated_with_user_id.join(',') : '',
          },
        },
      )
      .pipe(
        map((response: any) => {
          if (csv && response) {
            return response.data;
          }
          const lastPage = Math.max(Math.ceil(response.count / size), 1);
          const begin = page * size;
          const end = Math.min(size * (page + 1), response.count);

          const pagination = {
            length: response.count,
            size: size,
            page: page - 1,
            lastPage: lastPage - 1,
            startIndex: begin,
            endIndex: end,
          };

          let contacts = response.results;
          if (!dontEmitValues) {
            this._pagination.next(pagination);
            this._contacts.next(contacts);
          }
          return {
            pagination,
            contacts,
          };
        }),
      );
  }

  /**
   * Get contacts from associated deal context.
   * @param page current page
   * @param size pages per view
   * @param sort sort by column_name
   * @param order contacts list order
   * @param search search query string
   * @param filters query filter object
   * @param projectId id from project/deal context
   * @param csv generate csv report from contacts
   * @returns object { pagination: ContactPagination, contacts: Contact[] }
   */
  getDealAssociatedContacts(
    page: number = 1,
    size: number = 20,
    sort = 'first_name',
    order: 'asc' | 'desc' | '' = 'asc',
    search: string = '',
    filters: any = {},
    projectId: string,
    csv?: boolean,
  ): Observable<{ pagination: ContactPagination; contacts: Contact[] }> {
    const {
      name = '',
      email = '',
      phone = '',
      tags = '',
      category_name = '',
      job = '',
      company = '',
      associated_with_user_id = [],
    } = filters;
    //set the page number to 1 if 0 as can happen in pagination
    if (page < 1) {
      page = 1;
    }
    return this._httpClient
      .get<{ pagination: ContactPagination; contacts: Contact[] }>(
        `contacts${csv ? '.csv' : ''}?account_id=${this._accountId}&project_id=${projectId}`,
        {
          params: {
            page: '' + page,
            per_page: '' + size,
            sort: sort,
            order: order,
            search: search,
            name: name,
            email: email,
            phone: phone,
            tags: tags?.length ? tags.join(',') : '',
            category_id: category_name,
            job_title: job,
            company: company,
            associated_with_user_id: associated_with_user_id?.length ? associated_with_user_id.join(',') : '',
          },
        },
      )
      .pipe(
        map((response: any) => {
          if (csv && response) {
            return response.data;
          }
          const lastPage = Math.max(Math.ceil(response.count / size), 1);
          const begin = page * size;
          const end = Math.min(size * (page + 1), response.count);

          const pagination = {
            length: response.count,
            size: size,
            page: page - 1,
            lastPage: lastPage - 1,
            startIndex: begin,
            endIndex: end,
          };

          let contacts = response.results;
          this._dealAssociatedContactsPagination.next(pagination);
          this._dealAssociatedContacts.next(contacts);

          return {
            pagination,
            contacts,
          };
        }),
      );
  }

  /**
   * Search contacts with given query
   * @param query
   */
  searchContacts(query: string): Observable<Contact[] | null> {
    return this._httpClient
      .get<Contact[] | null>('api/apps/contacts/search', {
        params: { query },
      })
      .pipe(
        tap((contacts) => {
          this._contacts.next(contacts);
        }),
      );
  }

  /**
   * Get contact by id
   */
  getContactById(id: string): Observable<Contact> {
    return this._httpClient.get<any>(`contacts/${id}?account_id=${this._accountId}`).pipe(
      tap((response) => {
        this._contact.next(response.contact);
        return response.contact;
      }),
    );
  }

  /**
   * Create contact
   */
  createContact(contact): Observable<Contact> {
    return this.contacts$.pipe(
      take(1),
      switchMap((contacts) =>
        this._httpClient.post<any>(`contacts?account_id=${this._accountId}`, { contact: contact }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._contacts.next([reponse.contact, ...contacts]);

            // Return the new contact
            return reponse.contact;
          }),
        ),
      ),
    );
  }

  createContacts(contactsMapping): Observable<any> {
    const uploadData = new FormData();
    for (let key of Object.keys(contactsMapping)) {
      const value = key === 'file' ? contactsMapping[key] : JSON.stringify(contactsMapping[key]);
      uploadData.append(key, value);
    }

    return this.importContacts$.pipe(
      take(1),
      switchMap(() =>
        this._httpClient.post<any>(`contacts/import?account_id=${this._accountId}`, uploadData).pipe(
          map((reponse) => {
            if (reponse?.suggested_columns) {
              for (let key of Object.keys(reponse.suggested_columns)) {
                reponse.suggested_columns[key] = reponse.suggested_columns[key][0];
              }
            }
            this._importContacts.next(reponse);

            return reponse;
          }),
        ),
      ),
    );
  }

  /**
   * Update contact
   * @param id
   * @param contact
   */
  updateContact(contact: Contact): Observable<Contact> {
    return this.contacts$.pipe(
      take(1),
      switchMap((contacts) =>
        this._httpClient
          .patch<any>(`contacts/${contact.id}?account_id=${this._accountId}`, {
            contact,
          })
          .pipe(
            map((response) => {
              let updatedContact = response.contact;

              if (contacts) {
                // Find the index of the updated contact
                const index = contacts.findIndex((item) => item.id === updatedContact.id);

                // Update the contact
                contacts[index] = updatedContact;

                // Update the contacts
                this._contacts.next(contacts);
              }
              // Return the updated contact
              return updatedContact;
            }),
            switchMap((updatedContact) =>
              this.contact$.pipe(
                take(1),
                filter((item) => item && item.id === updatedContact.id),
                tap(() => {
                  // Update the contact if it's selected
                  this._contact.next(updatedContact);

                  // Return the updated contact
                  return updatedContact;
                }),
              ),
            ),
          ),
      ),
    );
  }

  /**
   * Delete the contact
   * @param id
   */
  deleteContact(id: string): Observable<boolean> {
    return this.contacts$.pipe(
      take(1),
      switchMap((contacts) =>
        this._httpClient.delete(`contacts/${id}?account_id=${this._accountId}`).pipe(
          map((isDeleted: boolean) => {
            // Find the index of the deleted contact
            if (contacts) {
              const index = contacts.findIndex((item) => item.id === id);

              // Delete the contact
              contacts.splice(index, 1);

              // Update the contacts
              this._contacts.next(contacts);
            }

            // Return the deleted status
            return isDeleted;
          }),
        ),
      ),
    );
  }

  /**
   * Get countries
   */
  getCountries(): Observable<Country[]> {
    return this._httpClient.get<Country[]>('api/apps/contacts/countries').pipe(
      tap((countries) => {
        this._countries.next(countries);
      }),
    );
  }

  /**
   * Get tags
   */
  getTags(): Observable<Tag[]> {
    return this._httpClient.get<Tag[]>('api/apps/contacts/tags').pipe(
      tap((tags) => {
        this._tags.next(tags);
      }),
    );
  }

  /**
   * Create tag
   * @param tag
   */
  createTag(tag: Tag): Observable<Tag> {
    return this.tags$.pipe(
      take(1),
      switchMap((tags) =>
        this._httpClient.put<Tag>('api/apps/contacts/tag', { tag }).pipe(
          map((newTag) => {
            // Update the tags with the new tag
            this._tags.next([...tags, newTag]);

            // Return new tag from observable
            return newTag;
          }),
        ),
      ),
    );
  }

  /**
   * Update the tag
   * @param id
   * @param tag
   */
  updateTag(id: string, tag: Tag): Observable<Tag> {
    return this.tags$.pipe(
      take(1),
      switchMap((tags) =>
        this._httpClient
          .patch<Tag>('api/apps/contacts/tag', {
            id,
            tag,
          })
          .pipe(
            map((updatedTag) => {
              // Find the index of the updated tag
              const index = tags.findIndex((item) => item.id === id);

              // Update the tag
              tags[index] = updatedTag;

              // Update the tags
              this._tags.next(tags);

              // Return the updated tag
              return updatedTag;
            }),
          ),
      ),
    );
  }

  /**
   * Delete the tag
   * @param id
   */
  deleteTag(id: string): Observable<boolean> {
    return this.tags$.pipe(
      take(1),
      switchMap((tags) =>
        this._httpClient.delete('api/apps/contacts/tag', { params: { id } }).pipe(
          map((isDeleted: boolean) => {
            // Find the index of the deleted tag
            const index = tags.findIndex((item) => item.id === id);

            // Delete the tag
            tags.splice(index, 1);

            // Update the tags
            this._tags.next(tags);

            // Return the deleted status
            return isDeleted;
          }),
          filter((isDeleted) => isDeleted),
          switchMap((isDeleted) =>
            this.contacts$.pipe(
              take(1),
              map((contacts) => {
                // Iterate through the contacts
                contacts.forEach((contact) => {
                  const tagIndex = contact.tags.findIndex((tag) => tag === id);

                  // If the contact has the tag, remove it
                  if (tagIndex > -1) {
                    contact.tags.splice(tagIndex, 1);
                  }
                });

                // Return the deleted status
                return isDeleted;
              }),
            ),
          ),
        ),
      ),
    );
  }

  /**
   * Update the avatar of the given contact
   * @param id
   * @param avatar
   */
  uploadAvatar(id: string, avatar: File): Observable<Contact> {
    return this.contacts$.pipe(
      take(1),
      switchMap((contacts) =>
        this._httpClient
          .post<Contact>(
            'api/apps/contacts/avatar',
            {
              id,
              avatar,
            },
            {
              headers: {
                'Content-Type': avatar.type,
              },
            },
          )
          .pipe(
            map((updatedContact) => {
              // Find the index of the updated contact
              const index = contacts.findIndex((item) => item.id === id);

              // Update the contact
              contacts[index] = updatedContact;

              // Update the contacts
              this._contacts.next(contacts);

              // Return the updated contact
              return updatedContact;
            }),
            switchMap((updatedContact) =>
              this.contact$.pipe(
                take(1),
                filter((item) => item && item.id === id),
                tap(() => {
                  // Update the contact if it's selected
                  this._contact.next(updatedContact);

                  // Return the updated contact
                  return updatedContact;
                }),
              ),
            ),
          ),
      ),
    );
  }

  /**
   * Get Projects related to a contact
   */
  getProjects(contactId: string): Observable<Project[]> {
    return this._httpClient.get<any>(`contacts/${contactId}/projects?account_id=${this._accountId}`).pipe(
      map((response) => {
        return response.projects;
      }),
    );
  }

  /**
   * Get Notes related to a contact
   */
  getNotes(contactId: string): Observable<Note[]> {
    return this._httpClient.get<any>(`contacts/${contactId}/notes?account_id=${this._accountId}`).pipe(
      map((response) => {
        return response.notes;
      }),
    );
  }

  getLoanQuotes(contactId: string): Observable<LoanQuote[]> {
    return this._httpClient.get<any>(`contacts/${contactId}/loan_quotes?account_id=${this._accountId}`).pipe(
      map((response) => {
        return response.loan_quotes;
      }),
    );
  }

  createNote(note: Note): Observable<Note> {
    return this._httpClient.post<any>(`notes?account_id=${this._accountId}`, { note: note }).pipe(
      map((reponse) => {
        // Return the new contact
        return reponse.note;
      }),
    );
  }

  updateNote(note: Note): Observable<Note> {
    return this._httpClient.put<any>(`notes/${note.id}?account_id=${this._accountId}`, { note: note }).pipe(
      map((reponse) => {
        // Return the new contact
        return reponse.note;
      }),
    );
  }

  deleteNote(id: number): Observable<any> {
    return this._httpClient.delete<any>(`notes/${id}?account_id=${this._accountId}`).pipe(
      map((reponse) => {
        // Return the deleted status
        return reponse.note;
      }),
    );
  }

  /**
   * Send message to contact
   */
  sendMessage(contactId: string, subject: string, body: string): Observable<Contact> {
    return this._httpClient
      .post<any>(`contacts/${contactId}/send_message?account_id=${this._accountId}`, {
        message: body,
        subject: subject,
      })
      .pipe(
        map((reponse) => {
          // Return the contact
          return reponse.contact;
        }),
      );
  }
}
