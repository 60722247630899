import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Project } from 'app/modules/projects/projects.types';

@Component({
  selector: 'project-dialog-overview-panel',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectDialogOverviewPanelComponent {
  @Input() project: Project;
  @Output() onUpdate = new EventEmitter<{ [key: string]: string }>();

  onBlur(): void {
    this.onUpdate.emit({ title: this.project.title });
  }
}
