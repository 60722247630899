import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Dashboard } from './dashboard.types';
import { AccountService } from 'app/core';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private _dashboard: BehaviorSubject<Dashboard | null>;
  private _dashboards: BehaviorSubject<Dashboard[] | null>;
  private _accountId: string;

  constructor(private _httpClient: HttpClient, private _accountService: AccountService) {
    this._dashboard = new BehaviorSubject(null);
    this._dashboards = new BehaviorSubject(null);

    this._accountService.currentAccount.subscribe((accountData) => {
      this._accountId = accountData.id;
    });
  }

  get dashboard$(): Observable<Dashboard> {
    return this._dashboard.asObservable();
  }

  get dashboards$(): Observable<Dashboard[]> {
    return this._dashboards.asObservable();
  }

  set accountId(val: string) {
    this._accountId = val;
  }

  getDashboards(): Observable<Dashboard[] | null> {
    return this._httpClient.get<any>(`dashboards?account_id=${this._accountId}`).pipe(
      map((response: any) => {
        let dashboards = response.dashboards;
        this._dashboards.next(dashboards);

        return dashboards;
      }),
    );
  }

  getDashboardById(id: number): Observable<Dashboard> {
    return this._httpClient.get<any>(`dashboards/${id}?account_id=${this._accountId}`).pipe(
      map((response) => {
        this._dashboard.next(response.dashboard);
        return response.dashboard;
      }),
    );
  }

  createDashboard(dashboard): Observable<Dashboard> {
    return this.dashboards$.pipe(
      take(1),
      switchMap((dashboards) =>
        this._httpClient.post<any>(`dashboards?account_id=${this._accountId}`, { dashboard: dashboard }).pipe(
          map((reponse) => {
            this._dashboards.next([reponse.dashboard, ...dashboards]);

            return reponse.dashboard;
          }),
        ),
      ),
    );
  }

  updateDashboard(dashboard: Dashboard): Observable<Dashboard> {
    return this.dashboards$.pipe(
      take(1),
      switchMap((reportLayouts) =>
        this._httpClient
          .patch<any>(`dashboards/${dashboard.id}?account_id=${this._accountId}`, {
            dashboard: dashboard,
          })
          .pipe(
            map((response) => {
              this._dashboard.next(response.dashboard);

              return response.dashboard;
            }),
          ),
      ),
    );
  }

  duplicateDashboard(dashboard: Dashboard): Observable<Dashboard> {
    return this._httpClient
      .put<any>(`report_layouts/${dashboard.id}/duplicate?account_id=${this._accountId}`, { dashboard: dashboard })
      .pipe(
        map((response) => {
          return response.dashboard;
        }),
      );
  }

  deleteDashboard(id: number): Observable<any> {
    return this._httpClient.delete<any>(`dashboards/${id}?account_id=${this._accountId}`).pipe(
      map((response) => {
        return response.dashboard;
      }),
    );
  }

  getDashboardData(id: number): Observable<any> {
    return this._httpClient.get<any>(`dashboards/${id}/chart_data?account_id=${this._accountId}`).pipe(
      map((response) => {
        return response.chart_data;
      }),
    );
  }
}
