export enum FeatureFlag {
  AUTOMATIONS = 'automations',
  PROPERTIES = 'properties',
  DISPOSITIONS_TAB = 'dispositions_tab',
  LOAN_QUOTES = 'loan_quotes',
  LEASES = 'leases',
  KANBAN = 'kanban',
  FACILITY_REPORTS = 'facility_reports',
  PIPELINE_MAP = 'pipeline_map',
  CUSTOM_OBJECTS = 'custom_objects',
  REPORTS_TAB = 'reports_tab',
}
