<p class="text-md font-medium mt-3 pr-3">Files</p>
<mat-divider
  role="separator"
  class="mat-divider mt-1 mb-1 mat-divider-horizontal"
  aria-orientation="horizontal"
></mat-divider>

<div class="flex flex-col overflow-auto mb-3">
  <div class="files-table">
    <div class="body">
      <div class="row" *ngFor="let file of files">
        <div class="col">
          <a (click)="downloadDocument(file)"><mat-icon class="icon" [svgIcon]="'insert_drive_file'"></mat-icon></a>
        </div>
        <div class="col">
          <a (click)="downloadDocument(file)">{{ file.name }}</a>
        </div>
        <div class="col">{{ file.created_at | date: 'MMM dd, y' }}</div>
        <div class="col">
          <button mat-icon-button class="delete-file" (click)="deleteFile(file)">
            <mat-icon [svgIcon]="'delete'"></mat-icon>
          </button>
        </div>
      </div>
      <div class="row">
        <div>
          <button class="ml-2 xs:hidden add-task" mat-flat-button (click)="fileInput.click()">
            <mat-icon class="icon-size-20 mr-1" [svgIcon]="'backup'"></mat-icon>
            <input style="display: none" type="file" (change)="onFileChanged($event)" #fileInput />
            Upload
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
