<div class="header">
  <div class="title">{{ project.title }}</div>
  <div>
    <button class="close-button" [tabIndex]="-1" (click)="navigateToFullProject()" mat-icon-button>
      <mat-icon [svgIcon]="'open_in_new'"></mat-icon>
    </button>
    <button class="close-button" mat-icon-button (click)="discard()" [tabIndex]="-1">
      <mat-icon [svgIcon]="'close'"></mat-icon>
    </button>
  </div>
</div>
<div class="flex flex-auto">
  <div class="flex flex-col w-3/4 border-right p-2">
    <project-dialog-overview-panel [project]="project" (onUpdate)="update($event)"></project-dialog-overview-panel>
    <project-dialog-properties-panel
      [project]="project"
      [account]="account"
      (onUpdate)="update($event)"
    ></project-dialog-properties-panel>
    <project-dialog-tasks-panel
      [project]="project"
      [account]="account"
      (onUpdate)="update($event)"
      [options]="options"
    ></project-dialog-tasks-panel>

    <project-dialog-files-panel
      [project]="project"
      [account]="account"
      (onUpdate)="update($event)"
    ></project-dialog-files-panel>
    <project-dialog-comments-panel
      [project]="project"
      [account]="account"
      (onUpdate)="update($event)"
    ></project-dialog-comments-panel>
  </div>
  <div class="flex flex-col w-1/4 side-panel">
    <div class="flex flex-col gt-xs:flex-row">
      <ng-container *ngIf="lookupField('project_stage_id') as fieldDef">
        <app-form-field
          [fieldDef]="fieldDef"
          [model]="project"
          (onUpdate)="update($event)"
          [data]="options"
        ></app-form-field>
      </ng-container>
    </div>
    <div class="flex flex-col gt-xs:flex-row" *ngFor="let column of displayColumns">
      <ng-container *ngIf="lookupField(column) as fieldDef">
        <app-form-field
          [fieldDef]="fieldDef"
          [model]="project"
          (onUpdate)="update($event)"
          [data]="options"
        ></app-form-field>
      </ng-container>
    </div>
  </div>
</div>
