import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AccountService, AuthService } from 'app/core';
import { getSubdomain } from './shared/Utils/common.utils';
import { Account } from './core/models';
import { Permission } from './core/enums/permission';
import { Store } from '@ngxs/store';
import { SetAccount } from './state/account/actions';

@Injectable({
  providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private _accountService: AccountService,
    private readonly authService: AuthService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Load messages
   *
   * @private
   */
  private _loadMessages(): Observable<any> {
    return this._httpClient.get('api/common/messages');
  }

  /**
   * Load navigation data
   *
   * @private
   */
  private _loadNavigation(): Observable<any> {
    return this._httpClient.get('api/common/navigation');
  }

  /**
   * Load notifications
   *
   * @private
   */
  private _loadNotifications(): Observable<any> {
    return this._httpClient.get('user/notifications');
  }

  /**
   * Load shortcuts
   *
   * @private
   */
  private _loadShortcuts(): Observable<any> {
    return this._httpClient.get('api/common/shortcuts');
  }

  /**
   * Load user
   *
   * @private
   */
  private _loadUser(): Observable<any> {
    const subdomain = getSubdomain();
    if (subdomain) {
      return this._accountService.getAccount(subdomain).pipe(
        switchMap((account: Account) => this._accountService.getUserPermissionByAccountId(Number(account.id))),
        tap((permissions) => this.authService.setPermissions(permissions.map(({ name }) => name as Permission))),
        switchMap(() => this._httpClient.get('/user')),
      );
    }

    return this._httpClient.get('/user');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin([
      // Messages
      this._loadMessages(),

      // Navigation data
      this._loadNavigation(),

      // Notifications
      this._loadNotifications(),

      // Shortcuts
      this._loadShortcuts(),

      // User
      this._loadUser(),
    ]).pipe(
      map((data) => {
        return {
          messages: data[0].messages,
          navigation: {
            compact: data[1].compact,
            default: data[1].default,
            futuristic: data[1].futuristic,
            horizontal: data[1].horizontal,
          },
          notifications: data[2].notifications,
          shortcuts: data[3].shortcuts,
          user: data[4].user,
        };
      }),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class AccountResolver implements Resolve<Account> {
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private _accountService: AccountService,
    private readonly store: Store,
  ) {}

  private _loadAccount(): Observable<Account> {
    const subdomain = getSubdomain();
    if (subdomain) {
      return this._accountService.loadAccount(subdomain);
    } else {
      return null;
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Account> {
    return this._loadAccount().pipe(tap((account) => this.store.dispatch(new SetAccount(account))));
  }
}
