<!-- Navigation -->
<treo-vertical-navigation
  class="bg-cool-gray-900 theme-dark"
  *ngIf="isScreenSmall"
  [appearance]="'classic'"
  [mode]="'over'"
  [name]="'mainNavigation'"
  [navigation]="data.navigation.default"
  [opened]="false"
>
  <div treoVerticalNavigationContentHeader>
    <div class="logo">
      <img src="assets/images/logo/ts-logo.png" />
    </div>
  </div>
</treo-vertical-navigation>

<!-- Wrapper -->
<div class="wrapper">
  <!-- Header -->
  <div class="header">
    <!-- Logo -->
    <div class="logo" *ngIf="!isScreenSmall">
      <img class="logo-text" src="assets/images/logo/ts-logo.png" />
      <img class="logo-text-on-dark" src="assets/images/logo/logo-text-on-dark.svg" />
      <img class="logo-base" src="assets/images/logo/ts-logo.png" />
    </div>
    <ng-container *ngIf="account.id">
      <!-- Horizontal navigation -->
      <treo-horizontal-navigation
        *ngIf="!isScreenSmall"
        [name]="'mainNavigation'"
        [navigation]="horizontalNavigation"
      ></treo-horizontal-navigation>

      <!-- Navigation toggle button -->
      <button
        class="navigation-toggle-button"
        *ngIf="isScreenSmall"
        mat-icon-button
        (click)="toggleNavigation('mainNavigation')"
      >
        <mat-icon [svgIcon]="'menu'"></mat-icon>
      </button>

      <!-- Spacer -->
      <div class="spacer"></div>

      <!-- Search -->

      <search [appearance]="'bar'" [account]="account"></search>

      <!-- Notifications -->
      <notifications [notifications]="data.notifications"></notifications>

      <calculator></calculator>
      <!--<shortcuts [shortcuts]="data.shortcuts"></shortcuts>
            <messages [messages]="data.messages"></messages>-->

      <!-- User -->
      <user [user]="data.user"></user>
    </ng-container>
  </div>

  <!-- Content -->
  <div class="content">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer
    <div class="footer">
        TermSheet &copy; {{currentYear}}
    </div>
     -->
</div>
