import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { noop } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Project, Pipeline } from 'app/modules/projects/projects.types';
import { ProjectsService } from 'app/modules/projects/projects.service';
import { Account, FieldDef, ProjectStage } from 'app/core/models/account.types';
import { ProjectStatusRequiredFieldsComponent } from '../../modals/project-status-required-fields/project-status-required-fields.component';
import { Dictionary } from 'app/shared/models/dictionary';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'project-modal-dialog',
  templateUrl: './project-modal-dialog.component.html',
  styleUrls: ['./project-modal-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectModalDialogComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  account: Account;
  project: Project;
  pipeline: Pipeline;
  displayColumns: string[];
  fieldDefs: FieldDef[] = [];
  options: any[];
  projectStages: ProjectStage[];
  panels: any[];

  private _dashboardDefinition: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<ProjectModalDialogComponent>,
    private _projectsService: ProjectsService,
    private _matDialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    this.project = this.data.project;
    this.account = this.data.account;
    this.displayColumns = (this.data.displayColumns || []).filter((fieldName: string) => fieldName !== 'title');
    this.fieldDefs = this.data.fieldDefs;
    this.options = this.data.options;
    this.projectStages = this.data.options.options.project_stages.filter((option) => !option.hidden);
    this.pipeline = this.data.pipeline;
  }

  navigateToFullProject(): void {
    this.matDialogRef.close({ redirect: ['projects', this.project.pipeline_id, this.project.id] });
  }

  discard(): void {
    this.matDialogRef.close();
  }

  update(params: Dictionary<any>): void {
    const options = { ...params, id: Number(this.project?.id) };

    this._projectsService.saveProject(options).pipe(untilComponentDestroyed(this)).subscribe(noop);

    const stage = this.projectStages.filter((option) => option.id === parseInt(params.project_stage_id))[0];

    if (stage?.required_fields) {
      this._matDialog.open(ProjectStatusRequiredFieldsComponent, {
        panelClass: 'mailbox-compose-dialog',
        data: { data: this.data, stage, project: this.project },
      });
    }
  }

  lookupField(name: string): FieldDef {
    return this.fieldDefs.find((fieldDef: FieldDef) => fieldDef.name == name);
  }

  onEditPanelDef(event: any): void {
    for (const col in this.panels) {
      for (const row in this.panels[col]) {
        const panel = this.panels[col][row];
        if (panel.id == event.id) {
          this.panels[col][row] = event;
        }
      }
    }
    this._dashboardDefinition.panels = this.panels;
    this.pipeline.dashboard_definition = JSON.stringify(this._dashboardDefinition);
    this._projectsService.updatePipeline(this.pipeline).subscribe();
  }
}
