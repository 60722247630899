import { Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TreoMediaWatcherService } from '@treo/services/media-watcher';
import { TreoNavigationService } from '@treo/components/navigation';
import { Account } from 'app/core/models/account.types';
import { AccountService, AuthService } from 'app/core';
import { TreoNavigationItem } from '@treo/components/navigation';
import { UserService } from 'app/layout/common/user/user.service';
import { Project } from 'app/modules/projects/projects.types';
import { Contact } from 'app/modules/contacts/contacts.types';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { Dashboard } from 'app/modules/dashboard/dashboard.types';
import { ProjectsService } from 'app/modules/projects/projects.service';
import { Pipeline } from 'app/modules/projects/projects.types';
import { FeatureFlag } from 'app/core/enums/feature-flag';

@Component({
  selector: 'modern-layout',
  templateUrl: './modern.component.html',
  styleUrls: ['./modern.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModernLayoutComponent implements OnInit, OnDestroy {
  data: any;
  isScreenSmall: boolean;

  @HostBinding('class.fixed-header')
  fixedHeader: boolean;

  @HostBinding('class.fixed-footer')
  fixedFooter: boolean;

  public horizontalNavigation: TreoNavigationItem[];

  // Private
  private _unsubscribeAll: Subject<void>;

  account: Account;
  userRole: string;
  pipelines: Pipeline[];
  projects: Project[];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _treoMediaWatcherService: TreoMediaWatcherService,
    private _treoNavigationService: TreoNavigationService,
    private _accountService: AccountService,
    private _userService: UserService,
    private _dashboardService: DashboardService,
    private _projectsService: ProjectsService,
    private readonly authService: AuthService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Set the defaults
    this.fixedHeader = false;
    this.fixedFooter = false;

    this._accountService.currentAccount.subscribe((accountData) => {
      this.account = accountData;
      this._dashboardService.accountId = this.account.id;
    });
  }

  get currentYear(): number {
    return new Date().getFullYear();
  }

  ngOnInit(): void {
    // Subscribe to the resolved route data
    this._activatedRoute.data.subscribe((data: Data) => {
      this.data = data.initialData;
    });

    this._generateMenuLinks();

    this._userService.role$.pipe(takeUntil(this._unsubscribeAll)).subscribe((role: string) => {
      if (role) {
        this.userRole = role;
        this._generateMenuLinks();
      }
    });

    this._userService.recentProjects$.pipe(takeUntil(this._unsubscribeAll)).subscribe((projects: Project[]) => {
      this.projects = projects;
      this._updateRecentProjects();
    });

    this._userService.recentContacts$.pipe(takeUntil(this._unsubscribeAll)).subscribe((contacts: Contact[]) => {
      if (contacts) {
        this._updateRecentContacts(contacts);
      }
    });

    this._userService.recentProperties$.pipe(takeUntil(this._unsubscribeAll)).subscribe((properties: Contact[]) => {
      if (properties) {
        this._updateRecentProperties(properties);
      }
    });

    this._projectsService.pipelines$.pipe(takeUntil(this._unsubscribeAll)).subscribe((pipelines) => {
      if (pipelines) {
        this.pipelines = pipelines.filter((pipeline) => pipeline?.pipeline_type != 'dynamic');
        this._updateRecentProjects();
      }
    });

    this._dashboardService.dashboards$.pipe(takeUntil(this._unsubscribeAll)).subscribe((dashboards: Dashboard[]) => {
      if (dashboards) {
        this._updateDashboards(dashboards);
      }
    });

    if (this.account.id) {
      this._userService.getRecentProjects(this.account.id).subscribe();
      this._userService.getRecentContacts(this.account.id).subscribe();
      this._userService.getRecentProperties(this.account.id).subscribe();
    }

    if (this.account && Object.keys(this.account).length) {
      this._dashboardService.getDashboards().subscribe();
    }

    this._generateMenuLinks();
    // Subscribe to media changes
    this._treoMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the breakpoint is 'lt-md'
        this.isScreenSmall = matchingAliases.includes('lt-md');
      });
  }

  _generateMenuLinks(): void {
    this.horizontalNavigation = [
      {
        id: 'dashboard',
        title: 'Dashboard',
        link: '/dashboard',
        icon: 'heroicons_outline:home',
        type: 'group',
      },
      {
        id: 'projects',
        title: 'Deals',
        link: '/projects',
        icon: 'heroicons_outline:cash',
        type: 'group',
      },
      {
        id: 'contacts',
        title: 'Contacts',
        link: '/contacts',
        icon: 'heroicons_outline:user-group',
        type: 'group',
      },
      {
        id: 'tasks',
        title: 'Tasks',
        link: '/tasks',
        icon: 'heroicons_outline:clipboard-list',
        type: 'group',
        children: [
          {
            id: 'tasks.global',
            title: 'Global',
            link: '/tasks/global',
            icon: 'heroicons_outline:clipboard-list',
            type: 'basic',
            exactMatch: false,
          },
          {
            id: 'tasks.aggregated',
            title: 'Aggregated',
            link: '/tasks/aggregated',
            icon: 'heroicons_outline:clipboard-list',
            type: 'basic',
            exactMatch: true,
          },
        ],
      },
      {
        id: 'properties',
        title: 'Properties',
        link: '/properties',
        icon: 'heroicons_outline:office-building',
        type: 'group',
        hidden: () => !this.account.features.includes(FeatureFlag.PROPERTIES),
      },
      {
        id: 'research',
        title: 'Maps',
        link: '/research',
        icon: 'heroicons_outline:globe',
        type: 'basic',
      },
      {
        id: 'reporting',
        title: 'Reports',
        link: '/reporting',
        icon: 'heroicons_outline:document-report',
        type: 'basic',
        hidden: () => !this.account.features.includes(FeatureFlag.REPORTS_TAB),
      },
    ];

    if (this.authService.isGuest()) {
      this.horizontalNavigation = [
        {
          id: 'projects',
          title: 'Deals',
          icon: 'heroicons_outline:office-building',
          type: 'group',
        },
      ];
    }
  }

  _updateRecentProjects(): void {
    let children: TreoNavigationItem[] = [
      {
        id: 'deals.all',
        title: 'All Views',
        link: '/projects',
        icon: 'heroicons_outline:cash',
        type: 'basic',
        exactMatch: true,
      },
    ];

    const index = this.horizontalNavigation.findIndex((item) => item.id === 'projects');
    if (this.pipelines) {
      let pipelineMenu: TreoNavigationItem = {
        id: 'pipelines',
        title: 'Views',
        icon: 'heroicons_outline:view-list',
        type: 'group',
        children: [],
      };

      this.pipelines.forEach((pipeline) => {
        let pipelineChild: TreoNavigationItem = {
          id: pipeline.id.toString(),
          title: pipeline.name,
          type: 'group',
          icon: 'heroicons_outline:view-list',
          children: [
            {
              id: 'deals-' + pipeline.id.toString(),
              title: 'View Pipeline Deals',
              type: 'basic',
              icon: 'heroicons_outline:cash',
              link: `/projects/${pipeline.id}`,
              exactMatch: true,
            },
          ],
        };

        if (this.projects) {
          let pipelineDeals = this.projects.filter((proj) => proj.pipeline_id == pipeline.id);

          pipelineDeals.forEach((project) => {
            let child: TreoNavigationItem = {
              id: project.id.toString(),
              title: project.title,
              type: 'basic',
              icon: 'heroicons_outline:cash',
              link: `/projects/${project.pipeline_id}/${project.id}`,
              exactMatch: true,
            };
            pipelineChild.children.push(child);
          });
          pipelineMenu.children.push(pipelineChild);
        }
      });
      children.push(pipelineMenu);
    }

    if (this.projects) {
      let recentProjectsMenu: TreoNavigationItem = {
        id: 'projects',
        title: 'Recent Deals',
        icon: 'heroicons_outline:clock',
        type: 'group',
        children: [],
      };
      this.projects.forEach((project) => {
        let child: TreoNavigationItem = {
          id: project.id.toString(),
          title: project.title,
          type: 'basic',
          icon: 'heroicons_outline:clock',
          link: `/projects/${project.pipeline_id}/${project.id}`,
          exactMatch: true,
        };
        recentProjectsMenu.children.push(child);
      });
      children.push(recentProjectsMenu);
    }
    if (index) {
      let menuItem = this.horizontalNavigation[index];
      menuItem.children = children;
      this.horizontalNavigation[index] = menuItem;
    }
  }

  _updateRecentContacts(contacts): void {
    const index = this.horizontalNavigation.findIndex((item) => item.id === 'contacts');
    let children: TreoNavigationItem[] = [
      {
        id: 'contacts.all',
        title: 'All Contacts',
        link: '/contacts',
        icon: 'heroicons_outline:user-group',
        type: 'basic',
        exactMatch: true,
      },
    ];

    if (index && contacts) {
      let menuItem = this.horizontalNavigation[index];
      if (menuItem) {
        contacts.forEach((contact) => {
          let child: TreoNavigationItem = {
            id: contact.id.toString(),
            title: `${contact.first_name} ${contact.last_name}`,
            type: 'basic',
            icon: 'heroicons_outline:user',
            link: `/contacts/${contact.id}`,
            exactMatch: true,
          };
          children.push(child);
        });
        menuItem.children = children;
        this.horizontalNavigation[index] = menuItem;
      }
    }
  }

  _updateRecentProperties(properties): void {
    const index = this.horizontalNavigation.findIndex((item) => item.id === 'properties');
    let children: TreoNavigationItem[] = [
      {
        id: 'properties.all',
        title: 'All Properties',
        link: '/properties',
        icon: 'heroicons_outline:office-building',
        type: 'basic',
        exactMatch: true,
      },
    ];

    if (index && properties) {
      let menuItem = this.horizontalNavigation[index];
      if (menuItem) {
        properties.forEach((property) => {
          let child: TreoNavigationItem = {
            id: property.id.toString(),
            title: `${property?.name || property?.address_string || 'Untitled'}`,
            type: 'basic',
            icon: 'heroicons_outline:clock',
            link: `/properties/${property.id}`,
            exactMatch: true,
          };
          children.push(child);
        });
        menuItem.children = children;
        this.horizontalNavigation[index] = menuItem;
      }
    }
  }

  _updateDashboards(dashboards): void {
    const index = this.horizontalNavigation.findIndex((item) => item.id === 'dashboard');

    let children: TreoNavigationItem[] = [
      {
        id: 'dashboards.all',
        title: 'All Dashboards',
        link: '/dashboard',
        icon: 'heroicons_outline:home',
        type: 'basic',
        exactMatch: true,
      },
    ];

    if (index > -1 && dashboards) {
      let menuItem = this.horizontalNavigation[index];
      if (menuItem) {
        dashboards.forEach((dashboard) => {
          let child: TreoNavigationItem = {
            id: dashboard.id.toString(),
            title: dashboard.name,
            type: 'basic',
            icon: 'heroicons_outline:sparkles',
            link: `/dashboard/${dashboard.id}`,
            exactMatch: true,
          };
          children.push(child);
        });
        menuItem.children = children;
        this.horizontalNavigation[index] = menuItem;
      }
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleNavigation(key): void {
    // Get the navigation
    const navigation = this._treoNavigationService.getComponent(key);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }
}
