<div class="tags">
  <mat-form-field #lala class="flex-auto gt-xs:pr-3">
    <mat-label>
      {{ fieldDef.label }} <i class="fa fa-info-circle pointer-events-auto" [matTooltip]="showTip" *ngIf="showTip"> </i
    ></mat-label>
    <div class="w-full" (click)="edit()" (focus)="edit()">
      <input
        #inputControl
        matInput
        currencyMask
        [placeholder]="fieldDef.label"
        [(ngModel)]="value"
        (keyup.enter)="onBlur($event)"
        (blur)="onBlur($event)"
        [options]="currencyMaskOptions"
        [disabled]="isDisabled"
      />
    </div>
  </mat-form-field>
</div>
