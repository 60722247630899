import { Component, Inject, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AccountService } from 'app/core';
import { Account } from 'app/core/models/account.types';
import { FileResource, Document } from '../folders.types';
import { FoldersTabService } from 'app/shared/modules/folders/folders.service';

@Component({
  selector: 'folders-tab-view-file',
  templateUrl: './view-file.component.html',
  styleUrls: ['./view-file.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FoldersTabViewFileComponent extends OnDestroyMixin implements OnInit {
  // Is a WOPI file being edited?
  editWopiFile: boolean;
  // Is the right sidebar expanded?
  expandRightSidebar: boolean;
  selectedFile: FileResource;
  selectedDocument: Document;
  documentVersionsDataSource: MatTableDataSource<any>;
  documentVersionsColumns: string[];
  account: Account;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<FoldersTabViewFileComponent>,
    private _foldersTabService: FoldersTabService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _accountService: AccountService,
  ) {
    super();
    this.documentVersionsDataSource = new MatTableDataSource();
    this.documentVersionsColumns = ['version', 'created_by', 'created_at', 'actions'];

    this._accountService.currentAccount.pipe(untilComponentDestroyed(this)).subscribe((accountData) => {
      this.account = accountData;
    });
  }

  ngOnInit(): void {
    this.editWopiFile = false;
    this.expandRightSidebar = true;
    this.selectedFile = this.data.fileResource;
    this.setSelectedDocument();
    this.loadFile();
  }

  setSelectedDocument(document?): void {
    if (document) {
      this.selectedDocument = document;
    } else if (this.selectedFile.documents.length > 0) {
      this.selectedDocument = this.selectedFile.documents[0];
    }
  }

  loadFile(): void {
    this._foldersTabService
      .getFile(this.selectedFile.folder_resource_id, this.selectedFile.id)
      .pipe(untilComponentDestroyed(this))
      .subscribe((fileResource) => {
        if (fileResource) {
          this.documentVersionsDataSource.data = fileResource.documents.sort((a, b) => (a.id < b.id ? 1 : -1));
        }

        this._changeDetectorRef.markForCheck();
      });
  }

  toggleWopiFile(): void {
    this.editWopiFile = !this.editWopiFile;

    if (this.editWopiFile) {
      // TODO: This is probably not the best way to accomplish posting to the form.
      const form = window.document.getElementById('officeForm') as HTMLFormElement;

      if (form) {
        form.submit();
      }
    }
  }

  downloadDocument(document): void {
    window.open(document.original_url, '_blank');
  }

  deleteDocument(document): void {
    if (confirm('Are you sure?')) {
      this._foldersTabService
        .deleteDocument(this.selectedFile.folder_resource_id, this.selectedFile.id, document.id)
        .pipe(untilComponentDestroyed(this))
        .subscribe(() => {
          this.loadFile();
        });
    }
  }

  deleteFile(): void {
    if (confirm('Are you sure?')) {
      this._foldersTabService
        .deleteFile(this.selectedFile.folder_resource_id, this.selectedFile.id)
        .pipe(untilComponentDestroyed(this))
        .subscribe(() => {
          this.matDialogRef.close();
        });
    }
  }

  onFileChanged(event) {
    let document = event.target.files[0];
    this._foldersTabService
      .uploadDocument(this.selectedFile.folder_resource_id, this.selectedFile.id, document)
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.loadFile();
      });
  }

  openOnline(document) {
    window.open(document?.links?.edit?.link?.webUrl, '_blank');
  }

  /**
   * Discard the modal
   */
  discard(): void {
    this.matDialogRef.close();
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
