<mat-form-field class="flex-auto gt-xs:pr-3 url-wrapper">
  <mat-label
    >{{ fieldDef.label }} <i class="fa fa-info-circle pointer-events-auto" [matTooltip]="showTip" *ngIf="showTip"> </i
  ></mat-label>
  <div class="w-full flex flex-row">
    <input
      #inputControl
      matInput
      [placeholder]="fieldDef.label"
      [(ngModel)]="value"
      (keyup.enter)="onBlur($event)"
      (blur)="onBlur($event)"
      [disabled]="isDisabled"
      [ngClass]="{ underline: value }"
    />
    <button
      mat-button
      matSuffix
      mat-icon-button
      aria-label="Expand"
      (click)="visitUrl($event)"
      class="remove-email-field-url"
    >
      <mat-icon>open_in_new</mat-icon>
    </button>
    <button
      mat-button
      matSuffix
      mat-icon-button
      aria-label="Expand"
      (click)="edit()"
      class="remove-email-field-url"
      [disabled]="isDisabled"
    >
      <mat-icon>edit</mat-icon>
    </button>
  </div>
</mat-form-field>
