import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, Inject, OnInit, ViewEncapsulation, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { noop, Observable } from 'rxjs';
import { debounceTime, take, distinctUntilChanged, filter, switchMap, map, delay, finalize } from 'rxjs/operators';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { pick } from 'lodash-es';
import { AccountService } from 'app/core';
import { RelatedToType } from 'app/shared/enums';
import { Account } from 'app/core/models/account.types';
import { EMAIL_PATTERN } from 'app/shared/Utils/common.utils';
import { UserService } from 'app/layout/common/user/user.service';
import { ProjectsService } from 'app/modules/projects/projects.service';
import { ContactsService } from 'app/modules/contacts/contacts.service';
import { Contact, Category } from 'app/modules/contacts/contacts.types';
import { Project } from '../../projects.types';

declare interface DialogDataInterface {
  project_id?: number;
  contactId?: string;
  related_to_type?: RelatedToType;
  byPass?: boolean;
  object?: any;
  fromProject?: boolean;
  project?: Project;
  paginator?: MatPaginator;
  pageSize?: number;
  sort?: MatSort;
  queryFilters?: any;
}
@Component({
  selector: 'project-add-contact',
  templateUrl: './project-add-contact.component.html',
  styleUrls: ['./project-add-contact.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectAddContactComponent extends OnDestroyMixin implements OnInit {
  public contactForm: UntypedFormGroup;
  public categories: Category[];
  public contacts: Contact[];
  public contactsCount: number = 0;
  public account: Account;
  public contactId: string | null;
  public related_to_type: RelatedToType;
  public results: any[] | null;
  public companyResults: any[] | null;
  public uploadedFile: any;
  public error = null;
  separatorKeysCodes = [ENTER, COMMA];
  tagsSearchResult$: Observable<string[]>;
  public isLoading: boolean;

  @ViewChild('tagsInput') tagsInput: ElementRef<HTMLInputElement>;

  get tagsControl(): AbstractControl {
    return this.contactForm.get('tags');
  }

  get tags(): string[] {
    return (this.tagsControl.value || []) as string[];
  }

  get tagsInputControl(): AbstractControl {
    return this.contactForm.get('tagsInput');
  }

  public get isSaveBtnDisabled(): boolean {
    return this.isLoading || this.contactForm.pristine || this.contactForm.invalid;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: DialogDataInterface,
    public readonly matDialogRef: MatDialogRef<ProjectAddContactComponent>,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _projectsService: ProjectsService,
    private readonly _contactsService: ContactsService,
    private readonly _userService: UserService,
    private readonly _accountService: AccountService,
    private readonly httpClient: HttpClient,
  ) {
    super();
    this._accountService.currentAccount.pipe(untilComponentDestroyed(this)).subscribe((accountData) => {
      this.account = accountData;
      this._contactsService.accountId = `${this.account.id}`;
    });
  }

  ngOnInit(): void {
    this.contactForm = this._formBuilder.group({
      id: [''],
      category_id: ['', [Validators.required]],
      name: ['', [Validators.required]],
      company_name: [''],
      category_name: [''],
      email: ['', [Validators.email, Validators.pattern(EMAIL_PATTERN)]],
      phone: [''],
      tags: [[]],
      tagsInput: [''],
    });
    this.contactId = this.data.contactId;
    this.related_to_type = this.data.related_to_type;

    if (this.data.byPass && this.related_to_type === 'Project' && this.data?.object && this.data?.object?.id) {
      this._projectsService.getContactAssociations(this.data?.object).pipe(untilComponentDestroyed(this)).subscribe();
    } else if (this.contactId) {
      this._contactsService.getContactById(this.contactId).pipe(untilComponentDestroyed(this)).subscribe();
    }

    this._projectsService.contacts$.pipe(untilComponentDestroyed(this)).subscribe((contacts: Contact[]) => {
      if (contacts) {
        this.contacts = contacts;
        this.contactsCount = contacts.length;
      }
      if (this.contactId) {
        const currentContact = contacts?.find((contact) => contact.id == this.contactId);
        if (currentContact) {
          this.contactForm.patchValue(
            pick(
              { ...currentContact, tags: currentContact.tag_list || [] },
              'id',
              'name',
              'email',
              'category_id',
              'company_name',
              'category_name',
              'phone',
              'tags',
            ),
            { emitEvent: false, onlySelf: true },
          );
        }
      }

      this._changeDetectorRef.markForCheck();
    });

    this._contactsService.contact$.pipe(untilComponentDestroyed(this)).subscribe((contact: Contact) => {
      if (this.contactId && contact) {
        this.contactForm.patchValue(
          pick(
            { ...contact, tags: contact.tag_list || [] },
            'id',
            'name',
            'email',
            'category_id',
            'company_name',
            'category_name',
            'phone',
            'tags',
          ),
          { emitEvent: false, onlySelf: true },
        );
      }

      this._changeDetectorRef.markForCheck();
    });

    this._accountService.categories$.pipe(untilComponentDestroyed(this)).subscribe((categories: Category[]) => {
      if (categories) {
        this.categories = categories.filter((option) => !option.hidden);
      }

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });

    // autocomplete search for contacts
    if (!this.contactId) {
      this.contactForm
        .get('name')
        .valueChanges.pipe(untilComponentDestroyed(this), debounceTime(400), distinctUntilChanged())
        .subscribe((value) => {
          let query = value;
          if (!query.id) {
            this.contactForm.get('id').setValue('');

            this._contactsService
              .getContacts(1, 20, 'first_name', 'asc', query)
              .pipe(untilComponentDestroyed(this))
              .subscribe((response) => {
                this.results = response.contacts;
                this._changeDetectorRef.markForCheck();
              });
          }
        });

      this.contactForm
        .get('company_name')
        .valueChanges.pipe(untilComponentDestroyed(this), debounceTime(400), distinctUntilChanged())
        .subscribe((value) => {
          let query = value;
          if (!query.id) {
            this._accountService
              .typeaheadSearch(query, 'Company')
              .pipe(untilComponentDestroyed(this))
              .subscribe((response) => {
                this.companyResults = response.companies;
                this._changeDetectorRef.markForCheck();
              });
          }
        });
    }

    this.tagsSearchResult$ = this.tagsInputControl.valueChanges.pipe(
      untilComponentDestroyed(this),
      debounceTime(300),
      filter((value: string) => !!(value || '').trim()),
      switchMap((query: string) => {
        return this.httpClient.get(`tags?account_id=${this.account.id}`, { params: { query } });
      }),
      map(({ tags }: { tags: string[] }) => tags),
    );
  }

  saveContactAssociation(): void {
    const contact = { ...this.contactForm.getRawValue(), tag_list: this.tags };
    this.contactForm.disable();
    this.error = null;
    if (contact) {
      this._projectsService.project$.pipe(take(1), untilComponentDestroyed(this)).subscribe((project) => {
        if (contact.id) {
          //ch3256: only update contact info if was an edit
          if (this.contactId) {
            this._projectsService
              .updateContactInfo(contact)
              .pipe(untilComponentDestroyed(this))
              .subscribe((newContact) => {
                if (this.data.byPass) {
                  this.matDialogRef.close({ contact: newContact, contact_id: newContact.id });
                  return;
                }
                this._userService.getRecentContacts(this.account.id).pipe(untilComponentDestroyed(this)).subscribe();
                this.contactForm.enable();
                this.matDialogRef.close({ contact_id: this.contactId });
              });
          } else {
            //ch3715: if we update an existing contact, on a new selection, we should update the info.
            this._projectsService
              .updateContactInfo(contact)
              .pipe(untilComponentDestroyed(this))
              .subscribe((newContact) => {
                if (this.data.byPass) {
                  this.matDialogRef.close({ contact: newContact, contact_id: newContact.id });
                  return;
                }
                this._projectsService
                  .createContactAssociation(contact, project.id)
                  .pipe(
                    untilComponentDestroyed(this),
                    finalize(() => {
                      if (this.data.fromProject) {
                        this._contactsService
                          .getDealAssociatedContacts(
                            this.data.paginator.pageIndex,
                            this.data.paginator.pageSize ? this.data.paginator.pageSize : this.data.pageSize,
                            'first_name',
                            this.data.sort.direction,
                            '',
                            this.data.queryFilters,
                            this.data.project.id,
                          )
                          .pipe(untilComponentDestroyed(this), delay(1000))
                          .subscribe(() => {
                            this.contactForm.enable();
                            this.matDialogRef.close({ contact_id: contact.id });
                            this._changeDetectorRef.markForCheck();
                          });
                      } else {
                        this._userService
                          .getRecentContacts(this.account.id)
                          .pipe(untilComponentDestroyed(this), delay(1000))
                          .subscribe(() => {
                            this.contactForm.enable();
                            this.matDialogRef.close({ contact_id: contact.id });
                          });
                      }
                    }),
                  )
                  .subscribe(noop);
              });
          }
        } else {
          this._projectsService
            .createContact(contact)
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              (newContact) => {
                if (this.data.byPass) {
                  this.matDialogRef.close({ contact: newContact, contact_id: newContact.id });
                  return;
                }
                this._projectsService
                  .createContactAssociation(newContact, project.id)
                  .pipe(untilComponentDestroyed(this))
                  .subscribe(() => {
                    this._userService
                      .getRecentContacts(this.account.id)
                      .pipe(untilComponentDestroyed(this))
                      .subscribe();
                    this.contactForm.enable();
                    this.matDialogRef.close({ contact_id: newContact.id });
                  });
              },
              (error) => {
                this.error = error;
                this.contactForm.enable();
              },
            );
        }
      });
    }
  }

  removeEmailField(index: number, contactId: number): void {
    this._projectsService.project$.pipe(take(1), untilComponentDestroyed(this)).subscribe((project) => {
      this._projectsService
        .deleteContactAssociation(contactId, project.id)
        .pipe(untilComponentDestroyed(this))
        .subscribe();
      this._changeDetectorRef.markForCheck();
    });
  }

  autocompleteSelected($event): void {
    this.contactForm.patchValue($event.option.value, { emitEvent: false });
  }

  autocompleteCompanySelected($event): void {
    this.contactForm.patchValue($event.option.value, { emitEvent: false });
  }

  discard(): void {
    this.matDialogRef.close();
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  displayFn(contact: Contact): string {
    return contact && contact.name ? contact.name : '';
  }

  removeTag(tag: string): void {
    this.tagsControl.setValue([...this.tags.filter((item) => tag !== item)]);
    this.tagsControl.markAsDirty();
  }

  addTag(event: MatChipInputEvent): void {
    const { value: rawValue } = event;

    const value = (rawValue || '').trim();

    if (value) {
      this.tagsControl.setValue([...this.tags, value]);
      this.clearTagsInput();
    }
  }

  selectedTag({ option }: MatAutocompleteSelectedEvent): void {
    this.tagsControl.setValue([...this.tags, option.value]);
    this.clearTagsInput();
  }

  private clearTagsInput(): void {
    this.tagsInput.nativeElement.value = '';
    this.tagsInputControl.setValue('');
    this.tagsControl.markAsDirty();
  }

  // Function attached to debounce-click directive to flag the button's disable state.
  public isDebounceLoading(isLoading: boolean): boolean {
    return (this.isLoading = isLoading);
  }
}
