import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  // Private
  private _notifications: BehaviorSubject<Notification[] | null>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the private defaults
    this._notifications = new BehaviorSubject(null);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for notifications
   */
  get notifications$(): Observable<Notification[]> {
    return this._notifications.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Store notifications on the service
   *
   * @param notifications
   */
  store(notifications: Notification[]): Observable<Notification[]> {
    // Load the notifications
    this._notifications.next(notifications);

    // Return the notifications
    return this.notifications$;
  }

  /**
   * Create a notification
   *
   * @param notification
   */
  create(notification: Notification): Observable<Notification> {
    return this.notifications$.pipe(
      take(1),
      switchMap((notifications) =>
        this._httpClient.put<Notification>('api/common/notifications', { notification }).pipe(
          map((newNotification) => {
            // Update the notifications with the new notification
            this._notifications.next([...notifications, newNotification]);

            // Return the new notification from observable
            return newNotification;
          }),
        ),
      ),
    );
  }

  /**
   * Update the notification
   *
   * @param id
   * @param notification
   */
  update(id: number, notification: Notification): Observable<Notification> {
    return this.notifications$.pipe(
      take(1),
      switchMap((notifications) =>
        this._httpClient
          .patch<Notification>('api/common/notifications', {
            id,
            notification,
          })
          .pipe(
            map((updatedNotification: Notification) => {
              // Find the index of the updated notification
              const index = notifications.findIndex((item) => item.id === id);

              // Update the notification
              notifications[index] = updatedNotification;

              // Update the notifications
              this._notifications.next(notifications);

              // Return the updated notification
              return updatedNotification;
            }),
          ),
      ),
    );
  }

  /**
   * Mark all notifications as read
   */
  markAllAsRead(): Observable<boolean> {
    return this.notifications$.pipe(
      take(1),
      switchMap((notifications) =>
        this._httpClient.get<boolean>('api/common/notifications/mark-all-as-read').pipe(
          map((isUpdated: boolean) => {
            // Go through all notifications and set them as read
            notifications.forEach((notification, index) => {
              notifications[index].is_read = true;
            });

            // Update the notifications
            this._notifications.next(notifications);

            // Return the updated status
            return isUpdated;
          }),
        ),
      ),
    );
  }
}
