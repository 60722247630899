import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { TreoAutogrowModule } from '@treo/directives/autogrow';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocompleteModule } from '@angular/material/autocomplete';
import { Overlay } from '@angular/cdk/overlay';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormFieldDirective } from './form-field.directive';
import { FormFieldComponent } from './form-field.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

import { TagsFormFieldComponent } from './tags-form-field/tags-form-field.component';
import { TextFormFieldComponent } from './text-form-field/text-form-field.component';
import { TextAreaFormFieldComponent } from './textarea-form-field/textarea-form-field.component';
import { SelectFormFieldComponent } from './select-form-field/select-form-field.component';
import { MoneyFormFieldComponent } from './money-form-field/money-form-field.component';
import { NumberFormFieldComponent } from './number-form-field/number-form-field.component';
import { DateTimeFormFieldComponent } from './datetime-form-field/datetime-form-field.component';
import { PercentFormFieldComponent } from './percent-form-field/percent-form-field.component';
import { TypeaheadFormFieldComponent } from './typeahead-form-field/typeahead-form-field.component';
import { DecimalFormFieldComponent } from './decimal-form-field/decimal-form-field.component';
import { YearFormFieldComponent } from './year-form-field/year-form-field.component';
import { CheckboxFormFieldComponent } from './checkbox-form-field/checkbox-form-field.component';
import { UrlFormFieldComponent } from './url-form-field/url-form-field.component';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { WidgetsModule } from 'app/shared/components/widgets/widgets.module';
import { ContactFormFieldComponent } from './contact-form-field/contact-form-field.component';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from 'app/shared/directives/directives.module';

@NgModule({
    declarations: [
        FormFieldComponent,
        FormFieldDirective,
        TagsFormFieldComponent,
        TextFormFieldComponent,
        TextAreaFormFieldComponent,
        SelectFormFieldComponent,
        MoneyFormFieldComponent,
        NumberFormFieldComponent,
        DateTimeFormFieldComponent,
        PercentFormFieldComponent,
        DecimalFormFieldComponent,
        YearFormFieldComponent,
        ContactFormFieldComponent,
        CheckboxFormFieldComponent,
        TypeaheadFormFieldComponent,
        UrlFormFieldComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatMomentDateModule,
        MatProgressBarModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        MatChipsModule,
        MatAutocompleteModule,
        TreoAutogrowModule,
        PipesModule,
        WidgetsModule,
        CurrencyMaskModule,
        MatTooltipModule,
        DirectivesModule,
        NgxMaskModule.forRoot(),
    ],
    exports: [FormFieldComponent],
    providers: [
        {
            provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => {
                return () => overlay.scrollStrategies.block();
            },
            deps: [Overlay],
        },
    ]
})
export class FormFieldsModule {}
