<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'"></empty-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with horizontal navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Centered -->
<centered-layout *ngIf="layout === 'centered'"></centered-layout>

<!-- Enterprise -->
<enterprise-layout *ngIf="layout === 'enterprise'"></enterprise-layout>

<!-- Material -->
<material-layout *ngIf="layout === 'material'"></material-layout>

<!-- Modern -->
<modern-layout *ngIf="layout === 'modern'"></modern-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Basic -->
<basic-layout *ngIf="layout === 'basic'"></basic-layout>

<!-- Classic -->
<classic-layout *ngIf="layout === 'classic'"></classic-layout>

<!-- Classy -->
<classy-layout *ngIf="layout === 'classy'"></classy-layout>

<!-- Compact -->
<compact-layout *ngIf="layout === 'compact'"></compact-layout>

<!-- Dense -->
<dense-layout *ngIf="layout === 'dense'"></dense-layout>

<!-- Futuristic -->
<futuristic-layout *ngIf="layout === 'futuristic'"></futuristic-layout>

<!-- Thin -->
<thin-layout *ngIf="layout === 'thin'"></thin-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Settings drawer - Remove this to remove the drawer and its button -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<div
  class="settings-drawer-toggle fixed flex items-center justify-center right-0 w-12 h-12 shadow-md rounded-l-4 z-99 cursor-pointer bg-cool-gray-500"
  style="top: 310px"
  (click)="settingsDrawer.toggle()"
>
  <mat-icon class="icon-size-24 text-white" [svgIcon]="'dynamic_feed'"></mat-icon>
</div>

<treo-drawer
  [fixed]="true"
  name="customDrawer"
  position="right"
  mode="over"
  [transparentOverlay]="false"
  (openedChanged)="customDrawerOpenedChanged($event)"
  #customDrawer
  class="custom-drawer"
>
  <ng-template #childLoader></ng-template>
</treo-drawer>

<treo-drawer
  [fixed]="true"
  [name]="'settingsDrawer'"
  [position]="'right'"
  [mode]="'over'"
  (openedChanged)="onSettingsDrawerOpenChanged($event)"
  [transparentOverlay]="true"
  class="settings-drawer"
  #settingsDrawer
>
  <div class="flex flex-col w-full light:bg-cool-gray-100 overflow-auto">
    <div class="flex flex-row items-center px-6 h-20 min-h-20 border-b">
      <mat-icon class="mr-3 text-secondary" [svgIcon]="'message'"></mat-icon>
      <div class="text-lg font-semibold tracking-wide">Recent Activity</div>
    </div>

    <div class="flex flex-col">
      <div class="layout-content">
        <!-- Notifications -->
        <ng-container *ngFor="let activity of activities">
          <div class="notification">
            <ng-container *ngIf="activity.pipeline_id && activity.project_id">
              <a [routerLink]="getActivityObjectLink(activity)">
                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
              </a>
            </ng-container>
          </div>

          <ng-template #notificationContent>
            <!-- Image -->
            <img class="image" *ngIf="activity.avatar_url" [src]="activity.avatar_url" />

            <!-- Title, description & time -->
            <div class="notification-content">
              <div class="title" [innerHTML]="getActivityTitle(activity)"></div>
              <div class="description" *ngIf="activity.user_name" [innerHTML]="getActivityOutput(activity)"></div>
              <div class="time">{{ activity.created_at | date: 'MMM dd, h:mm a' }}</div>
            </div>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="!activities || !activities.length">
          <div class="empty">
            <mat-icon [svgIcon]="'notifications'"></mat-icon>
            <span>No activity</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</treo-drawer>
