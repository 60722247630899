import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { HttpClient } from '@angular/common/http';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormFieldComponent } from '../form-field.types';
import { Account } from 'app/core/models/account.types';
import { AccountService } from 'app/core';
@Component({
  templateUrl: './tags-form-field.component.html',
  styleUrls: ['./tags-form-field.component.scss'],
})
export class TagsFormFieldComponent extends FormFieldComponent implements OnInit {
  @ViewChild('inputControl') inputControl: ElementRef;

  public editing: boolean = false;
  public value: string[] = [];
  public tags: string[] = [];
  public foundTags: string[];
  account: Account;

  //tags
  tagCtrl: UntypedFormControl;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  visible = true;
  selectable = true;
  removable = true;

  private _originalValue;
  private _readonly;

  @ViewChild('tagInput')
  tagInput: ElementRef<HTMLInputElement>;

  constructor(private _httpClient: HttpClient, private _accountService: AccountService) {
    super();

    this._accountService.currentAccount.subscribe((accountData) => {
      this.account = accountData;
    });
  }

  ngOnInit() {
    this.tagCtrl = new UntypedFormControl();

    this.value = this.model[this.fieldDef.name];
    this._originalValue = this.value;

    if (this.value) {
      this.tags = this.value;
    }

    this.tagCtrl.valueChanges.subscribe((value) => {
      if (value) {
        this._httpClient
          .get(`tags?account_id=${this.account.id}`, {
            params: {
              query: value,
            },
          })
          .subscribe((response: any) => {
            this.foundTags = response.tags;
          });
      }
    });

    if (this.fieldDef.meta && this.fieldDef.meta.readonly) {
      this._readonly = this.fieldDef.meta.readonly;
    }
    if (this.readonly) {
      this._readonly = this.readonly;
    }
  }

  edit() {
    if (!this.editing && !this.isDisabled) {
      this.editing = true;
    }
  }

  onBlur($event: Event) {
    this.onSave();
    this.editing = false;
  }

  onSave() {
    this.updateModel();
    this.update.emit({ [this.fieldDef.name]: this.value });
  }

  updateModel() {
    let list = this.tags.join(',');
    this.model[this.fieldDef.name] = list;
    this._originalValue = this.value;
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.tags.push(value.trim());
    }

    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  removeTag(tag: string): void {
    this.tags.splice(
      this.tags.findIndex((item) => item === tag),
      1,
    );
  }

  selectedTag(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  updateChangedModel?(model: any): void {
    /* noop */
  }
}
