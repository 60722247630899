import { ReportLayout } from 'app/modules/reports/reports.types';
import { CreateReportModel, GetReportsFilter, ReportLayoutChart } from '../reporting.types';

export class GetReports {
  static readonly type = 'Get Reports';

  constructor(public filter: GetReportsFilter) {}
}

export class GetReportById {
  static readonly type = 'Get Report By Id';

  constructor(public id: number) {}
}

export class CreateReport {
  static readonly type = 'Create Report';

  constructor(public model: CreateReportModel) {}
}

export class UpdateReport {
  static readonly type = 'Update Report';

  constructor(public model: ReportLayout<any>) {}
}

export class ClearSelectedReport {
  static readonly type = 'Clear Selected Report';

  constructor() {}
}

export class ExportReport {
  static readonly type = 'Export Report';

  constructor(public id: number) {}
}

export namespace BuildChartData {
  export interface Params {
    reportLayoutId: number;
    charts: Partial<ReportLayoutChart>[];
  }

  export class Action {
    static readonly type = 'Build Chart Data';

    constructor(public buildChartDataParams: Params) {}
  }
}
