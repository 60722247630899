import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormFieldComponent } from '../form-field.types';
import { Account, Activity } from 'app/core/models/account.types';
import { AccountService } from 'app/core';
import { CurrencyMaskOptions, getCurrencyMask } from 'app/shared/Utils/common.utils';

@Component({
  templateUrl: './money-form-field.component.html',
  styleUrls: ['./money-form-field.component.scss'],
})
export class MoneyFormFieldComponent extends FormFieldComponent implements OnInit {
  @ViewChild('inputControl') inputControl: ElementRef;

  public editing: boolean = false;
  public value: any;
  public showHistory: boolean = false;
  public activities: Activity[];
  private _originalValue;
  private _readonly = false;
  precision: number = 0;
  private account: Account;

  get currencyMaskOptions(): CurrencyMaskOptions {
    return getCurrencyMask(this.account.display_currency, this.precision);
  }

  constructor(private _accountService: AccountService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.value = this.model[this.fieldDef.name];

    // we want to track the original value, so that we don't hit save to many times
    this._originalValue = this.value;
    if (this.fieldDef.meta && this.fieldDef.meta.readonly) {
      this._readonly = this.fieldDef.meta.readonly;
    }
    if (this.readonly) {
      this._readonly = this.readonly;
    }
    this.readonly = this._readonly;

    if (this.fieldDef.meta && this.fieldDef.meta.scale !== null && this.fieldDef.meta.scale !== undefined) {
      this.precision = this.fieldDef.meta.scale;
    }

    this._accountService.currentAccount.subscribe((accountData) => {
      this.account = accountData;
    });
  }

  /**
   * When the model is updated after save, re-run our logic
   * @param model
   */
  updateChangedModel(model: any): void {
    this.model = model;
    //this.value = this.model[this.fieldDef.name] * 100;
    this.value = this.model[this.fieldDef.name];

    // we want to track the original value, so that we don't hit save to many times
    this._originalValue = this.value;
  }

  edit() {
    if (!this.editing && !this._readonly) {
      this.editing = true;
      setTimeout(() => {
        this.inputControl.nativeElement.focus();
      }, 300);
    }
  }

  onBlur($event: Event) {
    this.onSave();
    this.editing = false;
  }

  onSave() {
    if (this._originalValue != this.value) {
      this.updateModel();
      this.update.emit({ [this.fieldDef.name]: this.value });
    }
  }

  updateModel() {
    this.model[this.fieldDef.name] = this.value;
    this._originalValue = this.value;
  }
}
