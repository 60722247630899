<ng-container *ngIf="customObjType == 'Contact'">
  <mat-form-field class="flex-auto gt-xs:pr-3 contact-wrapper">
    <mat-label>{{ fieldDef.label }}</mat-label>
    <input
      style="display: none"
      #inputControl
      matInput
      [ngModel]="value"
      (ngModelChange)="value = $event"
      [disabled]="isDisabled"
    />
    <div [matTooltip]="showTip" class="flex flex-row items-center justify-between w-full">
      <span class="underline pointer" (click)="value && !isDisabled && goToRecord()">
        {{ value }}
      </span>
      <button
        class="remove-email-field-form mr-2"
        *ngIf="!value"
        type="button"
        mat-icon-button
        (click)="openContactModal()"
      >
        <mat-icon [svgIcon]="'add_box'"></mat-icon>
      </button>
      <div *ngIf="!!value">
        <a
          *ngIf="!!value && isFilterable()"
          [routerLink]="['/properties']"
          [queryParams]="{ filter: filterQueryParams() }"
        >
          <button class="remove-email-field-form" type="button" mat-icon-button matTooltip="Filter">
            <mat-icon [svgIcon]="'search'"></mat-icon>
          </button>
        </a>
        <button
          class="remove-email-field-form"
          *ngIf="value && !isDisabled"
          type="button"
          mat-icon-button
          (click)="openContactModal()"
          matTooltip="Edit"
        >
          <mat-icon [svgIcon]="'edit'"></mat-icon>
        </button>
        <button
          class="remove-email-field-form"
          *ngIf="value && !isDisabled"
          type="button"
          mat-icon-button
          (click)="removeModelField($event)"
          matTooltip="Remove"
        >
          <mat-icon [svgIcon]="'cancel'"></mat-icon>
        </button>
      </div>
    </div>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="customObjType == 'Company'">
  <mat-form-field class="flex-auto gt-xs:pr-3 contact-wrapper">
    <mat-label>{{ fieldDef.label }}</mat-label>
    <input
      style="display: none"
      #inputControl
      matInput
      [ngModel]="value"
      (ngModelChange)="value = $event"
      [disabled]="isDisabled"
    />
    <div [matTooltip]="showTip" class="flex flex-row items-center justify-between w-full">
      <span class="underline pointer" (click)="value && !isDisabled && goToRecord()">
        {{ value }}
      </span>
      <button
        class="remove-email-field-form mr-2"
        *ngIf="!value"
        type="button"
        mat-icon-button
        (click)="openContactModal()"
      >
        <mat-icon [svgIcon]="'add_box'"></mat-icon>
      </button>
      <div *ngIf="!!value">
        <a
          *ngIf="!!value && isFilterable()"
          [routerLink]="['/properties']"
          [queryParams]="{ filter: filterQueryParams() }"
        >
          <button class="remove-email-field-form" type="button" mat-icon-button matTooltip="Filter">
            <mat-icon [svgIcon]="'search'"></mat-icon>
          </button>
        </a>
        <button
          class="remove-email-field-form"
          *ngIf="value && !isDisabled"
          type="button"
          mat-icon-button
          (click)="openContactModal()"
          matTooltip="Edit"
        >
          <mat-icon [svgIcon]="'edit'"></mat-icon>
        </button>
        <button
          class="remove-email-field-form"
          *ngIf="value && !isDisabled"
          type="button"
          mat-icon-button
          (click)="removeModelField($event)"
          matTooltip="Remove"
        >
          <mat-icon [svgIcon]="'cancel'"></mat-icon>
        </button>
      </div>
    </div>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="customObjType == 'User'">
  <mat-form-field class="flex-auto gt-xs:pr-3" (click)="edit()" (focus)="edit()">
    <mat-label>{{ fieldDef.label }}</mat-label>
    <div [matTooltip]="showTip" class="w-full">
      <mat-select
        [placeholder]="fieldDef.label"
        (selectionChange)="onSelectionChange($event)"
        [(ngModel)]="value"
        (blur)="onBlur($event)"
        [disabled]="isDisabled"
      >
        <mat-option *ngFor="let option of filteredOptions" [value]="option.id">{{
          option?.name || option?.full_name
        }}</mat-option>
      </mat-select>
    </div>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="customObjType == 'FileResource'">
  <mat-form-field class="flex-auto gt-xs:pr-3 contact-wrapper">
    <mat-label>{{ fieldDef.label }}</mat-label>
    <input style="display: none" matInput />
    <div [matTooltip]="showTip" class="flex flex-row items-center justify-between w-full" *ngIf="!uploadingFile">
      <span *ngIf="!value"> Upload a file... </span>
      <span class="underline truncate w-40 pointer" (click)="viewFile(fileResource)">
        {{ value }}
      </span>
      <button
        class="remove-email-field-form mr-2"
        *ngIf="!value"
        type="button"
        mat-icon-button
        (click)="addFileInput.click()"
      >
        <mat-icon [svgIcon]="'add_box'"></mat-icon>
        <input style="display: none" type="file" (change)="onFileChanged($event)" #addFileInput />
      </button>
      <div *ngIf="value">
        <button
          class="remove-email-field-form"
          *ngIf="value && !isDisabled"
          type="button"
          mat-icon-button
          (click)="downloadFile(fileResource)"
          matTooltip="Download"
        >
          <mat-icon [svgIcon]="'get_app'"></mat-icon>
        </button>
        <button
          class="remove-email-field-form"
          *ngIf="value && !isDisabled"
          type="button"
          mat-icon-button
          (click)="editFileInput.click()"
          matTooltip="Edit"
        >
          <mat-icon [svgIcon]="'edit'"></mat-icon>
          <input style="display: none" type="file" (change)="onFileChanged($event)" #editFileInput />
        </button>
        <button
          class="remove-email-field-form"
          *ngIf="value && !isDisabled"
          type="button"
          mat-icon-button
          (click)="removeModelField($event)"
          matTooltip="Remove"
        >
          <mat-icon [svgIcon]="'cancel'"></mat-icon>
        </button>
      </div>
    </div>
    <mat-progress-bar *ngIf="uploadingFile" mode="indeterminate"></mat-progress-bar>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="hasNoRecordType()">
  <mat-form-field class="flex-auto gt-xs:pr-3 contact-wrapper">
    <mat-label>{{ fieldDef.label }}</mat-label>
    <input style="display: none" #inputControl matInput [ngModel]="value" (ngModelChange)="value = $event" />
    <div [matTooltip]="showTip" class="flex flex-row items-center w-full" *ngIf="value">
      <task-status [task]="value" [taskStatuses]="workflowStates" (onUpdate)="updateTask(value)"></task-status>
      <a (click)="goToRecord()">
        <div class="flex flex-col">
          <span class="font-semibold leading-none">{{ value.title }}</span>
          <span class="text-sm text-secondary leading-none mt-1">
            <span *ngIf="value.due_date">{{ value.due_date | date: 'LLL dd' }}</span>
            <span *ngIf="value.assigned_user_name">• {{ value.assigned_user_name }}</span>
          </span>
        </div>
      </a>
    </div>
  </mat-form-field>
</ng-container>
