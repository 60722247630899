<!-- Wrapper -->
<div class="wrapper">
  <!-- Header -->
  <div class="header">
    <!-- Logo -->
    <div class="logo" *ngIf="!isScreenSmall">
      <img class="logo-text" src="assets/images/logo/ts-logo.png" />
      <img class="logo-text-on-dark" src="assets/images/logo/logo-text-on-dark.svg" />
      <img class="logo-base" src="assets/images/logo/ts-logo.png" />
    </div>
  </div>

  <!-- Content -->
  <div class="content">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer
    <div class="footer">
        TermSheet &copy; {{currentYear}}
    </div>
     -->
</div>
