import { Pipe, PipeTransform } from '@angular/core';
import formatNumber from 'accounting-js/lib/formatNumber.js';

@Pipe({ name: 'formatNumber' })
export class FormatNumberPipe implements PipeTransform {
  transform(value: string, precision = 2): any {
    if (value) {
      if (typeof value === 'string' && value.startsWith('=')) {
        return value;
      } else {
        const newValue = value
          ?.toString()
          ?.replace(/[$,]/gi, '')
          ?.replace(/^\.|0\.$/, '0.');
        if (!Number.isInteger(+newValue) && newValue?.split('.')?.pop()?.length > 1) {
          return formatNumber(newValue, {
            precision: precision,
          });
          //return newValue
        }
        if (!isNaN(+newValue)) {
          return formatNumber(newValue, {
            precision: precision,
          });
        }
        return ''; // return empty for any non degit
      }
    }
    return value;
  }
}
