import { ProjectSheet } from 'app/modules/projects/details/sheets/sheets.types';
import { CustomField } from 'app/shared/modules/custom-form/custom-form.types';

export interface Image {
  id: number;
  list_image_url: string;
  original_image_url: string;
}

export interface Address {
  id?: number;
  address_string?: string;
  postal_code?: string;
  latitude?: number;
  longitude?: number;
  street?: string;
  city?: string;
  state?: string;
}

export interface Property {
  id?: number;
  apn?: string;
  condition?: string;
  description?: any;
  last_sale_date?: Date | string;
  last_sale_price?: number;
  livability_score?: any;
  name?: string;
  num_buildings?: any;
  num_parking_spaces?: number;
  occupancy?: string;
  opp_zone?: boolean;
  parcel_size?: string;
  project_ids?: Array<number>;
  property_type_id?: number;
  remote_id?: string;
  rentable_square_feet?: number;
  tax_assessor_id?: string;
  type?: string;
  unit_size?: number;
  year_built?: number;
  template_id?: number;
  created_at?: Date | string;
  updated_at?: Date | string;
  address: Address;
  featured_image_url?: string;
  msa?: null;
  template?: ProjectTemplate;
  images?: Image[];
  num_notes?: number;
  num_open_tasks?: number;
  num_documents?: number;
  cf_contact?: any;
  cf_date_filter_test?: any;
  cf_test_a?: number;
  address_string?: string;
  project_id?: number;
  pipeline_id?: number;
  property_ids?: number[];
}

export interface Compset {
  id?: number;
  title: string;
  project_id?: number;
  definition?: any;
}

export interface Deal {
  id: number;
  properties: Property[];
}

export interface Project {
  id: string;
  title: string;
  images?: Image[];
  deal?: Deal;
  root_folder_resource_id?: number;
  num_notes?: number;
  num_open_tasks?: number;
  num_documents?: number;
  num_loan_quotes?: number;
  pipeline_id?: number;
  template_id?: number;
  is_owner?: boolean;
  default_task_list_id?: number;
  project_stage_id?: number;
  project_stage?: any;
  project_sheets?: ProjectSheet[];
  template?: ProjectTemplate;
  contact_associations?: any[];
  property_type_id?: number;
  project_type_id?: number;
  child_ids?: any[];
  properties?: Property[];
  children?: Project[];
  is_child?: string;
  is_parent?: string;
  parent_id?: string;
  parents?: Project[];
  pipeline?: Pipeline;
  property?: Property;
  version_name?: string;
}

export interface ProjectPagination {
  length: number;
  size: number;
  page: number;
  lastPage: number;
  startIndex: number;
  endIndex: number;
}

export interface Pipeline {
  id?: number;
  name: string;
  num_projects?: number;
  table_definition?: any;
  dashboard_definition?: any;
  related_to_id?: number;
  related_to_type?: string;
  geojson?: any;
  visible?: boolean;
  color?: string;
  template_id?: number;
  fill_demo_data?: boolean;
  num_projects_user_has_access_to?: number;
  pipeline_type?: 'static' | 'dynamic';
  saved_filter_id?: number;
  rank?: number;
  visibility?: string;
  grid_definition?: any;
  create_form_definition?: CreateFormDefinition | string;
  kanban_definition?: KanbanDefinition | string;
}

export interface User {
  id: number;
  email: string;
  password: string;
  full_name: string;
  avatar_url: string;
  uuid: string;
  settings: any;
}

export interface Appointment {
  id: number;
  account_id: number;
  title: string;
  description: string;
  assigned_user: User;
  due_date: string;
  start_date: any;
  start_time: string;
  end_date: any;
  end_time: string;
  workflow_state: string;
  related_to_id: number;
  related_to_name: string;
  related_to_type: string;
  related_to_string: string;
  assigned_user_name: string;
  assigned_user_id: number;
  is_complete: boolean;
}

export interface Comment {
  id: number;
  user: User;
  body: string;
  commentable_id: number;
  commentable_type: string;
  related_to_id: number;
  related_to_type: string;
  editing?: boolean;
}

export interface DealMapFilter {
  id: number;
  iconName: string;
  label: string;
  key: string;
}

export interface ProjectTemplate {
  id?: number;
  name: string;
  default: boolean;
  listed: boolean;
  definition?: any;
  rank?: number;
  hidden?: boolean;
}

export enum SaveProjectMode {
  MovePipeline = 'MovePipeline',
  ChangeStatus = 'ChangeStatus',
}

export interface PanelDef {
  type: string;
  title: string;
  meta: any;
  id?: number;
}

export interface CreateFormDefinition {
  fields: CustomField[];
}

export interface KanbanDefinition {
  fields: string[][];
}

export interface GridDefinition {
  fields: string[][];
}
