import { NgModule } from '@angular/core';
import {
  AuthorizeDirective,
  BackButtonDirective,
  DebounceClickDirective,
  DynamicFormRowDirective,
  GooglePlacesDirective,
  ResizeColumnDirective,
} from './index';

@NgModule({
  declarations: [
    AuthorizeDirective,
    BackButtonDirective,
    DebounceClickDirective,
    DynamicFormRowDirective,
    GooglePlacesDirective,
    ResizeColumnDirective,
  ],
  exports: [
    AuthorizeDirective,
    BackButtonDirective,
    DebounceClickDirective,
    DynamicFormRowDirective,
    GooglePlacesDirective,
    ResizeColumnDirective,
  ],
})
export class DirectivesModule {}
