import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'app/core/models/user.types';
import { AccountService } from 'app/core';
import { Account } from 'app/core/models/account.types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { CalculatorModalComponent } from 'app/layout/common/calculator/calculator-modal/calculator-modal.component';

@Component({
  selector: 'calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
})
export class CalculatorComponent implements OnInit, OnDestroy {
  @Input()
  showAvatar: boolean;

  // Private
  private _unsubscribeAll: Subject<void>;
  private _account: Account;

  /**
   * Constructor
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {Router} _router
   * @param {UserService} _userService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _matDialog: MatDialog,
    private _router: Router,
    private _accountService: AccountService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Set the defaults
    this.showAvatar = true;

    this._accountService.currentAccount.subscribe((accountData) => {
      this._account = accountData;
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {}

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  openCalculatorModal(): void {
    const dialogRef = this._matDialog.open(CalculatorModalComponent, {
      panelClass: 'mailbox-compose-dialog',
      width: '40vw',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openChat(): void {
    if (window.analytics) {
      let intercom = window['Intercom'];
      if (intercom) {
        intercom('show');
      }
    }
  }
}
