<div class="header">
  <div class="title">Quick Calculator</div>
  <div>
    <button class="close-button" mat-icon-button (click)="discard()" [tabIndex]="-1">
      <mat-icon [svgIcon]="'close'"></mat-icon>
    </button>
  </div>
</div>
<div class="flex flex-auto">
  <div class="flex flex-col w-1/2 border-right p-2">
    <div class="flex flex-col gt-xs:flex-row mt-3">
      <mat-form-field class="subject flex-auto">
        <mat-label>Purchase Price</mat-label>
        <input
          matInput
          [(ngModel)]="underwriting.purchasePrice"
          (ngModelChange)="onChange($event)"
          currencyMask
          [options]="currencyMaskOptions"
        />
      </mat-form-field>
    </div>
    <div class="flex flex-col gt-xs:flex-row mt-3">
      <mat-form-field class="subject flex-auto">
        <mat-label>NOI</mat-label>
        <input
          matInput
          [(ngModel)]="underwriting.noi"
          (ngModelChange)="onChange($event)"
          currencyMask
          [options]="currencyMaskOptions"
        />
      </mat-form-field>
    </div>
    <div class="flex flex-col gt-xs:flex-row mt-3">
      <mat-form-field class="subject flex-auto">
        <mat-label>Cap Rate</mat-label>
        <span matSuffix>%</span>
        <input matInput [(ngModel)]="underwriting.capRate" (ngModelChange)="onChangeCapRate($event)" />
      </mat-form-field>
    </div>
    <div class="flex flex-col gt-xs:flex-row mt-3">
      <mat-form-field class="subject flex-auto">
        <mat-label>Leverage (%)</mat-label>
        <span matSuffix>%</span>
        <input matInput [(ngModel)]="underwriting.leverage" (ngModelChange)="onChange($event)" />
      </mat-form-field>
    </div>
    <div class="flex flex-col gt-xs:flex-row mt-3">
      <mat-form-field class="subject flex-auto">
        <mat-label>Interest Rate</mat-label>
        <span matSuffix>%</span>
        <input matInput [(ngModel)]="underwriting.interestRate" (ngModelChange)="onChange($event)" />
      </mat-form-field>
    </div>
    <div class="flex flex-col gt-xs:flex-row mt-3">
      <mat-form-field class="subject flex-auto">
        <mat-label>Amoritization Period (Years)</mat-label>
        <input matInput [(ngModel)]="underwriting.term" (ngModelChange)="onChange($event)" />
      </mat-form-field>
    </div>
  </div>
  <div class="flex flex-col w-1/2 side-panel">
    <div class="flex flex-col gt-xs:flex-row mt-3">
      <mat-form-field class="subject flex-auto">
        <mat-label>Equity Required</mat-label>
        <input
          matInput
          [(ngModel)]="underwriting.equityRequired"
          disabled
          currencyMask
          [options]="currencyMaskOptions"
        />
      </mat-form-field>
    </div>
    <div class="flex flex-col gt-xs:flex-row mt-3">
      <mat-form-field class="subject flex-auto">
        <mat-label>Cap Rate</mat-label>
        <span matSuffix>%</span>
        <input matInput [ngModel]="underwriting.capRate / 100 | percent: '1.2-2'" disabled />
      </mat-form-field>
    </div>
    <div class="flex flex-col gt-xs:flex-row mt-3">
      <mat-form-field class="subject flex-auto">
        <mat-label>Net Cash Flow</mat-label>
        <input matInput [(ngModel)]="underwriting.netCashFlow" disabled currencyMask [options]="currencyMaskOptions" />
      </mat-form-field>
    </div>
    <div class="flex flex-col gt-xs:flex-row mt-3">
      <mat-form-field class="subject flex-auto">
        <mat-label>Cash on Cash</mat-label>
        <span matSuffix>%</span>
        <input matInput [ngModel]="underwriting.cashOnCash / 100 | percent: '1.2-2'" disabled />
      </mat-form-field>
    </div>
    <div class="flex flex-col gt-xs:flex-row mt-3">
      <mat-form-field class="subject flex-auto">
        <mat-label>DSCR</mat-label>
        <span matSuffix>x</span>
        <input matInput [ngModel]="underwriting.dscr / 100 | percent: '1.2-2'" disabled />
      </mat-form-field>
    </div>
  </div>
</div>
