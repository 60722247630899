import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map, switchMap, take, tap } from 'rxjs/operators';
import { FolderResource, FileResource } from './folders.types';

declare type ModuleBaseUrlType = 'projects' | 'properties';
@Injectable({
  providedIn: 'root',
})
export class FoldersTabService {
  private _folder: BehaviorSubject<FolderResource | null>;
  private _file: BehaviorSubject<FileResource | null>;
  private _moduleId: string;
  private _moduleBaseUrl: ModuleBaseUrlType;
  private _accountId: string;

  constructor(private _httpClient: HttpClient) {
    this._folder = new BehaviorSubject(null);
    this._file = new BehaviorSubject(null);
  }

  get folder$(): Observable<FolderResource> {
    return this._folder.asObservable();
  }

  get file$(): Observable<FileResource> {
    return this._file.asObservable();
  }

  set accountId(val: string) {
    this._accountId = val;
  }

  set moduleId(val: string) {
    this._moduleId = val;
  }

  get moduleId() {
    return this._moduleId;
  }

  set moduleBaseUrl(val: ModuleBaseUrlType) {
    this._moduleBaseUrl = val;
  }

  get moduleBaseUrl() {
    return this._moduleBaseUrl;
  }

  searchFolders(search: string = ''): Observable<any> {
    return this._httpClient
      .post(`${this.moduleBaseUrl}/${this.moduleId}/folder_resources/search?account_id=${this._accountId}`, {
        search: search,
      })
      .pipe(
        debounceTime(500),
        map((response) => {
          return response;
        }),
      );
  }

  getFolder(folderId?: number): Observable<FolderResource> {
    if (folderId) {
      return this._httpClient
        .get<any>(`${this.moduleBaseUrl}/${this.moduleId}/folder_resources/${folderId}?account_id=${this._accountId}`)
        .pipe(
          tap((response: any) => {
            this._folder.next(response.folder_resource);
          }),
        );
    } else {
      return this._httpClient
        .get<any>(`${this.moduleBaseUrl}/${this.moduleId}/folder_resources?account_id=${this._accountId}`)
        .pipe(
          tap((response: any) => {
            this._folder.next(response.folder_resource);
          }),
        );
    }
  }

  getFile(folderId: number, fileResourceId: number): Observable<FileResource> {
    return this._httpClient
      .get<any>(
        `${this.moduleBaseUrl}/${this.moduleId}/folder_resources/${folderId}/file_resources/${fileResourceId}?account_id=${this._accountId}`,
      )
      .pipe(
        map((response: any) => {
          this._file.next(response.file_resource);
          return response.file_resource;
        }),
      );
  }

  createFolder(folderResource: FolderResource, skipNext: boolean = false): Observable<FolderResource> {
    return this.folder$.pipe(
      take(1),
      switchMap(() =>
        this._httpClient
          .post<any>(`${this.moduleBaseUrl}/${this.moduleId}/folder_resources?account_id=${this._accountId}`, {
            folder_resource: folderResource,
          })
          .pipe(
            map((reponse) => {
              // Return the new contact
              return reponse.folder_resource;
            }),
          ),
      ),
    );
  }

  updateFolder(folderResource: FolderResource): Observable<FolderResource> {
    return this.folder$.pipe(
      take(1),
      switchMap(() =>
        this._httpClient
          .put<any>(
            `${this.moduleBaseUrl}/${this.moduleId}/folder_resources/${folderResource.id}?account_id=${this._accountId}`,
            {
              folder_resource: folderResource,
            },
          )
          .pipe(
            map((reponse) => {
              // Return the new contact
              return reponse.folder_resource;
            }),
          ),
      ),
    );
  }

  createFile(folderId: number, fileResource: FileResource, skipNext: boolean = false): Observable<FileResource> {
    return this.folder$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient
          .post<any>(
            `${this.moduleBaseUrl}/${this.moduleId}/folder_resources/${folderId}/file_resources?account_id=${this._accountId}`,
            { file_resource: fileResource },
          )
          .pipe(
            map((response) => {
              let newFile = response.file_resource;

              if (folder && !skipNext) {
                let files = [newFile, ...folder.file_resources];
                let newFolder = folder;
                newFolder.file_resources = files;
                // Update the contacts with the new contact
                this._folder.next(newFolder);
              }

              return newFile;
            }),
          ),
      ),
    );
  }

  deleteFile(folderId: number, fileResourceId: number): Observable<any> {
    return this.folder$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient
          .delete<any>(
            `${this.moduleBaseUrl}/${this.moduleId}/folder_resources/${folderId}/file_resources/${fileResourceId}?account_id=${this._accountId}`,
          )
          .pipe(
            map((response) => {
              if (folder) {
                let files = folder.file_resources;
                const index = files.findIndex((item) => item.id === fileResourceId);
                files.splice(index, 1);
                folder.file_resources = files;
                this._folder.next(folder);
              }
              return response.file_resource;
            }),
          ),
      ),
    );
  }

  deleteFolder(folderId: number): Observable<any> {
    return this.folder$.pipe(
      take(1),
      switchMap(() =>
        this._httpClient
          .delete<any>(
            `${this.moduleBaseUrl}/${this.moduleId}/folder_resources/${folderId}?account_id=${this._accountId}`,
          )
          .pipe(
            map((response) => {
              response.folder_resource;
            }),
          ),
      ),
    );
  }

  updateFile(folderId: number, fileResource: FileResource): Observable<FileResource> {
    return this.folder$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient
          .put<any>(
            `${this.moduleBaseUrl}/${this.moduleId}/folder_resources/${folderId}/file_resources/${fileResource.id}?account_id=${this._accountId}`,
            { file_resource: fileResource },
          )
          .pipe(
            map((response) => {
              let newFile = response.file_resource;
              folder.file_resources.splice(
                folder.file_resources.findIndex((item) => item.id === newFile.id),
                1,
              );
              let files = [newFile, ...folder.file_resources];
              let newFolder = folder;
              newFolder.file_resources = files;
              // Update the contacts with the new contact
              this._folder.next(newFolder);

              return newFile;
            }),
          ),
      ),
    );
  }

  uploadDocument(folderId: number, fileId: number, document: any, skipNext: boolean = false): Observable<FileResource> {
    const uploadData = new FormData();
    uploadData.append('document[attachment]', document);

    return this.folder$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient
          .post<any>(
            `${this.moduleBaseUrl}/${this.moduleId}/folder_resources/${folderId}/file_resources/${fileId}/upload_document?account_id=${this._accountId}`,
            uploadData,
          )
          .pipe(
            map((response) => {
              let updatedFileResource = response.file_resource;

              if (folder && !skipNext) {
                const index = folder.file_resources.findIndex((item) => item.id === updatedFileResource.id);
                folder.file_resources[index] = updatedFileResource;
                this._folder.next(folder);
              }

              return updatedFileResource;
            }),
          ),
      ),
    );
  }

  updateRanks(list: any[], fileResource: string): Observable<FileResource> {
    let params = [];
    list.map((item, index) => params.push([fileResource, item.id, index]));
    return this.folder$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient.put<any>(`ranks`, { ranks: params }).pipe(
          map((response) => {
            let newFile;
            if (response) {
              newFile = response.file_resource;
              folder.file_resources.splice(
                folder.file_resources.findIndex((item) => item.id === newFile.id),
                1,
              );
              let files = [newFile, ...folder.file_resources];
              let newFolder = folder;
              newFolder.file_resources = files;
              // Update the contacts with the new contact
              this._folder.next(newFolder);
            }

            return newFile;
          }),
        ),
      ),
    );
  }

  deleteDocument(folderId: number, fileId: number, documentId: number): Observable<any> {
    return this.folder$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient
          .delete<any>(
            `${this.moduleBaseUrl}/${this.moduleId}/folder_resources/${folderId}/file_resources/${fileId}/destroy_document?document_id=${documentId}&account_id=${this._accountId}`,
          )
          .pipe(
            map((response) => {
              return response;
            }),
          ),
      ),
    );
  }

  getBase64Document(folderId: number, fileId: number, documentId: number): Observable<any> {
    return this.folder$.pipe(
      take(1),
      switchMap(() =>
        this._httpClient
          .post<any>(
            `${this.moduleBaseUrl}/${this.moduleId}/folder_resources/${folderId}/file_resources/${fileId}/download_base64?document_id=${documentId}&account_id=${this._accountId}`,
            {},
          )
          .pipe(
            map((response) => {
              return response;
            }),
          ),
      ),
    );
  }

  uploadedExtractedDataToDocument(
    folderId: number,
    fileId: number,
    documentId: number,
    extactedData: any,
  ): Observable<any> {
    return this.folder$.pipe(
      take(1),
      switchMap(() =>
        this._httpClient
          .put<any>(
            `${this.moduleBaseUrl}/${this.moduleId}/folder_resources/${folderId}/file_resources/${fileId}/save_extracted_data?document_id=${documentId}&account_id=${this._accountId}`,
            { extracted_data: extactedData },
          )
          .pipe(
            map((response) => {
              return response;
            }),
          ),
      ),
    );
  }

  shareFolder(folderId, emails: string[] = [], message: string = ''): Observable<any> {
    return this._httpClient
      .post<any>(
        `${this.moduleBaseUrl}/${this.moduleId}/folder_resources/${folderId}/share?account_id=${this._accountId}`,
        {
          emails: emails.join(','),
          message: message,
        },
      )
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  sendToDocusign(folderId: number, fileId: number): Observable<any> {
    return this._httpClient
      .post<any>(
        `${this.moduleBaseUrl}/${this.moduleId}/folder_resources/${folderId}/file_resources/${fileId}/send_to_docusign?account_id=${this._accountId}`,
        {},
      )
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  shareFile(folderId, fileResourceId, emails: string[] = [], message: string = ''): Observable<any> {
    return this._httpClient
      .post<any>(
        `${this.moduleBaseUrl}/${this.moduleId}/folder_resources/${folderId}/file_resources/${fileResourceId}/share?account_id=${this._accountId}`,
        { emails: emails.join(','), message: message },
      )
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }
}
