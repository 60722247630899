import { Injectable } from '@angular/core';
import { Dictionary } from 'app/shared/models/dictionary';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LayoutService {
  private _toggle = new Subject<CustomDrawerContract>();
  private _close = new Subject<void>();
  private _common: { [key: string]: Subject<any> } = {};

  get toggle$(): Observable<CustomDrawerContract> {
    return this._toggle.asObservable();
  }

  get close$(): Observable<void> {
    return this._close.asObservable();
  }

  openComponentDrawer<T extends InputOutput>(componentClass: any, options: T): void {
    this._toggle.next({
      componentClass,
      options,
    });
  }

  closeComponentDrawer(): void {
    this._close.next();
  }

  emitCommon<T>(key: string, value: T, type: 'subject' | 'behaviorSubject' = 'behaviorSubject'): void {
    this._common[key] = this._common[key] ?? (type === 'subject' ? new Subject<any>() : new BehaviorSubject<any>(null));
    this._common[key].next(value);
  }

  getCommon<T>(key: string): Observable<T> {
    return this._common[key]?.asObservable();
  }
}

export interface CustomDrawerContract {
  componentClass: any;
  options: InputOutput;
}

export interface InputOutput {
  inputs: Dictionary<any>;
  outputs: Dictionary<Function>;
}
