import Rollbar from 'rollbar';
import { Injectable, Inject, InjectionToken, ErrorHandler } from '@angular/core';
import { environment } from '@env/environment';
import { AccountService } from './account.service';
import { Account } from 'app/core/models/account.types';

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  private canLogToConsole = false;
  private errorLogEnabledKey = 'error.log.enabled';

  constructor(@Inject(RollbarService) private rollbar: Rollbar, private accountService: AccountService) {
    this.accountService.currentAccount.subscribe((account: Account) => {
      if (environment.production) {
        this.canLogToConsole = environment.env === 'stagingUI' || account.subdomain === 'desert2';
      } else {
        this.canLogToConsole = environment.env === 'developmentUI';
      }
    });
  }

  handleError(err: any): void {
    if (environment.production) {
      this.rollbar.error(err.originalError || err);
    }

    if (this.canLogToConsole || localStorage.getItem(this.errorLogEnabledKey)) {
      console.error(err);
    }
  }
}

export function rollbarFactory(): Rollbar {
  return new Rollbar(environment.rollbar);
}
