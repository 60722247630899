import { Component, OnInit } from '@angular/core';
import { VersionCheckService, PusherService } from 'app/core';
import { environment } from '@env/environment';
import { datadogRum } from '@datadog/browser-rum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  /**
   * Constructor
   */
  constructor(private _pusherService: PusherService, private _versionCheckService: VersionCheckService) {}

  ngOnInit(): void {
    this._pusherService.registerForPusher();

    if (environment.production) {
      this._versionCheckService.initVersionCheck(environment.versionCheckURL);

      datadogRum.init({
        ...environment.dataDog,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 100,
        trackInteractions: false,
      });
    }
  }
}
