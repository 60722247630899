<ng-container *ngIf="properties && properties.length > 0">
  <p class="text-md font-medium mt-3 pr-3">Properties</p>
  <mat-divider
    role="separator"
    class="mat-divider mt-1 mb-1 mat-divider-horizontal"
    aria-orientation="horizontal"
  ></mat-divider>

  <div class="flex flex-col overflow-auto mb-3">
    <div class="files-table">
      <div class="body">
        <div class="row" *ngFor="let property of properties">
          <div class="col pl-2">
            <mat-icon class="icon-size-20 mr-1" [svgIcon]="'domain'"></mat-icon
            ><a
              href="https://www.google.com/maps?q=loc:{{ property.address.latitude }}+{{
                property.address.longitude
              }}&ll={{ property.address.latitude }},{{ property.address.longitude }}&t=h"
              target="_blank"
              >{{ property?.address?.address_string || 'Address Missing' }}</a
            >
          </div>
          <div class="col">
            <a
              href="https://www.google.com/maps?q=loc:{{ property.address.latitude }}+{{
                property.address.longitude
              }}&ll={{ property.address.latitude }},{{ property.address.longitude }}&t=h"
              target="_blank"
            >
              <mat-icon class="icon-size-20 mr-1" [svgIcon]="'location_on'"></mat-icon>
            </a>
            <a
              [routerLink]="['/research', { outlets: { modal: null } }]"
              [queryParams]="{ lat: property.address.latitude, lng: property.address.longitude }"
            >
              <mat-icon class="icon-size-20 mr-1" [svgIcon]="'language'"></mat-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
