import { FieldDef } from 'app/core/models';
import { RelatedToType } from '../enums';

/**
 * @param fields Array<FieldDef>
 * @param fieldType RelatedToType
 * @returns a filtered array from the original fieldDefs array considering only fields related to type Projects and non-hidden.
 */
export function getAllowedFields(fields: Array<FieldDef>, fieldType: RelatedToType): Array<FieldDef> {
  return fields.filter((fieldDef) => fieldDef.related_to_type === fieldType && !fieldDef.hidden);
}

/**
 * @param definitionFields table definition
 * @param fieldDefs Array<FieldDef>
 * @returns a filtered array from the original table definition array response, with hidden fiields filtered out by name.
 */
export function removeHiddenFieldsFromTableDefinition(
  definitionFields: Array<string>,
  fieldDefs: Array<FieldDef>,
): Array<string> {
  return definitionFields.filter((field) => fieldDefs.find((fieldDef) => fieldDef.name === field));
}
