<div class="header">
  <div class="title">Associate Contact</div>
  <button class="close-button" mat-icon-button (click)="discard()" [tabIndex]="-1">
    <mat-icon [svgIcon]="'close'"></mat-icon>
  </button>
</div>

<form [formGroup]="contactForm">
  <div class="flex flex-col gt-xs:flex-row">
    <input [formControlName]="'id'" type="hidden" />
    <mat-form-field class="flex-auto gt-xs:pr-3">
      <mat-label>Contact Name</mat-label>
      <input
        matInput
        [formControlName]="'name'"
        [placeholder]="'Search by Contact Name or Company Name'"
        [matAutocomplete]="matAutocomplete"
        [spellcheck]="false"
      />
    </mat-form-field>
    <mat-autocomplete
      #matAutocomplete="matAutocomplete"
      [disableRipple]="true"
      (optionSelected)="autocompleteSelected($event)"
    >
      <mat-option class="no-results" *ngIf="results && !results.length"> No contacts found! </mat-option>

      <mat-option *ngFor="let result of results" [value]="result">
        {{ result.name }} ({{ result.category_name }})
      </mat-option>
    </mat-autocomplete>
  </div>
  <div class="flex flex-col gt-xs:flex-row">
    <mat-form-field class="flex-auto gt-xs:pr-3">
      <mat-label>Contact Type</mat-label>
      <mat-select [formControlName]="'category_id'">
        <mat-option *ngFor="let category of categories" [value]="category.id">
          {{ category.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="flex-auto gt-xs:pr-3">
      <mat-label>Company</mat-label>
      <input
        matInput
        [formControlName]="'company_name'"
        [placeholder]="'Company Name'"
        [matAutocomplete]="matAutocompleteCompany"
        [spellcheck]="false"
      />
    </mat-form-field>
    <mat-autocomplete
      #matAutocompleteCompany="matAutocomplete"
      [disableRipple]="true"
      (optionSelected)="autocompleteCompanySelected($event)"
    >
      <mat-option class="no-results" *ngIf="companyResults && !companyResults.length"> No companies found! </mat-option>

      <mat-option *ngFor="let companyResult of companyResults" [value]="companyResult">
        {{ companyResult }}
      </mat-option>
    </mat-autocomplete>
  </div>

  <div class="flex flex-col gt-xs:flex-row">
    <mat-form-field class="flex-auto gt-xs:pr-3">
      <mat-label>Email</mat-label>
      <input matInput [formControlName]="'email'" [placeholder]="'Email'" />
    </mat-form-field>
    <mat-form-field class="flex-auto gt-xs:pr-3 number-without-arrows">
      <mat-label>Phone</mat-label>
      <input
        matInput
        [formControlName]="'phone'"
        [placeholder]="'Phone'"
        [value]="contactForm.get('phone').value | phone"
        autocomplete="off"
        digitOnly
      />
    </mat-form-field>
  </div>
  <div class="tags-field">
    <mat-form-field>
      <mat-label>Tags</mat-label>
      <mat-chip-list #chipList aria-label="Tag selection">
        <mat-chip *ngFor="let tag of tags" [selectable]="true" [removable]="true" (removed)="removeTag(tag)">
          {{ tag }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          placeholder="New tag..."
          #tagsInput
          [matAutocomplete]="auto"
          formControlName="tagsInput"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="addTag($event)"
          data-cy="tags-input"
        />
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTag($event)">
        <ng-container *ngIf="tagsSearchResult$ | async as tagsSearchResult">
          <mat-option *ngFor="let tag of tagsSearchResult" [value]="tag">
            {{ tag }}
          </mat-option></ng-container
        >
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div class="actions justify-between">
    <div class="formatting">
      <mat-error *ngIf="error">
        {{ error?.error.message }}
      </mat-error>
    </div>
    <div class="buttons">
      <!-- Discard -->
      <button class="discard-button" mat-button type="button" (click)="discard()">
        <span>Cancel</span>
      </button>

      <!-- Send -->
      <button
        class="add"
        mat-flat-button
        type="submit"
        [disabled]="isSaveBtnDisabled"
        [color]="'primary'"
        DebounceClick
        (debounceLoading)="isDebounceLoading($event)"
        (debounceClick)="saveContactAssociation()"
      >
        <span *ngIf="!isLoading || !contactForm.disabled">Save</span>
        <mat-progress-spinner
          *ngIf="isLoading || contactForm.disabled"
          [diameter]="24"
          [mode]="'indeterminate'"
        ></mat-progress-spinner>
      </button>
    </div>
  </div>
</form>
