<div class="form-field-container" [class.locked]="isLocked">
  <ng-template form-field-host><ng-template> </ng-template> </ng-template>
  <div *ngIf="canShowHistory" class="history-panel-trigger" #historyPanelOrigin>
    <mat-icon svgIcon="history" (click)="openHistoryPanel()"></mat-icon>
  </div>
  <ng-template #historyPanel>
    <div class="history-overlay">
      <div class="history">
        <app-field-history [model]="model" [fieldDef]="fieldDef" [data]="data"></app-field-history>
      </div>
    </div>
  </ng-template>
</div>
