import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureFlag } from '../enums/feature-flag';
import { FeatureFlagService } from '../services/feature-flag.service';

@Injectable({ providedIn: 'root' })
export class FeatureFlagGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly featureFlagService: FeatureFlagService) {}

  canActivate(
    next: ActivatedRouteSnapshot & { data: { featureFlag: FeatureFlag } },
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { featureFlag, redirectPath, redirectToReplacedCurrentUrl } = next.data;

    return this.featureFlagService.hasFeatureFlag(featureFlag).pipe(
      map((isAllowed: boolean) => {
        if (!isAllowed) {
          if (redirectPath) {
            return this.router.parseUrl(redirectPath);
          } else if (redirectToReplacedCurrentUrl) {
            return this.router.parseUrl(state.url.replace(redirectToReplacedCurrentUrl, ''));
          }
        }

        return true;
      }),
    );
  }
}
