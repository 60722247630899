import {
  Component,
  ComponentFactoryResolver,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TreoConfigService } from '@treo/services/config';
import { TreoDrawerComponent } from '@treo/components/drawer';
import { Layout, TreoConfig } from 'app/layout/layout.types';
import { Account, Activity } from 'app/core/models/account.types';
import { AccountService } from 'app/core';
import { CustomDrawerContract, LayoutService } from './layout.service';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { RelatedToType } from 'app/shared/enums';

@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  config: TreoConfig;
  layout: Layout;
  account: Account;
  activities: Activity[];

  @ViewChild('customDrawer', { static: true }) customDrawer: TreoDrawerComponent;
  @ViewChild('childLoader', { static: true, read: ViewContainerRef })
  dynamicChild: ViewContainerRef;

  // Private
  private _unsubscribeAll: Subject<void>;

  /**
   * Constructor
   *
   * @param {ActivatedRoute} _activatedRoute
   * @param {TreoConfigService} _treoConfigService
   * @param {TreoDrawerService} _treoDrawerService
   * @param {DOCUMENT} _document
   * @param {Router} _router
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _treoConfigService: TreoConfigService,
    private _accountService: AccountService,
    @Inject(DOCUMENT) private _document: any,
    private _router: Router,
    private readonly layoutService: LayoutService,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
  ) {
    super();

    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._accountService.currentAccount.subscribe((accountData) => {
      this.account = accountData;
      //Subscribe to account activity
      //RRS Disable activities for now as it is putting strain on server
      this._getActivities();
      /*setInterval(() => {
                    this._getActivities();
                }, 1000 * 60 * 5);*/
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._treoConfigService.config$.pipe(takeUntil(this._unsubscribeAll)).subscribe((config: TreoConfig) => {
      // Store the config
      this.config = config;

      // Update the selected theme class name on body
      const themeName = 'treo-theme-' + config.theme;
      this._document.body.classList.forEach((className) => {
        if (className.startsWith('treo-theme-') && className !== themeName) {
          this._document.body.classList.remove(className);
          this._document.body.classList.add(themeName);
          return;
        }
      });

      // Update the layout
      this._updateLayout();
    });

    // Subscribe to NavigationEnd event
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe(() => {
        // Update the layout
        this._updateLayout();
      });

    this.layoutService.toggle$
      .pipe(untilComponentDestroyed(this))
      .subscribe(({ componentClass, options: { inputs, outputs } }: CustomDrawerContract) => {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
        const component = this.dynamicChild.createComponent(componentFactory);

        for (const inputKey in inputs) {
          component.instance[inputKey] = inputs[inputKey];
        }

        for (const outputKey in outputs) {
          component.instance[outputKey].pipe(untilComponentDestroyed(this)).subscribe(outputs[outputKey]);
        }

        this.customDrawer.toggle();
      });

    this.layoutService.close$.pipe(untilComponentDestroyed(this)).subscribe(() => this.customDrawer.close());
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    super.ngOnDestroy();
  }

  onSettingsDrawerOpenChanged($event) {
    if ($event) {
      this._getActivities();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check for new activities
   */
  private _getActivities(): void {
    if (Object.keys(this.account).length) {
      this._accountService.getActivities({ account_id: this.account.id, per_page: 25 }).subscribe((activities) => {
        this.activities = activities;
      });
    }
  }
  /**
   * Update the selected layout
   */
  private _updateLayout(): void {
    // Get the current activated route
    let route = this._activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    // 1. Set the layout from the config
    this.layout = this.config.layout;

    // 2. Get the query parameter from the current route and
    // set the layout and save the layout to the config
    const layoutFromQueryParam = route.snapshot.queryParamMap.get('layout') as Layout;
    if (layoutFromQueryParam) {
      this.config.layout = this.layout = layoutFromQueryParam;
    }

    // 3. Iterate through the paths and change the layout as we find
    // a config for it.
    //
    // The reason we do this is that there might be empty grouping
    // paths or componentless routes along the path. Because of that,
    // we cannot just assume that the layout configuration will be
    // in the last path's config or in the first path's config.
    //
    // So, we get all the paths that matched starting from root all
    // the way to the current activated route, walk through them one
    // by one and change the layout as we find the layout config. This
    // way, layout configuration can live anywhere within the path and
    // we won't miss it.
    //
    // Also, this will allow overriding the layout in any time so we
    // can have different layouts for different routes.
    const paths = route.pathFromRoot;
    paths.forEach((path) => {
      // Check if there is a 'layout' data
      if (path.routeConfig && path.routeConfig.data && path.routeConfig.data.layout) {
        // Set the layout
        this.layout = path.routeConfig.data.layout;
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set the layout on the config
   *
   * @param layout
   */
  setLayout(layout: Layout): void {
    // Clear the 'layout' query param to allow layout changes
    this._router
      .navigate([], {
        queryParams: {
          layout: null,
        },
        queryParamsHandling: 'merge',
      })
      .then(() => {
        // Set the config
        this._treoConfigService.config = { ...this._treoConfigService.config, layout };
      });
  }

  /**
   * Set the theme on the config
   *
   * @param change
   */
  setTheme(change: MatSlideToggleChange): void {
    this._treoConfigService.config = { ...this._treoConfigService.config, theme: change.checked ? 'dark' : 'light' };
  }

  /**
   * Create route from given link
   */
  createRouteFromLink(activity: Activity): any[] {
    // Split the link and add a leading slash
    let route = ['/projects', activity.pipeline_id, activity.project_id];
    //route.push(activity.pipeline_id)
    //route.push(activity.project_id)

    return route;
    //return `/projects/${activity.pipeline_id}/${activity.project_id}`;
  }

  /**
   * Given an activity, output the text to display
   */
  getActivityOutput(activity: Activity): string {
    const type = activity?.field_type || activity?.related_to_type;
    let textHash = {
      project: {
        create: `${activity.user_name} added new project ${activity.project_title}`,
        update: `${activity.user_name} updated ${activity.field} to ${activity.new_val}, it was ${activity.old_val}`,
        delete: `${activity.user_name} deleted project ${activity.project_title}`,
      },
      property: {
        create: `${activity.user_name} added new property ${activity.project_title}`,
        update: `${activity.user_name} updated ${activity.field} to ${activity.new_val}, it was ${activity.old_val} on ${activity.project_title}`,
        delete: `${activity.user_name} deleted property ${activity.project_title}`,
      },
      note: {
        create: `${activity.user_name} added a note to ${activity.project_title}`,
        update: `${activity.user_name} updated a note in ${activity.project_title}`,
        delete: `${activity.user_name} deleted a note in ${activity.project_title}`,
      },
      comment: {
        create: `${activity.user_name} added a comment to ${activity.project_title}`,
        update: `${activity.user_name} updated a comment in ${activity.project_title}`,
        delete: `${activity.user_name} deleted a comment in ${activity.project_title}`,
      },
      file_resource: {
        create: `${activity.user_name} uploaded a file to ${activity.project_title}`,
        update: `${activity.user_name} updated a file in ${activity.project_title}`,
        delete: `${activity.user_name} deleted a file in ${activity.project_title}`,
      },
      email: {
        create: `${activity.user_name} sent an email re: ${activity.summary}`,
        update: `${activity.user_name} updated an email re: ${activity.summary}`,
        delete: `${activity.user_name} deleted an email re: ${activity.summary}`,
      },
    };
    if (type in textHash) {
      return textHash[type][activity.action];
    } else {
      return '';
    }
  }

  getActivityTitle(activity: Activity): string {
    const type = activity?.field_type || activity?.related_to_type;
    let textHash = {
      project: `${activity.project_title}`,
      property: `${activity.project_title}`,
      note: `${activity.project_title}`,
      comment: `${activity.project_title}`,
      file_resource: `${activity.project_title}`,
      email: 'Sent Email',
    };
    return textHash[type];
  }

  getActivityObjectLink(activity: Activity): string {
    const type = activity?.field_type || activity?.related_to_type;
    if (activity.action !== 'delete') {
      if (type === RelatedToType.Project.toLowerCase()) {
        return `/projects/${activity.pipeline_id}/${activity.project_id}`;
      }
    }
    return null;
  }

  customDrawerOpenedChanged(event: boolean): void {
    if (event === false) {
      this.dynamicChild.clear();
      this.layoutService.closeComponentDrawer();
    }
  }
}
