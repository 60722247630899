import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef, Input, EventEmitter } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { TasksTabService } from 'app/shared/modules/tasks/tasks.service';
import { Task, TaskStatus } from 'app/shared/modules/tasks/tasks.types';

@Component({
  selector: 'project-dialog-tasks-panel',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectDialogTasksPanelComponent implements OnInit, OnDestroy {
  @Input() project: any;
  @Input() account: any;
  @Input() options: any;

  private _unsubscribeAll: Subject<void>;

  tasks: Task[];
  openTasks: Task[];
  displayColumns: string[];
  tasksCount: any;
  public darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#424242',
      buttonColor: '#fff',
    },
    dial: {
      dialBackgroundColor: '#555',
    },
    clockFace: {
      clockFaceBackgroundColor: '#555',
      clockHandColor: '#9fbd90',
      clockFaceTimeInactiveColor: '#fff',
    },
  };
  public workflowStates: TaskStatus[];

  /**
   * Constructor
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef, private _tasksService: TasksTabService) {
    this._unsubscribeAll = new Subject();
    this.displayColumns = ['is_complete', 'name', 'due_date', 'assigned_user'];
    this.tasksCount = {
      completed: 0,
      incomplete: 0,
      total: 0,
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._tasksService.moduleBaseUrl = 'projects';
    this._tasksService.moduleId = this.project.id;
    this._tasksService.accountId = this.account.id;

    if (this.project.default_task_list_id) {
      this._tasksService.getTaskListById(this.project.default_task_list_id).subscribe();
    }

    this._tasksService.loadTasks().subscribe();

    this._tasksService.getTaskStatuses().subscribe((taskStatuses) => {
      this.workflowStates = taskStatuses;
    });

    this._tasksService.tasks$.pipe(takeUntil(this._unsubscribeAll)).subscribe((tasks: Task[]) => {
      if (tasks) {
        this.tasks = tasks;
        this.loadTasks();
      }

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  updateTask(task): void {
    this._tasksService.updateTask(this.project.default_task_list_id, task).subscribe();
  }

  loadTasks() {
    this.tasksCount.total = this.tasks.filter((task) => task.task_type === 'task').length;
    this.tasksCount.completed = this.tasks.filter((task) => task.task_type === 'task' && task.is_complete).length;
    this.tasksCount.incomplete = this.tasksCount.total - this.tasksCount.completed;
    this.openTasks = this.tasks.filter((task) => task.task_type === 'task' && !task.is_complete);

    for (let task of this.tasks) {
      if (!task.title) {
        task.editing = true;
      }
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {}

  createTask() {
    const task: Task = {
      task_type: 'task',
      task_list_id: this.project.default_task_list_id,
    };

    this._tasksService.createTask(this.project.default_task_list_id, task).subscribe((newTask) => {});
  }

  deleteTask(task): void {
    if (confirm('Are you sure?')) {
      this._tasksService.deleteTask(this.project.default_task_list_id, task.id).subscribe();
    }
  }

  saveTask(task: Task): void {
    this._tasksService.updateTask(this.project.default_task_list_id, task).subscribe((task) => {
      task.editing = false;
    });
  }

  editTask(task: Task): void {
    task.editing = true;
  }

  cancelTaskEditing(task: Task): void {
    task.editing = false;
  }

  /**
   * Toggle the completed status
   * of the given task
   *
   * @param task
   */
  toggleCompleted(task: Task): void {
    // Toggle the completed status
    task.is_complete = !task.is_complete;

    // Update the task on the server
    this._tasksService.updateTask(this.project.default_task_list_id, task).subscribe();

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  taskTimeChanged(time: EventEmitter<string>, task: any) {
    const makeTime = moment(`${time}`, 'LT');
    const makeDate = moment(task.due_date);
    makeDate.set({
      hour: +makeTime.format('HH'),
      minute: +makeTime.format('mm'),
    });
    task.due_date = makeDate.format();
    this.saveTask(task);
  }
}
