import { Pipe, PipeTransform } from '@angular/core';
import { RelatedToType } from 'app/shared/enums';
import { typeTitleMap } from '../models/type-title-map';

const typeTitleMapKeys = Object.keys(typeTitleMap);

@Pipe({ name: 'relatedToTypeTitle' })
export class RelatedToTypeTitlePipe implements PipeTransform {
  transform(value: RelatedToType | string): string {
    for (const key of typeTitleMapKeys) {
      if (key.toLowerCase() === value.toLowerCase()) {
        return typeTitleMap[key];
      }
    }

    return value;
  }
}
