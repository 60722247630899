import { getCurrencySymbol } from '@angular/common';

const isGuid = (guid: string): boolean => {
  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(guid);
};

const EMAIL_PATTERN =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const getSubdomain = () => {
  const domain = window.location.hostname;
  let subdomain = '';
  if (
    domain.indexOf('.') < 0 ||
    domain.split('.')[0] === 'portal' ||
    domain.split('.')[0] === 'dev' ||
    domain.split('.')[0] === 'dashboard'
  ) {
    subdomain = '';
  } else {
    subdomain = domain.split('.')[0];
  }
  return subdomain;
};

const isArraysEqual = <T>(a: T[], b: T[]): boolean => {
  return JSON.stringify(a) === JSON.stringify(b);
};

const keepProps = <T>(target: T, props: Array<keyof T>): Partial<T> => {
  return Object.keys(target)
    .filter((propName) => props.includes(propName as keyof T))
    .reduce((prev: Partial<T>, cur: string) => {
      return (prev[cur] = { ...prev, [cur]: target[cur] });
    }, {});
};

const isEmptyOrWhitespace = (value: string): boolean => value?.match(/^ *$/) !== null;

const camelToSnakeCase = (value: string) => value.replace(/[A-Z]/g, (l) => `_${l.toLowerCase()}`);

const getCurrencyMask = (displayCurrencyCode: string, precision: number, align = 'left'): CurrencyMaskOptions => ({
  align,
  precision,
  prefix: getCurrencySymbol(displayCurrencyCode, 'wide') || '$',
});

export type CurrencyMaskOptions = { align: string; precision: number; prefix: string };

const between = (obj1: any, obj2: any): string[] => {
  if (!obj1 || !obj2) {
    return;
  }

  return Object.keys(obj1).reduce((prev, cur) => (obj1[cur] != obj2[cur] ? [...prev, cur] : prev), []);
};

const capitalizeFirstLetter = (value: string) => {
  if (!value) {
    return value;
  }

  return value.charAt(0).toUpperCase() + value.slice(1);
};

const FJSON = (obj: any[]): string => {
  return `[${obj
    .map(({ operator, value, name }) => {
      const parsedValue = JSON.stringify(value);
      return `["${name}","${operator}",${parsedValue}]`;
    })
    .join(',')}]`;
};

export {
  isGuid,
  getSubdomain,
  isArraysEqual,
  keepProps,
  isEmptyOrWhitespace,
  camelToSnakeCase,
  getCurrencyMask,
  between,
  capitalizeFirstLetter,
  FJSON,
  EMAIL_PATTERN,
};
