import { Injectable } from '@angular/core';
import { getSubdomain } from 'app/shared/Utils/common.utils';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureFlag } from '../enums/feature-flag';
import { Account } from '../models';
import { AccountService } from './account.service';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
  constructor(private readonly accountService: AccountService) {}

  hasFeatureFlag(featureFlag: FeatureFlag): Observable<boolean> {
    const currentAccount = this.accountService.getCurrentAccount();

    if (currentAccount?.id) {
      return of(currentAccount?.features?.includes(featureFlag));
    }

    return this.accountService
      .getAccount(getSubdomain())
      .pipe(map((account: Account) => account.features?.includes(featureFlag)));
  }
}
