import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

//const BASE_URL = "https://app.termsheet.com/";
//const BASE_URL = "http://pv.ts.com:4003/"
const { baseUrl: BASE_URL, apiVersion: API_VERSION } = environment;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
  private _apiUrl: string = API_VERSION;

  constructor(private httpClient: HttpClient) {}

  public setApiUrl(apiUrl: string) {
    this._apiUrl += apiUrl;
  }

  public get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.get(BASE_URL + this._apiUrl + path, { params }).pipe(catchError(this.formatErrors));
  }

  public getPdf(path: string, urlOrFilter?: string | object): Observable<any> {
    let url = BASE_URL + this._apiUrl + path;
    let params = new HttpParams();
    if (typeof urlOrFilter === 'string') {
      // we were given a page URL, use it
      if (url.includes('?') && urlOrFilter.includes('?')) {
        urlOrFilter = urlOrFilter.replace('?', '&');
      }
      url += urlOrFilter;
    } else if (typeof urlOrFilter === 'object') {
      // we were given filtering criteria, build the query string
      Object.keys(urlOrFilter)
        .sort()
        .forEach((key) => {
          const value = urlOrFilter[key];
          if (value !== null && typeof value != 'undefined') {
            params = params.set(key, value.toString());
          }
        });
    }
    return this.httpClient.get(url, { params, responseType: 'text' }).pipe(catchError(this.formatErrors));
  }

  public put(path: string, body: object = {}): Observable<any> {
    return this.httpClient
      .put(BASE_URL + this._apiUrl + path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }

  public post(path: string, body: object = {}): Observable<any> {
    return this.httpClient
      .post(BASE_URL + this._apiUrl + path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }

  public directPost(path: string, body: object = {}, includeBase = true): Observable<any> {
    let url = '';
    if (includeBase) {
      url = BASE_URL;
    }
    url += path;

    return this.httpClient.post(url, JSON.stringify(body), this.options).pipe(catchError(this.formatErrors));
  }

  public filePost(path: string, body: object = {}): Observable<any> {
    return this.httpClient.post(BASE_URL + this._apiUrl + path, body, {}).pipe(catchError(this.formatErrors));
  }

  public filePostWord(path: string, body: object = {}): Observable<any> {
    return this.httpClient
      .post(BASE_URL + this._apiUrl + path, body, { responseType: 'text' })
      .pipe(catchError(this.formatErrors));
  }

  public delete(path: string): Observable<any> {
    return this.httpClient.delete(BASE_URL + this._apiUrl + path).pipe(catchError(this.formatErrors));
  }

  public formatErrors(error: any): Observable<any> {
    return throwError(error.error);
  }
}
