import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AccountService } from '../services/account.service';
import { UserService } from 'app/layout/common/user/user.service';
import { Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { User, UserAssociation } from 'app/core/models/user.types';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate, CanActivateChild {
  private _userAssociations: UserAssociation[];

  constructor(
    private router: Router,
    private authService: AuthService,
    private _accountService: AccountService,
    private _userService: UserService,
  ) {
    this._accountService.userAssociations$.subscribe((userAssociations: UserAssociation[]) => {
      if (userAssociations) {
        this._userAssociations = userAssociations;
        this._setRoleOnUser();
      }
    });
  }

  _setRoleOnUser(): void {
    this._userService.user$.subscribe((user: User) => {
      if (user) {
        let foundUserAssociations = this._userAssociations.filter((ua) => {
          return ua.user.id == user.id;
        });
        if (foundUserAssociations && foundUserAssociations.length > 0) {
          let ua = foundUserAssociations[0];
          this._userService.role = ua.role || 'admin';
        }
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return of(true);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return of(true);
  }
}
