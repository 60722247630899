import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { AccountService } from 'app/core';

@Pipe({ name: 'tsCurrency' })
export class TsCurrencyPipe extends CurrencyPipe implements PipeTransform {
  private get accountCurrency(): string {
    return this.accountService.getCurrentAccount()?.display_currency;
  }

  constructor(private readonly accountService: AccountService) {
    super('en-US');
  }

  transform(
    value: any,
    currencyCode?: string,
    display?: string | boolean,
    digitsInfo?: string,
    locale?: string,
  ): string | null | any {
    const currency = this.accountCurrency ?? currencyCode;
    return super.transform(value, currency, display, digitsInfo, locale);
  }
}
