import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User, UserAssociation } from 'app/core/models/user.types';
import { UserService } from 'app/layout/common/user/user.service';
import { AccountService } from 'app/core';
import { Account } from 'app/core/models/account.types';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
})
export class UserComponent implements OnInit, OnDestroy {
  @Input()
  showAvatar: boolean;

  // Private
  private _unsubscribeAll: Subject<void>;
  private _user: User;
  private _account: Account;
  userRole: string;

  public isCurrentUserAdmin: boolean = false;
  public currentUser: User;
  accountUserAssociations: UserAssociation[];
  userAssociations: UserAssociation[];

  /**
   * Constructor
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {Router} _router
   * @param {UserService} _userService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _userService: UserService,
    private _accountService: AccountService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Set the defaults
    this.showAvatar = true;

    this._accountService.currentAccount.subscribe((accountData) => {
      this._account = accountData;
    });

    this._accountService.userAssociations$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((userAssociations: UserAssociation[]) => {
        this.accountUserAssociations = userAssociations;
        this.checkIfAdmin();
      });

    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
      if (user) {
        this.currentUser = user;
        this.checkIfAdmin();
      }
      this._changeDetectorRef.markForCheck();
    });

    this._userService.role$.pipe(takeUntil(this._unsubscribeAll)).subscribe((role: string) => {
      if (role) {
        this.userRole = role;
      }
      this._changeDetectorRef.markForCheck();
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter and getter for user
   *
   * @param value
   */
  @Input()
  set user(value: User) {
    // Save the user
    this._user = value;

    // Store the user in the service
    this._userService.user = value;
  }

  get user(): User {
    return this._user;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes
    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
      this._user = user;

      //identify user for analytics
      if (this._account) {
        window.analytics.identify(user.id, {
          name: user.full_name,
          email: user.email,
          company: {
            id: this._account.id,
            name: this._account.name,
            subdomain: this._account.subdomain,
          },
        });
      } else {
        window.analytics.identify(user.id, {
          name: user.full_name,
          email: user.email,
        });
      }
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  checkIfAdmin(): void {
    if (this.currentUser && this.accountUserAssociations) {
      let foundUserAssociations = this.accountUserAssociations.filter((ua) => {
        return ua.user.id == this.currentUser.id;
      });
      if (foundUserAssociations && foundUserAssociations.length > 0) {
        this.isCurrentUserAdmin = true;
      }
    }
  }
  /**
   * Update the user status
   *
   * @param status
   */
  updateUserStatus(status): void {
    // Update the user data
    this.user.status = status;

    // Update the user on the server
    this._userService.update(this.user);
  }

  goToProfile(): void {
    this._router.navigate(['/profile']);
  }

  goToSettings(): void {
    this._router.navigate(['/settings']);
  }

  switchWorkspace(): void {
    this._router.navigate(['/accounts']);
  }

  /**
   * Sign out
   */
  signOut(): void {
    this._router.navigate(['/sign-out']);
  }
}
