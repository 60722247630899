import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReportLayout, ReportType } from '../reports/reports.types';
import { GetReportsFilter, ReportLayoutChart } from './reporting.types';

@Injectable({ providedIn: 'root' })
export class ReportingService {
  constructor(private readonly httpClient: HttpClient) {}

  create<T>(model: Partial<ReportLayout<T>>): Observable<ReportLayout<T>> {
    return this.httpClient
      .post<{ report_layout: ReportLayout<T> }>(`report_layouts?account_id=${model.account_id}`, {
        report_layout: model,
      })
      .pipe(map(({ report_layout }) => report_layout));
  }

  update<T>(model: Partial<ReportLayout<T>>): Observable<ReportLayout<T>> {
    return this.httpClient
      .patch<{ report_layout: ReportLayout<T> }>(`report_layouts/${model.id}?account_id=${model.account_id}`, {
        report_layout: model,
      })
      .pipe(map(({ report_layout }) => report_layout));
  }

  getReports<T>(filter: GetReportsFilter): Observable<ReportLayout<T>[]> {
    return this.httpClient
      .get<{ report_layouts: ReportLayout<T>[] }>(`report_layouts?account_id=${filter.accountId}`)
      .pipe(map(({ report_layouts }) => report_layouts));
  }

  getReportById<T>(id: number, accountId: number): Observable<ReportLayout<T>> {
    return this.httpClient
      .get<{ report_layout: ReportLayout<T> }>(`report_layouts/${id}?account_id=${accountId}`)
      .pipe(map(({ report_layout }) => report_layout));
  }

  buildChartData(id: number, params: Partial<ReportLayoutChart>[]): Observable<ReportLayoutChart[][]> {
    return this.httpClient
      .post<{ chart_data: any }>(`report_layouts/${id}/build_chart_data`, {
        charts: [params],
      })
      .pipe(map(({ chart_data }) => chart_data));
  }
}
