import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditableModule } from './components/editable/editable.module';
import { DirectivesModule } from './directives/directives.module';
import { FormFieldsModule } from './components/form-fields/form-fields.module';
import { InlineFieldsModule } from './components/inline-fields/inline-fields.module';
import { PipesModule } from './pipes/pipes.module';
import { ModalsModule } from './modals/modals.module';
import { WidgetsModule } from './components/widgets/widgets.module';
import { ChartModule } from './components/chart/chart.module';
import { SavedViewsModule } from './modules/saved-views/saved-views.module';
import { OrganismModule } from './components/organism/organism.module';
import { ParcelMapModule } from './components/parcel-map/parcel-map.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EditableModule,
    DirectivesModule,
    FormFieldsModule,
    InlineFieldsModule,
    PipesModule,
    ModalsModule,
    WidgetsModule,
    ChartModule,
    SavedViewsModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EditableModule,
    DirectivesModule,
    FormFieldsModule,
    InlineFieldsModule,
    PipesModule,
    ModalsModule,
    WidgetsModule,
    ChartModule,
    OrganismModule,
    SavedViewsModule,
    ParcelMapModule,
  ],
})
export class SharedModule {}
