import { Route } from '@angular/router';
import { AuthGuard, NoAuthGuard, RoleGuard } from 'app/core/guards';
import { LayoutComponent } from 'app/layout/layout.component';
import { EmptyLayoutComponent } from 'app/layout/layouts/empty/empty.component';
import { SharingLayoutComponent } from 'app/layout/layouts/sharing/sharing.component';
import { InitialDataResolver, AccountResolver } from 'app/app.resolvers';
import { ProjectDialogComponent } from 'app/modules/projects/project-dialog/project-dialog.component';
import {
  ProjectsFieldDefsResolver,
  ProjectsProjectResolver,
  PipelinesPipelineResolver,
  ProjectDataResolver,
} from 'app/modules/projects/projects.resolvers';

// @formatter:off
/* eslint-disable max-len */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'accounts' },

  // Redirect signed in user to the '/example'
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'accounts' },

  // Auth routes
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: EmptyLayoutComponent,
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import('app/modules/auth/confirmation-required/confirmation-required.module').then(
            (m) => m.AuthConfirmationRequiredModule,
          ),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('app/modules/auth/forgot-password/forgot-password.module').then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then((m) => m.AuthResetPasswordModule),
      },
      {
        path: 'sign-in',
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule),
      },
      {
        path: 'sign-up',
        loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then((m) => m.AuthSignUpModule),
      },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: EmptyLayoutComponent,
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then((m) => m.AuthUnlockSessionModule),
      },
    ],
  },

  // Landing routes
  {
    path: '',
    component: EmptyLayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('app/modules/landing/home/home.module').then((m) => m.LandingHomeModule),
      },
    ],
  },
  {
    path: '',
    component: SharingLayoutComponent,
    children: [
      {
        path: 'shared',
        loadChildren: () =>
          import('app/modules/shared-resources/shared-resources.module').then((m) => m.SharedResourcesModule),
      },
    ],
  },

  // Admin routes
  {
    path: 'accounts',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      // Example
      { path: '', loadChildren: () => import('app/modules/accounts/accounts.module').then((m) => m.AccountsModule) },
      {
        path: 'contacts',
        loadChildren: () => import('app/modules/contacts/contacts.module').then((m) => m.ContactsModule),
      },
      // 404 & Catch all
      // {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/errors/error-404/error-404.module').then(m => m.Error404Module)},
      // {path: '**', redirectTo: '404-not-found'}
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard, RoleGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
      account: AccountResolver,
    },
    children: [
      {
        path: 'contacts',
        loadChildren: () => import('app/modules/contacts/contacts.module').then((m) => m.ContactsModule),
      },
      {
        path: 'companies',
        loadChildren: () => import('app/modules/companies/companies.module').then((m) => m.CompanyModule),
      },
      {
        path: 'projects',
        loadChildren: () => import('app/modules/projects/projects.module').then((m) => m.ProjectsModule),
      },
      {
        path: 'research',
        loadChildren: () => import('app/modules/research/research.module').then((m) => m.ResearchModule),
      },
      {
        path: 'calendar',
        loadChildren: () => import('app/modules/calendar/calendar.module').then((m) => m.CalendarModule),
      },
      { path: 'tasks', loadChildren: () => import('app/modules/tasks/tasks.module').then((m) => m.TasksModule) },
      {
        path: 'folders',
        loadChildren: () => import('app/modules/folders/folders.module').then((m) => m.FoldersModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('app/modules/settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('app/modules/reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('app/modules/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('app/modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'explorer',
        loadChildren: () => import('app/modules/explorer/explorer.module').then((m) => m.ExplorerModule),
      },
      {
        path: 'properties',
        loadChildren: () => import('app/modules/properties/properties.module').then((m) => m.PropertiesModule),
      },
      {
        path: 'reporting',
        loadChildren: () => import('app/modules/reporting/reporting.module').then((m) => m.ReportingModule),
      },
      // 404 & Catch all
      // {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/errors/error-404/error-404.module').then(m => m.Error404Module)},
      // {path: '**', redirectTo: '404-not-found'}
    ],
  },
  {
    path: 'pd/:id/:project_id',
    component: ProjectDialogComponent,
    outlet: 'modal',
    resolve: {
      pipeline: PipelinesPipelineResolver,
      project: ProjectsProjectResolver,
      options: ProjectDataResolver,
      fieldDefs: ProjectsFieldDefsResolver,
    },
  },
];
