<mat-form-field class="flex-auto gt-xs:pr-3" (click)="edit()" (focus)="edit()">
  <mat-label
    >{{ fieldDef.label }} <i class="fa fa-info-circle pointer-events-auto" [matTooltip]="showTip" *ngIf="showTip"> </i
  ></mat-label>
  <div class="w-full">
    <input
      #inputControl
      matInput
      [placeholder]="fieldDef.label"
      type="text"
      [ngModel]="editing ? value : (value | formatNumber: precision)"
      type="text"
      (ngModelChange)="value = $event"
      (keyup.enter)="onBlur($event)"
      (blur)="onBlur($event)"
      [disabled]="isDisabled"
    />
  </div>
</mat-form-field>
