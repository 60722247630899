<mat-form-field class="flex-auto gt-xs:pr-3" (click)="edit()" (focus)="edit()">
  <mat-label
    >{{ fieldDef.label }} <i class="fa fa-info-circle pointer-events-auto" [matTooltip]="showTip" *ngIf="showTip"> </i
  ></mat-label>
  <div class="w-full">
    <input
      #inputControl
      matInput
      [disabled]="isDisabled"
      [formControl]="formCtrl"
      (keyup.enter)="onBlur($event)"
      (blur)="onBlur($event)"
      [(ngModel)]="value"
      [placeholder]="fieldDef.label"
      [matAutocomplete]="matAutocomplete"
      [spellcheck]="false"
    />
  </div>
</mat-form-field>
<mat-autocomplete
  #matAutocomplete="matAutocomplete"
  [disableRipple]="true"
  (optionSelected)="autoCompleteSelected($event)"
  (closed)="autoCompleteClosed()"
>
  <mat-option class="no-results" *ngIf="searchResults && !searchResults.length"> No results! </mat-option>

  <mat-option *ngFor="let result of searchResults" [value]="result">
    {{ result }}
  </mat-option>
</mat-autocomplete>
