import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Company, Country, Tag, CompanyPagination, Category } from './companies.types';
import { Project } from 'app/modules/projects/projects.types';
import { Contact } from '../contacts/contacts.types';
import { LoanQuote } from '../projects/details/loans/loans.types';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  // Private
  private _company: BehaviorSubject<Company | null>;
  private _companies: BehaviorSubject<Company[] | null>;
  private _pagination: BehaviorSubject<CompanyPagination | null>;
  private _countries: BehaviorSubject<Country[] | null>;
  private _tags: BehaviorSubject<Tag[] | null>;
  private _categories: BehaviorSubject<Category[] | null>;
  private _accountId: string;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the private defaults
    this._company = new BehaviorSubject(null);
    this._companies = new BehaviorSubject(null);
    this._countries = new BehaviorSubject(null);
    this._tags = new BehaviorSubject(null);
    this._categories = new BehaviorSubject(null);
    this._pagination = new BehaviorSubject(null);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for company
   */
  get company$(): Observable<Company> {
    return this._company.asObservable();
  }

  /**
   * Getter for companies
   */
  get companies$(): Observable<Company[]> {
    return this._companies.asObservable();
  }

  /**
   * Getter for pagination
   */
  get pagination$(): Observable<CompanyPagination> {
    return this._pagination.asObservable();
  }

  set accountId(val: string) {
    this._accountId = val;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get companies
   *
   * @param sortField
   * @param sortDirection
   */
  getCompanies(
    page: number = 1,
    size: number = 20,
    sort = 'name',
    order: 'asc' | 'desc' | '' = 'asc',
    search: string = '',
    filters: any = {},
    csv = false,
    dontEmitValues = false,
  ): Observable<{ pagination: CompanyPagination; companies: Company[] }> {
    const { name = '', website = '' } = filters;
    //set the page number to 1 if 0 as can happen in pagination
    if (page < 1) {
      page = 1;
    }
    return this._httpClient
      .get<{ pagination: CompanyPagination; companies: Company[] }>(`companies?account_id=${this._accountId}`, {
        params: {
          page: '' + page,
          per_page: '' + size,
          sort: sort,
          order: order,
          q: search,
          name: name,
          website: website,
        },
      })
      .pipe(
        map((response: any) => {
          const lastPage = Math.max(Math.ceil(response.count / size), 1);
          const begin = page * size;
          const end = Math.min(size * (page + 1), response.count);

          const pagination = {
            length: response.count,
            size: size,
            page: page - 1,
            lastPage: lastPage - 1,
            startIndex: begin,
            endIndex: end,
          };

          let companies = response.results;
          if (!dontEmitValues) {
            this._pagination.next(pagination);
            this._companies.next(companies);
          }

          return {
            pagination,
            companies,
          };
        }),
      );
  }

  /**
   * Search companies with given query
   *
   * @param query
   */
  searchCompany(query: string): Observable<Company[] | null> {
    return this._httpClient
      .get<Company[] | null>('companies/search', {
        params: { query },
      })
      .pipe(
        tap((companies) => {
          this._companies.next(companies);
        }),
      );
  }

  /**
   * Get company by id
   */
  getCompanyById(id: string): Observable<Company> {
    return this._httpClient.get<any>(`companies/${id}?account_id=${this._accountId}`).pipe(
      tap((response) => {
        this._company.next(response.company);
        return response.company;
      }),
    );
  }

  /**
   * Create company
   */
  createCompany(company): Observable<Company> {
    return this.companies$.pipe(
      take(1),
      switchMap((companies) =>
        this._httpClient.post<any>(`companies?account_id=${this._accountId}`, { company: company }).pipe(
          map((reponse) => {
            // Update the companies with the new company
            this._companies.next([reponse.company, ...companies]);

            // Return the new company
            return reponse.company;
          }),
        ),
      ),
    );
  }

  /**
   * Update company
   *
   * @param id
   * @param company
   */
  updateCompany(company: Company): Observable<Company> {
    return this.companies$.pipe(
      take(1),
      switchMap((companies: Array<Company>) =>
        this._httpClient
          .patch<{ company: Company }>(`companies/${company.id}?account_id=${this._accountId}`, {
            company,
          })
          .pipe(
            map((response) => {
              let updatedCompany = response.company;

              if (companies) {
                // Find the index of the updated company
                const index = companies.findIndex((item) => item.id === updatedCompany.id);

                // Update the company
                companies[index] = updatedCompany;

                // Update the companies
                this._companies.next(companies);
                this._company.next(updatedCompany);
              }
              // Return the updated company
              return updatedCompany;
            }),
            switchMap((updatedCompany) =>
              this.company$.pipe(
                take(1),
                filter((item) => item && item.id === updatedCompany.id),
                tap(() => {
                  // Update the company if it's selected
                  this._company.next(updatedCompany);

                  // Return the updated company
                  return updatedCompany;
                }),
              ),
            ),
          ),
      ),
    );
  }

  /**
   * Delete the company
   *
   * @param id
   */
  deleteCompany(id: string): Observable<boolean> {
    return this.companies$.pipe(
      take(1),
      switchMap((companies) =>
        this._httpClient.delete(`companies/${id}?account_id=${this._accountId}`).pipe(
          map((isDeleted: boolean) => {
            // Find the index of the deleted company
            const index = companies.findIndex((item) => item.id === id);

            // Delete the company
            companies.splice(index, 1);

            // Update the companies
            this._companies.next(companies);

            // Return the deleted status
            return isDeleted;
          }),
        ),
      ),
    );
  }

  /**
   * Get Projects related to a company
   */
  getProjects(companyId: string): Observable<Project[]> {
    return this._httpClient.get<any>(`companies/${companyId}/projects?account_id=${this._accountId}`).pipe(
      map((response) => {
        return response.projects;
      }),
    );
  }

  getContacts(companyId: string): Observable<Contact[]> {
    return this._httpClient.get<any>(`companies/${companyId}/contacts?account_id=${this._accountId}`).pipe(
      map((response) => {
        return response.contacts;
      }),
    );
  }

  getLoanQuotes(companyId: string): Observable<LoanQuote[]> {
    return this._httpClient.get<any>(`companies/${companyId}/loan_quotes?account_id=${this._accountId}`).pipe(
      map((response) => {
        return response.loan_quotes;
      }),
    );
  }
}
