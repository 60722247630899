import { FileFormat } from 'app/shared/enums';
import { ExportOptions } from 'app/shared/models/export-options';

export namespace GraphqlProject {
  export interface Params {
    fields: string[];
    filters?: { fjson: string };
    exportOptions?: ExportOptions;
  }

  export class Action {
    static readonly type = 'Graphql Project';

    constructor(public grapqlParams: Params) {}
  }
}
