<mat-form-field class="flex-auto gt-xs:pr-3" (click)="edit()" (focus)="edit()">
  <mat-label
    >{{ fieldDef.label }} <i class="fa fa-info-circle pointer-events-auto" [matTooltip]="showTip" *ngIf="showTip"> </i
  ></mat-label>
  <div class="w-full">
    <textarea
      matInput
      #inputControl
      cdkTextareaAutosize
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="500"
      [disabled]="isDisabled"
      [placeholder]="fieldDef.label"
      [(ngModel)]="value"
      (blur)="onBlur($event)"
      [spellcheck]="false"
    ></textarea>
  </div>
</mat-form-field>
